

import { Prop, Component } from "vue-property-decorator"

import { RootView } from "@/components"
import { BoxIndicatorBlock, CountryBlock, LastUpdateBlock, OrganisationLinkBlock, TagsBlock } from "@/components/blocks";
import { TreatmentTag } from "@/components/tags";
import { CheckboxField } from "@/components/fields";
import { DailyIcon } from "@/components/icons";

import LicenseDashboardBlock from "./LicenseDashboardBlock.vue";

@Component({
    components: {
        BoxIndicatorBlock, TreatmentTag, LicenseDashboardBlock, LastUpdateBlock,
        TagsBlock, CheckboxField, DailyIcon, OrganisationLinkBlock, CountryBlock
    }
})
export default class DeviceDashboardBlock extends RootView {

    @Prop({default: false}) value!: boolean;

    onRowClicked() {
        this.$emit('click', {device: this.device, index: this.index});
    }

    onCheckBoxClicked(value: boolean) {
        this.$emit("input", value);
    }

    get displayIcon(): any {
        return this.deviceIcon(this.chooseDevice);
    }

    onDeviceDetailsClicked() {
        this.goto({ path: `/organisation/${this.device!.org_id}/devices/${this.device!.device_id}`});
    }
}

