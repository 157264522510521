
import { Component, Prop } from "vue-property-decorator";

import { RootView } from '@/components';
import { InfoRowText } from "@/components/texts";

@Component({
    components: {
        InfoRowText
    }
})
export default class MobileUserInfoView extends RootView {
    @Prop({default: ""}) phone!: string;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) website!: string;
    @Prop({default: ""}) address!: string;
    @Prop({default: ""}) icon!: string;
}
