
import { Component, Prop } from "vue-property-decorator";
import { RootView } from "@/components";
import { TimestampText } from "../texts";

@Component({
    components: {
        TimestampText: TimestampText,
    }
})
export default class LicenseTag extends RootView {
    @Prop({default: false}) small!: boolean;
    @Prop({default: false}) noBreak!: boolean;
    
    get licenseExpiryClass(): string {
        if (this.showTextLicenseRequest) {
            return "alert-warning";
        }

        return "alert-danger";
    }

    get licenseExpiredNewClass(): string {
        if (this.noBreak) return "d-flex align-items-center";
        return "";
    }

    get licenseExpiredNewStyle(): string {
        if (this.noBreak) return "gap: 4px;"
        return "";
    }
    
    get showTextLicenseRequest(): boolean {
        return this.chooseDevice != null && this.chooseDevice.licenserequest && this.chooseDevice.licenserequest.content && !this.licenseRequestAboutToExpire;
    }

    get licenseRequestAboutToExpire(): boolean {
        if (this.chooseDevice == null) return false;
        return this.chooseDevice.license && 
            this.chooseDevice.licenserequest && 
            this.chooseDevice.licenserequest.content && 
            this.chooseDevice.licenserequest.content.expiry <= Date.now();
    }

}
