import { render, staticRenderFns } from "./ClassicContainerLayout.vue?vue&type=template&id=ed8236f6&scoped=true&"
import script from "./ClassicContainerLayout.vue?vue&type=script&lang=ts&"
export * from "./ClassicContainerLayout.vue?vue&type=script&lang=ts&"
import style0 from "./ClassicContainerLayout.vue?vue&type=style&index=0&id=ed8236f6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed8236f6",
  null
  
)

export default component.exports