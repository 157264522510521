
import { Component } from "vue-property-decorator";
import { MODAL_ADMIN_UPDATE_FW } from "@/Data";

import { ModalType, RootView } from "@/components";
import { SelectDropdown } from "@/components/dropdowns";
import { DeviceModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        SelectDropdown,
        DeviceModalLayout
    }
})
export default class AdminUpdateFirmwareModalView extends RootView {

    showError = false;

    $refs!: {
        firmwareDropdown: SelectDropdown
    }

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_UPDATE_FW,
            title: "modal_adminupdatefirmware_title",
            desc: "modal_adminupdatefirmware_desc",
            type: ModalType.PROCESS_STAY_OPEN,
            action: this.process,
            validations: this.validations,
            onOpen: this.onOpen
        }
    }

    onOpen() {
        this.showError = false;
    }

    validations(): Promise<boolean> {
        if (!this.$refs.firmwareDropdown.value || typeof this.$refs.firmwareDropdown.value == "string") {
            this.$nextTick(() => {
                this.showError = true;
            })
            return Promise.resolve(false);
        }
        this.$nextTick(() => {
            this.showError = false;
        });
        return Promise.resolve(true);
    }


    process(): Promise<any> {
      return this.$store.dispatch("admin_devices/updateFirmwares", {options: {firmware: this.$refs.firmwareDropdown.value.firmware_id}, selected: this.selectedDevices});
    }
}
