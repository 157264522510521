
import { Component } from "vue-property-decorator";

import { MissingData, Accordion } from "@/components";
import { MobileListLayout, ListHeaderLayout, CenterLayout, MobileRowLayout } from "@/components/layouts";
import { CheckboxField, SearchbarField } from "@/components/fields";
import { Dropdown, UserOrganisationRoleDropdown } from "@/components/dropdowns";
import { RoleTag } from "@/components/tags";

import BaseUserMembershipsView from "./BaseUserMembershipsView";

@Component({
    components: {
        MobileListLayout, ListHeaderLayout, MissingData, Accordion, CenterLayout,
        SearchbarField, UserOrganisationRoleDropdown, Dropdown, CheckboxField,
        RoleTag, MobileRowLayout
    }
})
export default class MobileUserMembershipsView extends BaseUserMembershipsView {}
