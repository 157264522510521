import OrganisationModel from "@/models/OrganisationModel";
import { emptyString, searchItems } from "./Search";

export interface AdminOrganisationSearchFilters {
    label: string,
}

export default searchItems<OrganisationModel, AdminOrganisationSearchFilters>(new Map([
    ["label", {
        default: emptyString,
        validation: (label: string, org: OrganisationModel): boolean => {

            if (label.trim() == "") {
                return false;
            }
            
            return !(org.name.toLowerCase().includes(label.trim().toLowerCase()));
        }
    }],
]));