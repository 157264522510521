
import { Vue, Prop, Component } from "vue-property-decorator";
import Branding from "../Branding.vue";
import { isMobile } from "mobile-device-detect";

@Component({
    components: {
        Branding
    }
})
export default class LoginLayout extends Vue {
    @Prop({default: ""}) title!: string;

    get isMobile(): boolean {
        return isMobile;
    }
}
