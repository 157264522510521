
import { Prop, Component } from "vue-property-decorator";
import RootView from "../RootView";
import SectionAvatar from "../SectionAvatar.vue";
import InfoRowText from "../texts/InfoRowText.vue";

@Component({
    components: {
        SectionAvatar,
        InfoRowText
    }
})
export default class SummaryCardSheet extends RootView {
    @Prop({default: ""}) phone!: string;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) website!: string;
    @Prop({default: ""}) address!: string;
    @Prop({default: ""}) icon!: string;
}

