
import { Component } from "vue-property-decorator";

import { MissingData } from "@/components";
import { Dropdown, UserOrganisationRoleDropdown } from "@/components/dropdowns";
import { ListLayout, ListFiltersLayout, ListHeaderLayout, CenterLayout } from "@/components/layouts";
import { SearchbarField, CheckboxField } from "@/components/fields";
import { RoleTag } from "@/components/tags";

import BaseUserInvitationsView from "./BaseUserInvitationsView";

@Component({
    components: {
        ListLayout, ListFiltersLayout, 
        ListHeaderLayout, SearchbarField, CheckboxField, 
        RoleTag,
        CenterLayout, MissingData, Dropdown, UserOrganisationRoleDropdown
    }
})
export default class UserInvitationsView extends BaseUserInvitationsView {}
