
import { Component, Prop, Vue } from "vue-property-decorator";
import {isOnlyNumbers, isStringEmpty} from "@/Utils";
import { isMobile } from "mobile-device-detect";

import { SimplifiedDevice } from "../../models/DeviceModel";
import { VForm } from "@/components";

import EditbarField from "./EditbarField.vue";

@Component({
    components: {
        EditbarField
    }
})
export default class RegisterBarField extends Vue {
    @Prop({default: () => {return [];}}) value: SimplifiedDevice[] = [];

    imei = "";
    sn = "";
    label = "";
    
    keepLabel = false;

    $refs!: {
        dataForm: VForm,
        entryForm: VForm
    }

    mounted() {
        this.sn = "";
        this.imei = "";
        this.label = "";
    }

    get isMobile() {
        return isMobile;
    }
    
    get getImeiLabel() {
        return `Imei (${this.imei?.length ?? 0} / 15)`;
    }

    rules = {
        imei: (value: string): any => {
            const text = value.trim();
            if (!(text.length >= 5 && text.length <= 15)) return "Imei need to be between 5 and 15 numbers";
            if (!isOnlyNumbers(text)) return "Imei only allows numbers";
            return true;
        },
        sn: (value: string): any => {
            const text = value.trim();
            if (text.length == 0) return "Serial number cannot be empty";
            if (!(/^EMF/.test(text))) return "Serial number need to start with EMF";
            return true;
        }
    }

    onRegisterButtonSubmit(e: any) {   
        e.preventDefault();
        
        if (!this.$refs.entryForm.validate()) return;


        let found = false;

        for (let x = 0; x < this.value.length && !found; x++) {
            if (this.value[x].id == this.imei || this.value[x].sn == this.sn) {
                found = true;
            }
        }

        if (!found) {
            const element = structuredClone(this.value);
            element.push({
                id: this.imei.slice(),
                sn: this.sn.slice(),
                label: this.label.slice()
            });
            this.$emit('input', element);
        }
        
        this.$nextTick(() => {
            this.imei = "";
            this.sn = "";

            if (!this.keepLabel) {
                this.label = "";
            } 

            setTimeout(() => {
                this.$refs.entryForm.resetValidation();
            }, 200);
        })
    }

    validate() {
        return this.$refs.dataForm.validate();
    }

    onDeleteSnClicked(data: {id: string, sn: string}) {
        const element = structuredClone(this.value);
        const objWithIdIndex = element.findIndex((obj) => obj.id === data.id);
        element.splice(objWithIdIndex, 1);
        this.$emit('input', element);
    }

    reset() {
        this.sn = "";
        this.imei = "";
        this.label = "";
    }
}
