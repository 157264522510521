
import { Component, Prop} from "vue-property-decorator";

import { RootView } from "@/components";
import { hideToast } from "@/Toast";

@Component
export default class BackButton extends RootView {
    @Prop() text?: string
    @Prop({default: null}) link!: string | null; 

    onGoBackClicked() {
        hideToast();
        if (this.link != null) {
            this.goto({path: this.link}, true);
            return;
        }
        this.goBack();
    }
}
