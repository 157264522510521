
import { Component } from "vue-property-decorator";
import { AxiosError } from "axios";

import { MODAL_EDIT_PASSWORD } from "@/Data";
import { ErrorList, ModalType, RootView, VForm } from "@/components";
import { PasswordField } from "@/components/fields";
import { ActionModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";
import { PasswordSheet } from "@/components/sheets";

@Component({
    components: {
        ActionModalLayout,
        ErrorList,
        PasswordField,
        PasswordSheet
    }
})
export default class EditPasswordModalView extends RootView {

    $refs!: {
        pwSheet: PasswordSheet,
        form: VForm
    }

    oldPwRules = [
        (value: string): any => {
            if (!value) return this.$t("field_rule_empty");
            if (value.trim().length > 0) return true;
            return this.$t("field_rule_empty")
        }
    ]

    oldPwErrors: string[] = [];
    oldPassword = "";
    password = "";
    serverErrors: string[] = [];

    get options(): ModalOptions {
        return {
            modal: MODAL_EDIT_PASSWORD,
            type: ModalType.PROCESS_STAY_OPEN,
            title: "modal_changepw_title",
            desc: "",
            size: "md",
            height: "480px",
            action: this.process,
            validations: this.validations,
            onOpen: this.reset,
            onOpened: this.resetRef
        }
    }

    reset() {
        this.oldPassword = "";
        this.password = "";
        this.oldPwErrors = [];
        this.serverErrors = [];
    }

    resetRef() {
        this.$refs.form.reset();
        (this.$refs.pwSheet as any).reset();
    }

    validations(): Promise<boolean> {
        return Promise.resolve(this.$refs.form.validate());
    }

    async process(): Promise<any> {
        try {
            await this.$store.dispatch("user/changePassword", {
                old_password: this.oldPassword,
                new_password: this.password
            });

            return Promise.resolve(true);
        } catch (error) {

            if (error instanceof AxiosError) {
                const status: number = error.response?.status ?? 400;

                switch(status) {
                    case 429:
                        this.$nextTick(() => {
                            this.serverErrors = ["pw_error_limitexceeded"]
                        })
                        break;
                    case 419:
                        this.$nextTick(() => {
                            this.serverErrors = ["pw_error_unauthorized"]
                        });
                        break;  
                    case 400:
                        this.$nextTick(() => {
                            this.oldPwErrors = [this.$t("pw_error_oldpw").toString()]
                        })
                        break;
                    default:
                        break;
                }
            }

            return Promise.resolve(false);
        }
    }
}
