import { CognitoJwtVerifier } from "aws-jwt-verify";
import jwt_decode from "jwt-decode";
import { Axios } from "axios";

import { VuexModule, Module, Mutation, Action,} from "vuex-class-modules";
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession, CognitoRefreshToken } from "amazon-cognito-identity-js"

import { jsonParse, pausify, promiseWithTimeout } from "@/Utils";
import { UserMemberships, User, AuthRequestStatus, AuthRequest, UserRole } from "@/Data";

const buildUserMemberships = (tokenData: any): UserMemberships => {
    const results: UserMemberships = new Map();
    const rawMemberships = jsonParse(tokenData.memberships);

    for (let i = 0; i < rawMemberships.length; i++) {
        results.set(rawMemberships[i].org_id, rawMemberships[i].role.toUpperCase());
    }

    return results;
}

const constructUser = (cognitoUser: CognitoUser, session: CognitoUserSession): User => {
    const idToken: string = session.getIdToken().getJwtToken();
    const rawTokenData: any = jwt_decode(idToken);

    return {
      username: rawTokenData.email,
      id: rawTokenData.sub,
      tokens: {
        IdToken: idToken,
        AccessToken: session.getAccessToken().getJwtToken(),
        RefreshToken: session.getRefreshToken().getToken(),
      },
      attributes: {},
      organisations: jsonParse(rawTokenData.organisations),
      memberships: buildUserMemberships(rawTokenData),
      internals: jsonParse(rawTokenData.internals)
    };
}

@Module
export default class AuthModule extends VuexModule {
    cognitoUserPool = new CognitoUserPool({
        UserPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID!,
        ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID!
    });

    verifier = CognitoJwtVerifier.create({
        userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID!,
        clientId: process.env.VUE_APP_COGNITO_CLIENT_ID!,
        tokenUse: "id"
    });

    user?: User
    cognitoUser?: CognitoUser

    get isAuthenticated(): boolean {
        return this.user != undefined;
    }

    get accessToken(): string {
        return this.user?.tokens.AccessToken ?? ""
    }

    get token(): string {
        return this.user?.tokens.IdToken ?? "";
    }

    @Action
    getOrganisationMembershipRole(org_id: string): UserRole | null {
        if (this.user == null) return null;
        return this.user.memberships.get(org_id) ?? null;
    }

    @Action
    async isInOrganisation(org_id: string): Promise<boolean> {
        return (await this.getOrganisationMembershipRole(org_id)) != null;
    }

    @Mutation
    disconnect() {
        this.user = undefined;
        this.cognitoUser = undefined;
    }

    @Mutation
    setUser(user: User) {
        this.user = user;
    }

    @Mutation
    setCognitoUser(user: CognitoUser) {
        this.cognitoUser = user;
    }

    @Action
    async updateToken() {
        if (!this.cognitoUser) return;
        if (!this.user?.tokens) return;

        const refreshToken: string = this.user.tokens.RefreshToken;

        const user: User | undefined = await new Promise<User | undefined>((resolve, reject) => {
            this.cognitoUser?.refreshSession(new CognitoRefreshToken({RefreshToken: refreshToken}), (err, session) => {
                const constructedUser = constructUser(this.cognitoUser!, session);
                resolve(constructedUser);
            });
        })
        
        if (!user) return false;
        this.setUser(user);
        return true;
    }

    @Action
    async getCurrentUser(): Promise<boolean> {
        const cognitoUser = this.cognitoUserPool.getCurrentUser();
    
        if (!cognitoUser) return false;
        this.setCognitoUser(cognitoUser);

        const user: User | undefined = await new Promise<User | undefined>((resolve, reject) => {
            cognitoUser.getSession((err: null, session: CognitoUserSession) => {
                if (err || !session) {
                  resolve(undefined);
                  return;
                }
                
                const constructedUser = constructUser(cognitoUser, session);

                const verification = this.verifier.verify(constructedUser.tokens.IdToken).catch((err) => {
                    resolve(undefined);
                }).then((response) => {
                    resolve(constructedUser);
                })
            });
        });

        if (!user) return false;
        this.setUser(user);
        return true;
        
    }

    @Action
    async logout() {
        
        if (this.user == undefined) {
            return
        }

        const cognitoUser = this.cognitoUserPool.getCurrentUser();

        if (!cognitoUser) return false;
        await promiseWithTimeout<CognitoUserSession | undefined>({promise:new Promise((resolve, reject) => {
            cognitoUser.getSession((err: null, session: CognitoUserSession) => {
                if (!session) {
                    reject();
                    return;
                }

                cognitoUser.globalSignOut({
                    onSuccess: () => {
                        resolve(session);
                    },
                    onFailure: (err) => {
                        resolve(session);
                    }
                })
                })
        }), defaultValue: undefined, timeout: 3000});

        this.disconnect();
    }

    @Action
    setNewPassword(payload: {username: string, password: string}): Promise<boolean> {
        return pausify(new Promise<boolean>((resolve, reject) => {
            this.cognitoUser!.completeNewPasswordChallenge(payload.password, null, {
            onSuccess(session, userConfirmationNecessary?) {
                resolve(true);
            },
            onFailure: (err) => {
                resolve(false);
            }
            })
        }), 3000);
    }

    @Action
    async resetPassword(payload: {token: string, password: string}): Promise<boolean> {
        return pausify(new Axios({
                baseURL: process.env.VUE_APP_API,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).put(`/login/password/${payload.token}`, JSON.stringify({
                password: payload.password
            })), 3000);
    }

    @Action
    async login(payload: {username: string, password: string}): Promise<AuthRequest> {
        const authDetails = new AuthenticationDetails({
            Username: payload.username,
            Password: payload.password,
        });

        const cognitoUser = new CognitoUser({
            Pool: this.cognitoUserPool,
            Username: payload.username,
        });
        
        return pausify(new Promise<AuthRequest>((resolve, reject) => {
            cognitoUser.authenticateUser(authDetails, {
                onFailure: async (err) => {
                    resolve({status: AuthRequestStatus.FAILURE, data: err});
                },
                onSuccess: async (session) => {
                    resolve({status: AuthRequestStatus.SUCCESS, data: constructUser(cognitoUser, session)})
                },

                newPasswordRequired: async (userAttributes: any, requiredAttributes: any) => {
                    resolve({status: AuthRequestStatus.NEW_PASSWORD, data: {username: payload.username}})
                }
            })
        }), 3000);
    }

    @Action
    forgotPassword(payload: {username: string}): Promise<any> {
        return pausify(new Axios({
            baseURL: process.env.VUE_APP_API,
            headers: {
                'Content-Type': 'application/json'
            }
        }).post("/login/password", JSON.stringify({
            email: payload.username
        })), 3000);
    }

}