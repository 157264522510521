
import { Component, Prop, Watch } from "vue-property-decorator"
import RootView from "../RootView";
import Dropdown from "./Dropdown.vue";
import { checkAll, SearchItemModel } from "@/models/SearchItemModel";
import { wrapSearchItems } from "@/Utils";
import { selection } from "@/Utils";

@Component({
    components: {
        Dropdown
    }
})
export default class SearchDropdown extends RootView {
    @Prop({default: ""}) id!: string;
    @Prop({default: ""}) title?: string;
    @Prop({default: ""}) titleClass!: string;
    @Prop({default: ""}) titleStyle!: string;
    @Prop({default: ""}) sectionStyle!: string;
    @Prop({default: ""}) sectionClass!: string;
    @Prop({default: () => { return []; }}) items?: SearchItemModel<any>[];
    @Prop({default: ""}) refKey?: string;
    @Prop({default: ""}) refValue?: string;

    checkedAll = false;
    itemValues: SearchItemModel<any>[] = [];

    mounted() {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.itemValues = wrapSearchItems(structuredClone(this.items!));
    }

    onSelectedAll() {
        checkAll(this.itemValues, this.checkedAll);
        this.sendEvent();
    }

    sendEvent() {
        this.$emit("selection", selection(this.itemValues));
    }
}
