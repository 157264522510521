import { VuexModule, Mutation, Module } from "vuex-class-modules";
import { hasProperty } from "@/Utils";
import LangModel from "@/models/LangModel";

@Module
export default class GlobalModule extends VuexModule {

    langs : LangModel[] = [{
        id: "en",
        tag: "English"
    },{
        id: "fr",
        tag: "Français"
    }];


    query: any;
    toast = false;

    @Mutation
    setQuery(query: any) {
        this.query = query;
    }

    @Mutation
    setToast(toast: boolean) {
        this.toast = toast;
    }

    @Mutation
    addToQuery(payload: {key: string, value: string}) {
        if (hasProperty(this.query, payload.key) && this.query[payload.key] == payload.value) {
            return;
        }
        this.query[payload.key] = payload.value;
    }
    
    @Mutation
    removeFromQuery(key: string) {
        if (hasProperty(this.query, key)) {
            delete this.query[key];
        }
    }
}