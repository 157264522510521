
import { Component, Prop } from "vue-property-decorator";

import { DialogPopupModal } from '../toasts';
import RootModal, { ModalSetup, ModalType } from "../RootModal";
import { ModalOptions, toast, ToastType } from "@/Toast";

@Component({
    components: {
        DialogPopupModal,
    }
})
export default class ActionModalLayout extends RootModal {
    @Prop({default: () => {return {}}}) options!: ModalOptions;
    
    override setup(): ModalSetup {
        return {
            name: this.options.modal,
            type: this.options.type
        }
    }

    onData(data: any) {
        if (this.options.onData) {
            this.options.onData(data);
        }
    }

    get optionSize(): string {
        if (this.options.size != undefined) return this.options.size;
        return this.modalSize;
    }

    get hasOptions(): boolean {
        if (this.options.options != undefined) return this.options.options;
        return true;
    }

    get onOpen(): any {
        if (this.options.onOpen != undefined) return this.options.onOpen;
        // eslint-disable-next-line
        return () => {};
    }

    get onOpened(): any {
        if (this.options.onOpened != undefined) return this.options.onOpened;
        // eslint-disable-next-line
        return () => {};
    }

    override async validations(): Promise<boolean> {
        if (this.options.validations) return this.options.validations();
        return Promise.resolve(true);
    }

    override hasProcess(): boolean {
        return this.options.action != undefined;
    }

    override async process(): Promise<any> {
        if (this.options.action != undefined) {
            return this.options.action()
        }
        // eslint-disable-next-line
        return Promise.resolve(undefined);
    }
}
