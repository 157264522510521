

import { Component, Prop } from 'vue-property-decorator';
import RootView from '../RootView';

@Component
export default class ConnectivityTag extends RootView {
    @Prop({default: false}) forceUnknown!: boolean;
    @Prop({default: "font-size: 12px; margin-left: 8px !important; margin-right: 8px !important;"}) textStyle!: string;
}
