
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import debounce from 'debounce';

@Component
export default class ChipSearchField extends Vue {

    @Prop({default: (() => {return []})}) value?: any[];
    @Prop({default: ""}) label?: string;
    @Prop({default: true}) clearable!: boolean;
    search = "";

    get items(): any[] {
        return this.value!
    }

    addTag() {
        const value = (this.search ?? "").trim(); // Get the current input value
        if (value && !this.items.includes(value)) {
            this.items.push(value); // Add the item as a chip
            this.search = ''; // Clear the input field
        }

        this.onTagChanged(this.items);
    }

    removeTag(item: any) {
        const values = this.value?.slice(0) ?? [];
        values.splice(values.indexOf(item), 1);
        this.$emit('input', values);
        this.$emit('search', values);
    }
    
    clearTags() {
        this.$emit('input', []);
        this.$emit('search', []);
    }

    onTagBtnAdded: () => any = debounce(this.addTag, 200);
    onTrashTagBtnClicked: () => any = debounce(this.clearTags, 200);
    onTagRemoved: (item: any) => any = debounce(this.removeTag, 200);

    onTagChanged(items: string[]) {
        this.$emit('input', items);
        this.$emit('search', items);
    }

}
