export default {
    "wait_message": 'Please wait..',
    'latitude': 'Latitude',
    'longitude': 'Longitude',
    'no_tags_found': 'No tags found',
    "coordinates": "Coordinates",
    'unavailable': 'Unavailable',
    'not_available': "N/A",
    'activate': 'Activate',
    'deactivate': 'Deactivate',
    'all': 'All',
    "unassigned": "Unassigned",
    'invitations': "Invitations",
    'manage': 'Manage',
    'elements_selected': 'Selected {1} out of {0} {2}(s)',
    'menu-collapse': 'Collapse',
    '404': "Eh?! It seems the content you searched for doesn't exist.",
    '404_subtitle': 'We couldn\'t find the associated page you tried to search for. You might have made a mistake typing the link.',
    '404_subtitle_hint': 'Luckily, we have a buoy to get you back on track.',
    '404_btn': 'Back to the Homepage',
    'organisation': "Organisation",
    'route_undefined': '',
    'route_map': 'Map',
    'route_dashboard': 'Dashboard',
    'route_organisations': 'Organisations',
    'route_profile': 'Profile',
    "route_organisation": "Organisation",
    "route_device": "Device",
    'route_faq': 'F.A.Q',
    'route_admin': 'Administration',
    'route_firmwares': 'Firmwares',
    "route_invite": "Invitation",
    "route_licenses": "Expired Licenses",
    "route_mobileapp": "EMFConnect Mobile",
    'role_title': "Role",
    'role_guest': 'Guest',
    'role_admin': 'Admin',
    'role_owner': 'Owner',
    'role_member': 'Member',
    'role_none': 'None',
    'role_superadmin': 'SuperAdmin',
    'login_email': "Email",
    'options': 'Options',
    'options_section_manage': 'Manage Device',
    'options_cmd_edit_label': 'Edit label',
    'options_cmd_cancel_treatment': 'Cancel Treatment ({0})',
    'options_cmd_activate_treatment': 'Activate Treatment',
    'options_cmd_deactivate_treatment': 'Deactivate Treatment',
    'options_cmd_storage_mode': 'Storage Mode',
    'options_cmd_treatment': 'Toggle Treatment',
    'options_section_remote_commands': 'Remote Commands',
    'passwords_do_not_match': 'Passwords do not match.',
    'login_password': "Password",
    'login_password_confirm': 'Confirm Password',
    'new_login_password': 'New password',
    'new_login_password_confirm': 'Confirm your new password',
    'new_login_password_title': 'New Password Required ',
    'new_login_password_description': 'The creation of a new password is required.',
    'forgot_password_title': 'Password Forgotten?',
    'forgot_login_email': 'Enter your email',
    'forgot_password_description': 'Fill in your email address, so we can send you a link to reset your password.',
    'forgot_password_email_error': 'Email not found',
    'forgot_password_email_sent': 'Email sent. Please verify your mail inbox.',
    'reset_password_title': "Reset your password",
    'reset_password_description': 'Complete the form in below to change your password.',
    'password_changed_succesfully': 'Password reseted succesfully.',
    'phone_rule_international': 'Phone needs to fit the international standard starts with a leading plus sign (+). No spaces.',
    'password_match': 'Both passwords need to match',
    'password_rule_min_char': 'Password needs to be 8 characters or more.',
    'password_rule_uppercase': 'Password needs to contain atleast 1 uppercase',
    'password_rule_number': 'Password needs to contain atleast 1 number',
    'password_rule_special': 'Password needs to contain atleast 1 special character ($&+,:;=?@#|\'<>.^*()%!-)',
    'password_rule_match': 'Passwords must match',
    'field_rule_empty': "Field must not be empty.",
    'password_rule_requirements': 'Password requirements need to be met',
    'field_rule_min_char': '{0} needs to be {1} characters or more.',
    'login_invitation_title': 'Invitation',
    'login_invitation_text': 'Sign in to join {0}',
    'login_remember': "Remember me",
    'login_forgot': "Forgot password?",
    'login_signup': "Sign up",
    'login_signin': "Sign in",
    'logout': "Sign out",
    'logout_message': 'Are you sure you want to perform this action ?',
    'button_close': 'Close',
    'button_cancel': 'Cancel',
    'button_confirm': 'Confirm',
    'login_error': "Invalid login credentials..",
    'map_legend': "Legend",
    "map_legend_enabled": "Enabled",
    "map_legend_disabled": "Disabled",
    "map_buttons_summary": "Summary",
    "map_buttons_details": "Details",
    "map_buttons_zoom": "Zoom",
    "map_buttons_search": "Search",
    "map_search_label": "Label / Serialnumber",
    "admin_search_label": "Label / SN / Firmware",
    "map_search_tags": "Tags",
    "my_organisations": "My Organisations",
    "my_informations": "My Informations",
    "none": "None",
    "no_memberships": "No memberships found",
    "no_invitations": "No invitations found",
    "no_organisations_title": "No Organisations",
    "no_organisations_found_title": "No Organisations found",
    "no_organisations_description": "You must be invited to an organisation to view the dashboard.",
    "profile_usercode": "Your User ID Code",
    "no_organisations_steps": "Copy and send your user ID code to your organisation(s) account owner to be invited.",
    "no_devices": "No devices found",
    "no_firmwares": "No firmwares found",
    "no_users": "No users found",
    "no_organisations": "No organisations found",
    "no_devices_msg": "No devices have been found for the associated organisation : {name}. ",
    "no_device_msg": "No device found with the id.",
    "update_src_iot": "LTE",
    "update_src_mobile": "Mobile",
    "update_src_admin": "Admin",
    "update_src_unknown": "Unknown",
    "details_title_summary": "Summary",
    "details_title_licenses": "Licenses",
    "details_title_fwu": "Firmware Updates History",
    "details_title_actions": "Actions",
    "details_title_users": "Users",
    "details_title_details": "Details",
    "details_title_firmware": "Firmware",
    "details_title_roam": "Roam",
    "details_title_map": "Map",
    "details_title_updates": "Updates",
    'details_title_devices': "Devices",
    "details_title_organisations": "Organisations",
    'details_title_info': 'Info',
    "details_title_memberships": "Memberships",
    "details_title_invitations": "Invitations",
    "details_summary_role": "Role",
    "details_summary_status": "Status",
    "details_summary_name": "Name",
    "details_summary_phone": "Phone",
    "details_summary_email": "Email",
    "details_summary_address": "Address",
    "details_summary_website": "Website",
    "details_summary_temperature": "Temperature",
    "details_summary_transducerbattery": "Transducer Battery",
    "details_summary_buoybattery": "Buoy Battery",
    "details_summary_uptime": "Uptime",
    "details_summary_resetcount": "Reset count",
    "details_summary_activatedtime": "Activated time",
    "details_summary_coordinates": "Coordinates",
    "details_summary_enabled": "Activation",
    "details_summary_enabled_true": "Enabled",
    "details_summary_enabled_false": "Disabled",
    "details_summary_water": "Liquid Sensor",
    "details_summary_water_true": "Is in water",
    "details_summary_water_false": "Is out",
    "details_summary_wifi": "Cellular network",
    "details_summary_wifi_status_disconnected": "Disconnected",
    "details_summary_wifi_status_poor": "Poor",
    "details_summary_wifi_status_good": "Good",
    "details_summary_wifi_status_excellent": "Excellent",
    "details_summary_wifi_status_fair": "Fair",
    "details_actions_toggle": "Treatment",
    "details_actions_storage": "Storage",
    "details_actions_storage_remove_btn": "Reinstate",
    "details_actions_storage_btn": "Send",
    "details_actions_unavailable_btn": "Unavailable",
    "details_actions_cancel_btn": "Cancel",
    "details_actions_back_btn": "Back",
    "details_summary_product": "Product",
    "details_summary_firmware": "Firmware",
    "details_firmware_imei": "IMEI",
    "details_firmware_ICCID": "ICCID",
    "details_firmware_module": "Mod",
    "details_firmware_board": "Board",
    "details_firmware_app": "App",
    "details_roam_band": "Band",
    "details_roam_nw": "NW",
    "details_roam_rsrp": "RSRP",
    "details_roam_area": "Area",
    "details_roam_mccmnc": "MCCMNC",
    "details_roam_cell": "Cell",
    "details_roam_ip": "IP",
    "no_label": "No label",
    'done': 'Operation completed with success.',
    'failure': 'Operation failed.. Contact a site administrator',
    "page_organisations_info": "Manage or view your differents organisations..",
    "languages": "Languages",
    "page_organisation_users_guest_warn": "Guests do not have the permission to see an organisation's users.",
    "page_organisation_search_name_email": "Name/Email",
    "page_organisations_table_id": "ID",
    "page_organisations_table_name": "Name",
    "page_access_forbidden_title": "Access Forbidden",
    "page_access_forbidden_organisation_subtitle": "It seems that you aren't part of the organisation requested.",
    "page_organisation_table_id": "ID",
    "page_organisation_table_name": "Name",
    "page_organisation_table_role": "Role",
    "page_organisation_table_phone": "Phone",
    "page_organisation_table_email": "Email",
    "page_organisation_table_actions": "Actions",
    "page_organisation_table_website": "Website",
    "page_organisation_search": "Search",
    "page_organisation_invite": "Invite",
    "page_organisation_user_edit": "Edit",
    "page_organisation_user_delete": "Delete",
    "btn_add": "Add",
    "btn_delete": "Delete",
    "share_code": "Copy code",
    "email": "Email",
    "toast_share_code": "Your user ID code was copied to clipboard.",
    "toast_user_edit": "User edited with success.",
    "toast_user_invited": "Used added with success.",
    "toast_user_invited_fail": "Error: Attempt to add the user failed.",
    "toast_missing_selection": "Please select atleast one {0} before performing the action.",
    "toast_org_user_deleted": "User deleted with success.",
    "toast_already_exists": "One\\The device\\s already exist(s)",
    "toast_org_user_updated": "User edited with success.",
    "toast_admin_cloud_disconnected": "Operation currently unavailable. Device need to be connected to the cloud.",
    "help": "Help",
    "device_task": "Device Task",
    "action_task": "Action",
    "device_task_description": "Description",
    "device_task_selected_devices": "Target Device(s)",
    "device_task_selected_devices_total": "Total : <strong>{0}</strong> targeted device",
    "device_task_options": "Option(s)",
    "modal_sharehelp_description": "The share code button allows you to join an organisation. To join an organisation you need to proceed as such",
    "modal_sharehelp_stepone": "Click the share code button ; the code will be added to your clipboard",
    "modal_sharehelp_steptwo": "Send the code to organisation's owner or administrators so they can add you in.",
    "modal_adminupdatefirmware_title": "Firmware Update",
    "modal_adminupdatefirmware_desc": "Update the firmware of device(s) to a newer version.",
    "modal_adminassignorg_title": "Assign Organisation",
    "modal_adminassignorg_desc": "Assign/unassign device(s) to/from an organisation",
    "modal_admintags_title": "Manage tag(s)",
    "modal_admintags_desc": "Add/remove tag(s) for device(s)",
    "modal_admintags_switch": "Remove/Add",
    "modal_adminsync_title": "Synchronize",
    "modal_adminsync_desc": "Synchronize devices latest statuses from the latest shadows.",
    "modal_admindisable_title": "Disable device(s)",
    "modal_admindisable_desc": "Disable device(s) execution and unassign them from an organisation.",
    "modal_admindeleteorg_title": "Delete organisation(s)",
    "modal_admindeleteorg_desc": "I acknolwedge that deleting these organisations will also unlink all of their devices and kick all their members.",
    "modal_treatment_title": "Toggle Treatment",
    "modal_treatment_text": "Would you like to {0} the treatment of the device?",
    "modal_cancel_title": "Cancel Operation",
    "modal_leaveorg_subtitle": "You are now attempting to leave the following organisation(s).",
    "modal_leaveeorg_title": "Leave Organisation",
    "modal_cancel_subtitle": "Would you like to cancel the transition ?",
    "modal_cancel_text": "If your device has been activated it will deactivate, and the opposite, etc.",
    "modal_signup_title": "Sign Up",
    "modal_signup_text": "Contact your EM Fluids Representative to create an account.",
    "modal_editorg_title": "Edit Organisation",
    "modal_editorg_desc": "Edit information related to the organisation",
    "page_profile_table_name": "Name",
    "page_profile_button_edit": "Edit your profile",
    "modal_invite_title": "Invite",
    "modal_invite_description": "Invite a user to join your organisation by inserting their email.",
    "modal_invitation_title": "Invitation",
    "modal_invitation_description": "You succesfully joined <strong> {0} </strong> as a/an <strong> {1} </strong>",
    "modal_invitation_failed_title": "Invitation failed",
    "modal_invitation_failed_description": "Your invitation is expired/invalid.",
    'modal_editdevice_title': 'Edit label',
    'modal_editdevice_desc': 'Edit label for this device',
    "modal_organisationstorage_title": "Setting Devices for Storage",
    "modal_organisationstorage_desc": "Set or unset devices to/from storage",
    'modal_organisationstorage_options': "Toggle the switch to set the devices to be either in storage or not.",
    "toast_org_user_update_fail": "Attempt to update the user failed.",
    "toast_org_user_delete_fail": "Attempt to delete the user failed.",
    "modal_editorguser_title": "Edit",
    "modal_editorguser_desc": "Edit user(s) within your organisation.",
    "modal_kickorganisationuser_title": "Remove user(s)",
    "modal_kickorganisationuser_desc": "Remove user(s) within your organisation.",
    "dashboard_table_sn": "#SN",
    "dashboard_table_organisation": "Organisation",
    "dashboard_table_country": "Country",
    "dashboard_table_update": "Last update",
    "dashboard_table_license": "License",
    "dashboard_table_label": "Label",
    "dashboard_table_tags": "Tags",
    "dashboard_table_transducer": "Transducer",
    "dashboard_table_status": "Status",
    "dashboard_table_cloudconnection": "Cloud",
    "dashboard_table_firmware": "Firmware",
    "dashboard_table_ignored": "Is Ignored?",
    "dashboard_table_daily": "Daily",
    "treatment": "Treatment",
    "serverconnection": "Cloud",
    "device_status_enabled": "Treatment ON",
    "device_status_offline": "Treatment OFF",
    "device_status_transition_on": "ACTIVATING..",
    "device_status_transition_off": "DEACTIVATING..",
    "device_status_unknown": "Unknown",
    "device_status_on": "ON",
    "device_status_off": "OFF",
    "device_status_storage": "IN STORAGE",
    "device_status_pairing": "PAIRING",
    "device_status_disconnected_sm": "DISCONNECTED",
    "device_status_idle": "Idle",
    'device_status_disconnected': 'Transducer disconnected',
    "modal_storage_title": "Storage Mode",
    "device_status_license_expired": "Expired",
    "device_status_license_not_found": "Not found",
    "device_status_license_request_pending": "New license",
    "device_status_license_tag_expired": "License expired",
    "device_status_license_tag_expired_short": "expired",
    "device_status_license_new_ready": "New License Ready",
    "modal_storage_text": "Set your unit to storage mode. The unit will stop collecting data. Are you sure ?",
    "modal_storage_text_return": "Remove your unit from storage mode.",
    "modal_storage_remove_title": "Storage Mode (Reinstate)",
    "modal_storage_remove_text": "Reinstate your unit from storage mode. You will still have to activate the device if required. Are you sure ?",
    'manage_title': "Manage",
    'filters_title': "Filters",
    "admin_board_all": "Boards (all)",
    "admin_command_label": "Edit label",
    "admin_command_title": "Manage Devices",
    "admin_command_single_title": "Manage Device",
    "admin_command_single_mobile_title": "Manage",
    "admin_command_firmware": "Update firmware",
    "admin_command_license": "Update license",
    "admin_command_assignment": "Assign device(s) to an organisation",
    "admin_command_coordinates": "Update desired coordinates",
    "admin_command_tags": "Manage tag(s)",
    "admin_command_storage": "Storage mode",
    "admin_command_empty": "Create device(s)",
    "admin_command_serialnumber": "Assign serial number(s)",
    "admin_command_sync": "Sync",
    "admin_command_disable": "Disable",
    "admin_command_daily": "Set daily",
    "admin_command_shadow": "Shadow",
    "admin_command_country": "Set country",
    "profile_command_changepassword": "Change password",
    "profile_command_editprofile": "Edit profile",
    "profile_command_myprofile": "My profile",
    "admin_organisations_all": "Organisations (all)",
    "firmware_version": "Version",
    "firmware_name": "Name",
    "firmware_description": "Description",
    "firmware_board": "Board Design",
    "firmware_date": "Release Date",
    "manage_users": "Manage Users",
    "manage_organisations": "Manage Organisations",
    "manage_memberships": "Manage Memberships",
    "manage_invitations": "Manage Invitations",
    "manage_firmwares": "Manage Firmwares",
    "manage_devices": "Manage Devices",
    "details_title_manage": "Management",
    "organisation_command_edit": "Edit",
    "organisation_command_invite": "Invite",
    "organisation_command_rank": "Rank",
    "organisation_command_kick": "Kick",
    "command_leave": "Leave",
    "command_accept": "Accept",
    "command_refuse": "Refuse",
    "firmware_id": "ID",
    'settings': "Settings",
    "country": "Country",
    "acknowledgement": "Acknowledgement",
    "are_you_sure": "Are you sure?",
    "modal_adminupdatecountry_title": "Set Country",
    "modal_adminupdatecountry_desc": "Set country of device(s)",
    "modal_acceptinvitation_title": "Accept",
    "modal_acceptinvitation_desc": "Accepting this, will allow you to manage devices for the organisation(s) and more.",
    "modal_leaveorg_desc": "Leaving the organisation will prevent you from taking any actions with the organisation's devices and more.",
    'modal_adminstorage_title': 'Storage mode',
    'modal_adminstorage_desc': 'Send/Remove unit(s) to/from storage mode',
    "modal_admincreatefirmware_title": "Firmware Update",
    "modal_admincreatefirmware_desc": "Create a new firmware update registry.",
    "modal_admineditfirmware_title": "Edit Firmware Update",
    "modal_admineditfirmware_desc" : "Edit the details of a firmware update.",
    "modal_admincreateorg_title": "Create an organisation",
    "modal_admincreateorg_desc": "Create an organisation by filling the following fields.",
    "modal_admincoordinates_title": "Update desired coordinates",
    "modal_admincoordinates_desc": "Update desired coordinates of the device",
    "modal_adminupdatelicense_title": "Update license",
    "modal_adminupdatelicense_desc": "Update the license of a or some device(s). Fill in any missing serial numbers.",
    "modal_adminupdatelicense_date_error": "The selected date must be above the current date.",
    "modal_adminupdatelicense_sn_error": "Missing serial numbers.. Serial numbers are required to perform licenses creation.",
    "modal_adminupdatelicense_singlesn_error": "No serial numbers entered. Please enter one.",
    "modal_adminempty_title": "Registration",
    "modal_adminempty_desc": "Register new device(s) before their data is reported.",
    "modal_adminsetdevicedaily_title": "Set Daily",
    "modal_adminsetdevicedaily_desc": "Set the device as a daily device. It will be reported daily in the automated email.",
    "firmware_command_create": "Create an update",
    "modal_changepw_title": "Change password",
    "modal_changepw_desc": "Enter your old password and your new password to change your password.",
    "modal_editprofile_title": "Edit profile",
    "modal_editprofile_desc": "Edit any fields related to your profile if required.",
    "error_missing_role": "Missing role(s) for some/all the users",
    "organisation_command_create": "Create an organisation",
    "firmware_command_edit": "Edit an update",
    "license": "License",
    "last_update": "Last update",
    "license_valid_text": "Valid for {0}",
    "license_expire_text": "Expires {0}",
    "year": "year(s)",
    "month": "month(s)",
    "week": "week(s)",
    "day": "day(s)",
    "hour": "hour(s)",
    "minute": "minute(s)",
    "ago": "{0} ago",
    "until": "Until",
    "since": "Since",
    "admin_only": "Admin",
    "serialnumber": "Serial number",
    "button_view_device": "VIEW",
    "no_license_requests_found": "No License Update Requests found",
    "no_jobs_found": "No Firmware Jobs found",
    "no_events_found": "No events found",
    "no_files_found": "No files found",
    "license_request_type": "Type",
    "license_request_expirydate": "Expiration Date",
    "license_request_creationdate": "Creation Date",
    "license_request_latest": "Latest",
    "license_request_expired": "Expired",
    "license_request_installed": "Installed",
    "license_request_wip": "Update in Progress",
    "license_request_skipped": "Skipped",
    "license_request_current": "Current installed on device",
    "timestamp_type": "Category",
    "timestamp_db": "Timestamp (DB)",
    "timestamp_shadow": "Timestamp (Shadow)",
    "device_status_server_connected": "Connected",
    "device_status_server_disconnected": "Disconnected",
    "update_type_unknown": "Unknown",
    "update_type_mobile": "Mobile",
    "update_type_iot": "LTE",
    "update_type_admin": "Admin",
    "update_type_web": "Web",
    "admin_op_success_create": "Created successfully the following devices.",
    "admin_op_success_license": "Made new licenses for the following devices.",
    "admin_op_success_firmware": "Updated the firmware for the following devices.",
    "admin_op_success_desiredcoords": "Assigned a new set of coordinates for the devices",
    "admin_op_success_assignment": "Assigned successfully the device to the organisation.",
    "admin_op_success_tags": "Set the tags for the following devices.",
    "admin_op_success_sync": "Synced the following devices.",
    "job_status_in_progress": "In progress",
    "job_status_queued": "Queued",
    "job_status_rejected": "Rejected",
    "job_status_removed": "Removed",
    "job_status_timed_out": "Timed Out",
    "job_status_failed": "Failed",
    "job_status_canceled": "Canceled",
    "job_status_succeeded": "Succeeded",
    "job_status_completed": "Completed",
    "job_status_deleted": "Deleted",
    "job_status_cancellation_in_progress": "Cancellation In Progress",
    "job_status_deletion_in_progress": "Deletion In Progress",
    "cancel_job": "Cancel Firmware Job",
    "cancel_job_message": "Cancel the job. The device will stop its firmware update..",
    "treatment_on": "Treatment ON",
    "treatment_off": "Treatment OFF",
    "treatment_in_transition": "Treatment IN TRANSITION",
    "in_storage": "In Storage",
    "btn_license_create": "Generate a new license",
    "btn_daily_email": "Manage Daily Email List",
    "type": "Type",
    "value": "Value",
    "result": "Result",
    "modal_admingenlic_title": "Generate license",
    "modal_admingenlic_desc": "Fill in the information to generate a license to be copied and shared",
    "modal_admingenlic_result_desc": "The result of the license according to the following inputs.",
    "modal_adminmanagedailyemail_title": "Manage Daily Email List",
    "modal_adminmanagedailyemail_desc": "Manage the daily email list for the daily protocol.",
    "modal_adminwarnlic_title": "Warning licenses expiring/expired",
    "modal_adminwarnlic_desc": "The following list contains devices that may require your attention regarding their license avabaility",
    "text_expired_license_count": "{0} expired license(s)",
    "btn_download_app": "Download the app",
    "dropfile_here": "Drop file(s) here",
    "map_menu_storage_toggle": "Show In Storage unit(s)",
    "org_table_user_email": "Email",
    "org_table_user_name": "Name",
    "org_table_user_role": "Role",
    "org_table_user_since": "Since",
    "pw_error_oldpw": "Old password entered does not match.",
    "pw_error_limitexceeded": "Attempts at password changes limit exceeded. Please wait 15 minutes before trying again.",
    "pw_error_unauthorized": "Your session is expired. Please refresh the page and try again."
};