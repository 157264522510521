import Modal from "bootstrap/js/dist/modal";
import RootView from "./RootView";
import { ToastType, hideLoading, registerModal, showLoading, toast } from "@/Toast";

import { Component } from 'vue-property-decorator'
import { PopupModal } from "./toasts";

import { pausify } from "@/Utils";

import { SelectDropdown } from "./dropdowns";
import { FileUploaderField, PasswordField, EditableField, RegisterBarField } from "./fields";

export enum ModalType {
    VIEW_ONLY,
    PROCESS,
    PROCESS_STAY_OPEN,
    PROCESS_WITHOUT_TOAST
}

export interface ModalSetup {
    name: string, 
    type?: ModalType,
    onEnd?: ()=> void,
    pause?: number
}

export type VForm = Vue & {
    validate: () => boolean;
    resetValidation: () => boolean;
    reset: () => void;
  };

// eslint-disable-next-line
@Component({
    components: {
        PopupModal
    }
})
export default class RootModal extends RootView {
    _name!: string;
    _modal!: Modal;
    _type!: ModalType
    _pauseTime!: number;

    $refs!: {
        modal: PopupModal,
        roles: SelectDropdown[],
        assignOrgDropdown: SelectDropdown,
        firmwareDropdown: SelectDropdown,
        uploader: FileUploaderField,
        form: VForm,
        registerbar: RegisterBarField
    }

    get modalSize() {
        return !this.isMobile && !this.isOnDeviceDetailsPage ? 'lg' : '';
    }
    
    setup(): ModalSetup {
        return {
            name: "",
            type: ModalType.PROCESS
        };
    }

    onData(data: any) {
        // implement;
    }

    onProcessFailure() {
        // default
        toast({message: "failure", type: ToastType.TOAST_ERROR});
    }

    hasProcess() {
        return true;
    }
    
    onProcessSuccess() {
        // implement
    }

    onClose() {
        // implement
    }

    process(): Promise<any> {
        return Promise.resolve(true);
    }

    validations(): Promise<boolean> {
        return Promise.resolve(true);
    }

    mounted() {
        const setup: ModalSetup = this.setup();
        this._name = setup.name;
        this._type = setup.type ?? ModalType.PROCESS;
        this._pauseTime = setup.pause ?? 3000;
        this._modal = new Modal(`#${this.$refs.modal.modalId}`);
        
        registerModal({
            name: this._name,
            modal: this._modal,
            callback: this.onData
        });
    }

    close() {
        this._modal.hide();
        setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.onClose();
        }, 500);
    }

    async confirm() {
        if (this._type == ModalType.VIEW_ONLY || !this.hasProcess()) {
            this.close();
            return;
        }

        const result: boolean = await this.validations();
        if (!result) {
            return;
        }

        try {
            showLoading(5000);
            const result = await pausify(this.process(), this._pauseTime);
            if (!result) {
                throw new Error("Failure");
            }
        } catch (exception) {
            console.error(exception);
            hideLoading();
            this.onProcessFailure();

            if (this._type == ModalType.PROCESS_STAY_OPEN) {
                return;
            }
            
            this.close();
            return;
        }

        hideLoading();
        this.onProcessSuccess();
        
        if (this._type == ModalType.PROCESS_STAY_OPEN) {
            return;
        }

        this.close();

        if (this._type == ModalType.PROCESS_WITHOUT_TOAST) {
            return;
        }

        setTimeout(() => {
            toast({message: "done", type: ToastType.TOAST_SUCCESS});
        }, 500)
    } 
}