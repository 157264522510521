import { capitalize, jsonParse } from "@/Utils";
import { network } from "@/network";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";
import { modules } from "@/store";
import { CODE_INVALID, CODE_UNAUTHORIZED, CODE_VALID } from "@/Data";

@Module
export default class InviteModule extends VuexModule {
    recentlyDone = false;
    hasInvitation = false;
    status = "";
    org_name = "";
    role = "";
    email = "";
    org_id = "";
    token = "";

    @Mutation
    setUserInvite(payload: {status: string, email: string, role: string, org_name: string, org_id: string}) {
        if (payload.status == "invalid") {
            return;
        }

        this.hasInvitation = true;
        this.status = payload.status.toLowerCase();
        this.org_name = capitalize(payload.org_name.toLowerCase());
        this.org_id = payload.org_id;
        this.role = payload.role.toLowerCase();
        this.email = payload.email;
    }

    @Mutation
    toggleRecentlyDone() {
        this.recentlyDone = true;
    }

    @Mutation
    clear() {
        this.recentlyDone = false;
        this.hasInvitation = false;
        this.status = "";
        this.org_name = "";
        this.role = "";
        this.email = "";
        this.org_id = "";
        this.token = "";
    }

    @Mutation
    setToken(token: string) {
        this.token = token;
    }

    @Action
    async check(payload: {token: string}): Promise<boolean> {
        this.setToken(payload.token);
        const result = jsonParse((await network().get(`/invite/${payload.token}`)).data);
        this.setUserInvite(result);
        return true;
    }

    @Action
    async confirm() : Promise<number> { 
        if (!modules.auth.user) return CODE_UNAUTHORIZED;
        if (modules.auth.user!.username != this.email) return CODE_UNAUTHORIZED;
        const result = jsonParse((await network().post(`/invite/${this.token}`)).data);
        if (result.status == "done") {
            this.toggleRecentlyDone();
            return CODE_VALID;
        }
        return CODE_INVALID;
    }

    @Action
    async signup(payload: {name: string, password: string}): Promise<boolean> {
        const result = jsonParse((await network().post(`/invite/${this.token}`, JSON.stringify({
            name: payload.name,
            password: payload.password
        }))).data);
        if (result.status == "done") {
            this.toggleRecentlyDone();
            return true;
        }
        return false;
    }
}