
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "../RootView";
import SelectDropdown from "./SelectDropdown.vue";
import { ACTION_ADMIN_FILTERS, ACTION_ADMIN_SEARCH_DEVICES, QUERY_PARAM_LAST_UPDATE_TYPE } from "@/Data";

@Component({
    components: {
        SelectDropdown
    }
})
export default class LastUpdateTypeSearchDropdown extends RootView {
    
    @Prop({default: ACTION_ADMIN_FILTERS}) commitTo!: string;
    @Prop({default: ACTION_ADMIN_SEARCH_DEVICES}) search!: string;
    @Prop({default: () => {return {}}}) src!: any;
    
    $refs!: {
      updateTypeDropdown: SelectDropdown,
    }

    mounted() {
        if (!this.src.searchedLastUpdateType) return;
        const updateTypes = this.updateTypes;
        let result = undefined;

        for (let i = 0; i < updateTypes.length; i++) {
          if (updateTypes[i].value == this.src.searchedLastUpdateType)  {
            result = updateTypes[i];
          }
        }

        if (result) {
          this.commitLastUpdateTypeChange(result);
          this.$refs.updateTypeDropdown.forceValue(result);
        }
    }

    commitLastUpdateTypeChange(updateType: any) {
      let elem: any | undefined = updateType;
      if (elem.key == "all") {
        elem = undefined;
      }

      this.$store.commit(this.commitTo,  {filter: "lastUpdateType", value: elem});
    }

    onLastUpdateTypeChange(updateType: any) {
      this.commitLastUpdateTypeChange(updateType);
      this.$store.commit(this.search);
      this.setQuery(QUERY_PARAM_LAST_UPDATE_TYPE, updateType.value.toLowerCase());
    }

    onLastUpdateTypeClear() {
      this.$store.commit(this.commitTo, {filter: "lastUpdateType", value: undefined});
      this.$store.commit(this.search);
      this.removeFromQuery(QUERY_PARAM_LAST_UPDATE_TYPE);
    }
}
