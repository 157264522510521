
import { Component } from "vue-property-decorator";
import { MODAL_ADMIN_DISABLE } from "@/Data";
import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class AdminDisableModalView extends RootView {

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_DISABLE,
            title: "modal_admindisable_title",
            desc: "modal_admindisable_desc",
            options: false,
            action: this.process
        }
    }

    process(): Promise<any> {
        return this.$store.dispatch("admin_devices/disable", {selected: this.selectedDevices });
    }

}
