
import { Component, Watch } from 'vue-property-decorator';
import { MODAL_EDIT_ORGANISATION, MODAL_LEAVE_ORGANISATION, UserRole } from '@/Data';
import { Command } from '@/Utils';
import { modal } from '@/Toast';

import { RootView } from "@/components"
import { SummaryCardSheet } from '@/components/sheets';
import { CardLayout, CenterLayout, ClassicContainerLayout, SummaryDetailsLayout, TabLayout } from '@/components/layouts';
import { BackButton, SuperAdminButton, TabButton } from '@/components/buttons';
import { Dropdown, SettingsDropdown } from '@/components/dropdowns';

import OrganisationUsersView from './OrganisationUsersView.vue';
import BaseOrganisationView from "./BaseOrganisationView";

@Component({
    components: {
        ClassicContainerLayout, TabLayout, BackButton,
        SuperAdminButton, SettingsDropdown, Dropdown,
        SummaryCardSheet, SummaryDetailsLayout, CardLayout,
        OrganisationUsersView, TabButton, CenterLayout
    }
})
export default class OrganisationPageView extends BaseOrganisationView {}
