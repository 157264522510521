import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import { network } from "@/network"
import { modules } from "@/store";

import UserOrganisationModel from "@/models/UserOrganisationModel";
import { jsonParse, SearchIndex } from "@/Utils";
import searchUsersInOrganisation, { OrganisationUsersSearchFilters } from "@/search/OrganisationUsersSearch";
import { InternalRole, UserRole } from "@/Data";

@Module
export default class OrganisationUsersModule extends VuexModule {

    index: SearchIndex<UserOrganisationModel, OrganisationUsersSearchFilters> = new SearchIndex("usr_id", {searchRoot: searchUsersInOrganisation});
    loaded = false;
    checkboxAll = false;
    
    filters: {
        name: string,
        role: string
    } = {
        name: "",
        role: ""
    }

    @Mutation
    clear() {
        this.index.clear();
        this.filters.name = ""
        this.filters.role = ""
        this.loaded = false;
    }

    @Mutation
    setSearchRole(role: string) {
        this.filters.role = role;
    }

    @Mutation
    setUsers(users: UserOrganisationModel[]) {
        this.index.map(users);
        this.loaded = true;
    }

    @Mutation
    setSearchedChecked() { this.index.checkAll(this.checkboxAll); }
    
    @Mutation
    add(user: UserOrganisationModel) { this.index.add(user); }

    @Mutation
    updateUser(data: {usrId: string, role: string}) {
        const userModel = this.index.get(data.usrId);
        if (userModel) {
            userModel.role = data.role;
        }
    }

    @Mutation
    removeUser(usr_id: string) { this.index.remove(usr_id); }

    @Mutation
    search() {
        this.index.search({
            name: this.filters.name,
            role: this.filters.role,
        });
    }

    @Action
    async fetch(data: {org_id: string}) {
        const rawUsers: UserOrganisationModel[] = jsonParse((await network().get(`/op/user/organisations/${data.org_id}/users`)).data);
        this.setUsers(rawUsers);
    }

    @Action
    async invite(data: {org_id: string, email: string, role: UserRole}): Promise<boolean> {
        const responseData: any = jsonParse((await network().post(`/op/user/organisations/${data.org_id}/users`, JSON.stringify({
            email: data.email,
            role: data.role
        }))).data);

        if (responseData.error) {
            return false;
        }

        return true;
    }

    @Action
    async update(data: {org_id: string, usr_id: string, role: UserRole}): Promise<boolean> {
        const responseData: any = await network().put(`/op/user/organisations/${data.org_id}/users/${data.usr_id}`, JSON.stringify({
            role: data.role
        }));

        if (responseData.error) {
            return false;
        }


        this.updateUser({usrId: data.usr_id, role: data.role});
        return true;
    }

    @Action
    async remove(data: {org_id: string, usr_id: string}): Promise<boolean> {
        const responseData: any = await network().delete(`/op/user/organisations/${data.org_id}/users/${data.usr_id}`);

        if (responseData.error) {
            return false;
        }

        this.removeUser(data.usr_id);
        return true;
    }

}