export enum FirmwareUploadType {
    BIN = "bin",
    HEX = "hex",
    ELF = "elf"
}

export interface FirmwareUpload {
    path: string,
    type: FirmwareUploadType
}

export enum BoardDesign {
    DESIGN_3 = "DESIGN_3",
    DESIGN_4 = "DESIGN_4"
}

export default interface FirmwareModel {
    firmware_id: string,
    release: number,
    version: string,
    name: string,
    path: string,
    files: FirmwareUpload[]
    description: string,
    latest?: boolean,
    board: BoardDesign
}

