import { render, staticRenderFns } from "./TimestampText.vue?vue&type=template&id=c8b7553c&"
import script from "./TimestampText.vue?vue&type=script&lang=ts&"
export * from "./TimestampText.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports