
import { Vue, Prop, Component } from "vue-property-decorator";
import { RootView, NavbarContent } from "@/components";
import { DeviceOptionsDropdown, OrganisationSearchDropdown } from "@/components/dropdowns";
import { TimestampText } from "@/components/texts";
import { LastUpdateBlock, TagsBlock } from "@/components/blocks";
import { DeviceInfoSheet } from "@/components/sheets";

@Component({
    components: {
        NavbarContent,
        DeviceOptionsDropdown,
        TimestampText,
        DeviceInfoSheet,
        OrganisationSearchDropdown,
        LastUpdateBlock,
        TagsBlock
    }
})
export default class DeviceSummaryHomeView extends RootView {
    onClose() {
        this.$emit("close")
    }

    get paddingSize() {
      if (this.isMobile) {
        return "p-1";
      }
      return "p-3";
    }
}
