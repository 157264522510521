
import { Component, Vue } from 'vue-property-decorator';
import Branding from "@/components/Branding.vue";
import { goTo, GotoMessageType } from '@/Toast';

@Component({
    components: {
        Branding
    }
})
export default class PageNotFoundPageView extends Vue {
    on404BtnClick() {
        goTo({type: GotoMessageType.GOTO_PUSH, path: "/map"});
    }
}
