

import { Vue, Prop, Component } from "vue-property-decorator";

import BaseProfileView from "./BaseProfileView";

import { SettingsDropdown } from "@/components/dropdowns";
import { TabLayout } from "@/components/layouts";
import { BackButton } from "@/components/buttons";

import MobileUserInfoView from "./MobileUserInfoView.vue";
import MobileUserInvitationsView from "./MobileUserInvitationsView.vue";
import MobileUserMembershipsView from './MobileUserMembershipsView.vue';

@Component({
    components: {
        SettingsDropdown, TabLayout, 
        MobileUserInfoView, MobileUserInvitationsView,
        MobileUserMembershipsView, BackButton
    }
})
export default class MobileProfileView extends BaseProfileView {

}
