
import { Component } from "vue-property-decorator";
import RootView from "../RootView";
import SelectDropdown from "./SelectDropdown.vue";

@Component({
    components: {
        SelectDropdown
    }
})
export default class UserOrganisationRoleDropdown extends RootView {
    onClick(value: any) { this.$emit("click", value); }
    onClear() { this.$emit("clear"); }
}
