import {emptyString, falseBoolean, searchItems, specificString } from "@/search/Search";
import DeviceModel from "@/models/DeviceModel";

export interface DeviceSearchFilters {
    label: string,
    orgs: string,
    treatment_type: string,
    last_update_type: string,
    license_expired: boolean,
    transducer_disconnected: boolean
}

const isInStorage = (device: DeviceModel): boolean => {
    if (!device.local) return false;
    return device.local.content.storage;
}

export default searchItems<DeviceModel, DeviceSearchFilters>(new Map([
    ["label", {
        default: emptyString,
        validation: (label: string, device: DeviceModel): boolean => {

            if (label.trim() == "") {
                return false;
            }
        
            if (!device.local && !device.info) {
                return true;
            }

            let skip = false;
            if (device.local) {
                skip = !device.local?.content.label.toLowerCase().includes(label.toLowerCase());
            }

            if (skip && device.info) {
                skip = !device.info?.content.serialnumber.replaceAll("EMF1201-", "EMF")?.toLowerCase().includes(label.toLowerCase());
            }

            return skip;
        }
    }],
    ["orgs", {
        default: specificString("ALL"),
        validation: (org_id: string, device: DeviceModel): boolean => {
            return org_id != "ALL" && device.org_id != org_id;
        }
    }],
    ["treatment_type", {
        default: specificString("ALL"),
        validation: (treatment_type: string, device: DeviceModel): boolean => {
            const treatmentType = treatment_type.toUpperCase();
            if (treatmentType == "ALL") {
                return false;
            }

            if (!device.transducer || !device.configuration) {
                return true;
            }

            switch(treatmentType) {
                case "TREATMENT_ON":
                    if (isInStorage(device)) return true;
                    return !(device.transducer.content.activated && device.configuration.content.active);
                case "TREATMENT_OFF":
                    if (isInStorage(device)) return true;
                    return !(!device.transducer.content.activated && !device.configuration.content.active);
                case "IN_STORAGE":
                    return !isInStorage(device);
                default: 
                    if (isInStorage(device)) return true;
                    return !((device.transducer.content.activated && !device.configuration.content.active) 
                            || (!device.transducer.content.activated && device.configuration.content.active));
            }
        }
    }],
    ["last_update_type", {
        default: specificString("ALL"),
        validation: (update_type: string, device: DeviceModel): boolean => {
            const updateType = update_type.toUpperCase().trim();
            const deviceUpdateType = device.src.toUpperCase().trim();
            if (updateType == "ALL") {
                return false;
            }
            return deviceUpdateType != updateType;
        }
    }],
    ["license_expired", {
        default: falseBoolean,
        validation:  (licenseExpiredOnly: boolean, device: DeviceModel): boolean => {
            const timestamp: number = Date.now()
            if (!licenseExpiredOnly) {
                return false;
            }
            const licenseExpired: boolean = device.license == undefined || (device.license != null  
                                && timestamp >= device.license.content.expiry);
            if (licenseExpiredOnly && !licenseExpired) return true;        
            if (device.licenserequest === undefined) return false;
            return device.licenserequest.content.expiry >= timestamp;
        }
    }],
    ["transducer_disconnected", {
        default: falseBoolean,
        validation: (transducerDisconnectedOnly: boolean, device: DeviceModel): boolean => {
            return device.transducer != null && transducerDisconnectedOnly && device.transducer?.content.connected;
        }
    }],
]));