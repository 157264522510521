
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class ListFiltersLayout extends Vue {
    @Prop({default: "250px"}) width!: string;
    @Prop({default: "16px"}) gap!: string;

    get styling() {
        return `max-width: ${this.width}; !important; width: ${this.width} !important; min-width: ${this.width} !important; gap: ${this.gap};`;
    }
}
