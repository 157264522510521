
import { Vue, Component, Prop } from "vue-property-decorator";

import FirmwareModel, { BoardDesign } from "@/models/FirmwareModel";
import { isStringEmpty,  Command } from "@/Utils";
import { modal, toast, ToastType } from "@/Toast";
import { MODAL_ADMIN_VIEW_FW, MODAL_ADMIN_CREATE_FW } from "@/Data";

import { ListLayout, ListHeaderLayout, ListFiltersLayout, ListTooltipLayout, CenterLayout } from "@/components/layouts";
import { CheckboxField, SearchbarField } from "@/components/fields";
import { PlusButton } from "@/components/buttons";
import { Dropdown, BoardDesignDropdown } from "@/components/dropdowns";
import { MissingData, RootView, FilterSort } from "@/components";

import AdminViewLayout from "../AdminViewLayout.vue";

@Component({
    components: {
        AdminViewLayout,
        ListLayout, ListHeaderLayout, ListFiltersLayout, ListTooltipLayout,
        SearchbarField, PlusButton, MissingData, CenterLayout,
        CheckboxField, FilterSort, Dropdown, BoardDesignDropdown
    }
})
export default class AdminFirmwaresPageView extends RootView {

    commands: Command[] = [
      { id: "latest", name: "Assign latest firmware" },
      { id: "delete", name: "Delete" }
    ]

    viewFirmwareFile() {
        window.open(process.env.VUE_APP_ADMIN_MOBILE_VERSION_FILE, "_blank");
    }

    onFilterByUpdate() {
        this.$store.commit("admin_firmwares/sort");
    }

    onViewButtonClicked(firmware: FirmwareModel) {
        modal(MODAL_ADMIN_VIEW_FW, firmware);
    }

    onButtonCreateClicked() {
        modal(MODAL_ADMIN_CREATE_FW);
    }

    onSearch() {
      this.$store.dispatch("firmwares/search");
    }

    onCommandClicked(item: Command) {
        const selected: FirmwareModel[] = this.$store.state.admin_firmwares.index.selected;
        if (selected.length == 0) {
            toast({
                type: ToastType.TOAST_ERROR,
                message: "Please select one of the following firmware, before proceeding.."
            })
            return;
        }

        switch(item.id) {
            case "latest":
                
                break;
            default:
                break;
        }
    }

}

