
import { Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

import AppLink from "@/components/AppLink.vue";
import { MODAL_EDIT_PASSWORD, MODAL_EDIT_PROFILE, MODAL_LOGOUT } from "@/Data";
import { modal } from "@/Toast";

@Component({
    components: {
        AppLink
    }
})
export default class UserMenu extends RootView {
    singleCapitalLetter(letter: string) {
        return this.capitalize(letter).charAt(0);
    }

    goToProfile() {
        this.goto("/profile");
    }

    showChangePwModal() {
        modal(MODAL_EDIT_PASSWORD);
    }

    showEditProfileModal() {
        modal(MODAL_EDIT_PROFILE);
    }

    showLogoutModal() {
        modal(MODAL_LOGOUT);
    }
}
