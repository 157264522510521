import { Component } from "vue-property-decorator";
import { RootView } from "@/components";
import { Command, RoleOption } from "@/Utils";
import { MODAL_EDIT_ORGANISATION_USER, MODAL_INVITE_ORGANISATION, MODAL_KICK_ORGANISATION_USER, UserRole } from "@/Data";
import { modal, toast, ToastType } from "@/Toast";

@Component
export default class BaseOrganisationUsersView extends RootView {
    
    commands: Command[] = [
        { id: "rank", name: this.$t("organisation_command_rank").toString() },
        { id: "kick", name: this.$t("organisation_command_kick").toString() } 
    ]

    get selectedUsers() {
        return this.$store.state.organisation_users.index.selected;
    }

    onRoleChange(role: RoleOption | undefined) {
        let searchRole = "";

        if (role != undefined) {
            this.selectRole = role.id as UserRole;
            searchRole = role.id;
        } 

        this.$store.commit("organisation_users/setSearchedRole", searchRole);
        this.onSearch();
    }

    onRoleClear () {
        this.$store.commit("organisation_users/setSearchedRole", "");
        this.onSearch();
    }

    openInvite() {
        modal(MODAL_INVITE_ORGANISATION);
    }

    onSearch() {
        this.$store.dispatch("organisation_users/search");
    }

    onCheckboxChanged() {
        this.$store.commit("organisation_users/setSearchedChecked");
    }

    onCommandClicked(command: Command) {
        const selected = this.$store.state.organisation_users.index.selected;
        if (selected.length <= 0) {
            toast({
                message: this.$t("toast_missing_selection", ["user"]).toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }

        switch(command.id) {
            case "kick":
                modal(MODAL_KICK_ORGANISATION_USER, {
                    org_id: this.$store.state.organisations.model.org_id,
                    users: selected
                });
                break;
            default:
            case "rank":
                modal(MODAL_EDIT_ORGANISATION_USER, {
                    org_id: this.$store.state.organisations.model.org_id,
                    users: selected
                });
                break;
        }
    }
}