
import {Vue, Prop, Component} from "vue-property-decorator";
import RootView from "@/components/RootView";
import PageTitle from "@/components/PageTitle.vue";

import AccessForbiddenView from "@/views/AccessForbiddenView.vue";

@Component({
    components: {
        AccessForbiddenView,
        PageTitle
    }
})
export default class ClassicContainerLayout extends RootView {
    @Prop({default: null}) auth?: (() => boolean) | null;
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) barCss!: string;

    get authCheck(): boolean {
        if (this.auth == null) return true;
        return this.auth();
    }
}

