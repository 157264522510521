
import { Prop, Component } from "vue-property-decorator";
import RootView from "../RootView";
import SelectDropdown from "./SelectDropdown.vue";
import { ACTION_ADMIN_FILTERS, ACTION_ADMIN_SEARCH_DEVICES, QUERY_PARAM_TREATMENT } from "@/Data";

@Component({
    components: {
        SelectDropdown
    }
})
export default class TreatmentTypesSearchDropdown extends RootView {

    @Prop({default: ACTION_ADMIN_FILTERS}) commitTo!: string;
    @Prop({default: ACTION_ADMIN_SEARCH_DEVICES}) search!: string;
    @Prop({default: () => {return {}}}) src!: any;

    $refs!: {
      treatmentTypeDropdown: SelectDropdown,
    }

    mounted() {
        if (!this.src.searchedTreatmentType) return;
        const treatmentTypes = this.treatmentTypes;
        let result = undefined;

        for (let i = 0; i < treatmentTypes.length; i++) {
          if (treatmentTypes[i].value == this.src.searchedTreatmentType)  {
            result = treatmentTypes[i];
          }
        }

        if (result) {
          this.commitTreatmentTypeChange(result);
          this.$refs.treatmentTypeDropdown.forceValue(result);
        }
    }

    commitTreatmentTypeChange(treatmentType: any) {
        let elem: any | undefined = treatmentType;
        if (elem.key == "all") {
            elem = undefined;
        }

        this.$store.commit(this.commitTo, {filter: "treatmentType", value: elem});
    }

    onTreatmentTypeChange(treatmentType: any) {
        this.commitTreatmentTypeChange(treatmentType);
        this.$store.commit(this.search);
        this.setQuery(QUERY_PARAM_TREATMENT, treatmentType.value.toLowerCase());
    }

    onTreatmentTypeClear() {
        this.$store.commit(this.commitTo, {filter: "treatmentType", value: undefined});
        this.$store.commit(this.search);
        this.removeFromQuery(QUERY_PARAM_TREATMENT);
    }

}

