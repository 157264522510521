
import { Component } from "vue-property-decorator";
import { MODAL_LEAVE_ORGANISATION } from "@/Data";

import { RootView, VForm } from "@/components";
import { ActionModalLayout, ListLayout } from "@/components/layouts";
import { CheckboxField } from "@/components/fields";
import { RoleTag } from "@/components/tags";
import { ModalOptions } from "@/Toast";
import UserOrganisationModel from "@/models/UserOrganisationModel";

@Component({
    components: {
        ActionModalLayout, CheckboxField, ListLayout,
        RoleTag
    }
})
export default class LeaveOrganisationModalView extends RootView {

    items: UserOrganisationModel[] = [];

    $refs!: {
        form: VForm
    }

    rules = {
        required: (value: boolean) => {
            if (value) return true;
            return "Required";
        }
    }

    checked = false;

    get options(): ModalOptions {
        return {
            modal: MODAL_LEAVE_ORGANISATION,
            title: "modal_leaveeorg_title",
            desc: "",
            size: "md",
            height: "auto",
            onData: this.onData,
            action: this.process,
            validations: this.validations,
            onOpen: this.onOpen
        }
    }

    onData(data: any) {
        this.items = data;
    }

    onOpen() {
        this.checked = false;
    }

    validations(): Promise<boolean> {
        return Promise.resolve(this.$refs.form.validate());
    }

    process(): Promise<any> {
        return this.$store.dispatch("user_memberships/leave", this.items).then(() => {
            if (this.$route.fullPath.includes("/organisation")) {
                this.goto("/profile");
            }
        })
    }

}
