
import { Component } from "vue-property-decorator";

import { MissingData } from "@/components";
import { ListLayout, ListHeaderLayout, ListFiltersLayout, CenterLayout } from "@/components/layouts";
import { Dropdown, UserOrganisationRoleDropdown } from "@/components/dropdowns";
import { SearchbarField, CheckboxField } from "@/components/fields";
import { RoleTag } from "@/components/tags";

import BaseUserMembershipsView from "./BaseUserMembershipsView";

@Component({
    components: {
        MissingData, ListLayout, ListHeaderLayout, ListFiltersLayout,
        UserOrganisationRoleDropdown, SearchbarField, CheckboxField,
        Dropdown, CenterLayout, RoleTag
    }  
})
export default class UserMembershipsView extends BaseUserMembershipsView {}
