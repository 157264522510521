
import { PASSWORD_RULES, validatePassword } from "@/Utils";
import { Component, Prop, Watch } from "vue-property-decorator";

import RootView from "../RootView";
import { PasswordField } from "../fields";
import { PasswordRequirementText } from "../texts";
import { PASSWORD_REQUIREMENT } from "@/Data";

@Component({
    components: {
        PasswordField,
        PasswordRequirementText
    }
})
export default class PasswordSheet extends RootView {
    @Prop({default: ""}) value!: string;

    confirmPassword = "";

    requirement: {
        minChar: boolean,
        uppercase: boolean,
        number: boolean,
        special: boolean,
        match: boolean
    } = {
        minChar: false,
        uppercase: false,
        number: false,
        special: false,
        match: false
    }

    $refs!: {
        confirmPw: PasswordField
    }

    pwRules = [
        this.wrapRuleResult(PASSWORD_RULES.capital, "Password requirements must be met."), 
        this.wrapRuleResult(PASSWORD_RULES.number, "Password requirements must be met."),
        this.wrapRuleResult(PASSWORD_RULES.length, "Password requirements must be met."), 
        this.wrapRuleResult(PASSWORD_RULES.special, "Password requirements must be met.")
    ];

    get confirmPwRules() {
        return [
            (value: string): any => {
                if (value != null && value.trim().length > 0) return true;
                return this.$t("field_rule_empty")
            },
            (value: string): any => {
                if (this.value == value) return true;
                return this.$t("password_rule_match");
            }
        ]
    }
    
    onPwChanged(value: string) {
        this.$emit("input", value);
    }

    isValid() {
        return this.requirement.match
            && this.requirement.minChar
            && this.requirement.number
            && this.requirement.special
            && this.requirement.uppercase;
    }

    reset() {
        this.requirement = {
            minChar: false,
            uppercase: false,
            number: false,
            special: false,
            match: false
        }
        this.confirmPassword = "";
    }

    @Watch("confirmPassword")
    onConfirmPasswordChanged(value: string) {
        this.$nextTick(() => {
            this.requirement.match = (value == this.value);
        });
    }

    @Watch("value")
    onPasswordChanged(value: string) {
        const errors: PASSWORD_REQUIREMENT[] = validatePassword(value);

        const status = {
            minChar: true,
            number: true,
            special: true,
            uppercase: true,
            match: true
        };

        for (let i = 0; i < errors.length; i++) {
            switch (errors[i]) {
                case PASSWORD_REQUIREMENT.LENGTH:
                    status.minChar = false;
                    break;
                case PASSWORD_REQUIREMENT.NUMBER:
                    status.number = false;
                    break;
                case PASSWORD_REQUIREMENT.SPECIAL:
                    status.special = false;
                    break;
                case PASSWORD_REQUIREMENT.CAPITAL:
                    status.uppercase = false;
                    break;
            }
        }

        if (value != this.confirmPassword) {
            status.match = false;   
        }

        this.$nextTick(() => {
            this.requirement = status;
        })
    }
}
