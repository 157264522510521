import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import OrganisationModel from "@/models/OrganisationModel";
import { network } from "@/network";

@Module
export default class OrganisationModule extends VuexModule {

    model?: OrganisationModel;
    
    @Mutation
    clear() {
        this.model = undefined;
    }

    @Mutation
    set(organisation: OrganisationModel) {
        this.model = organisation;
    }

    @Mutation
    updateOrganisation(data: {organisation: OrganisationModel}) {
        if (!this.model) return;
        this.model!.name = data.organisation.name;
        this.model!.address = data.organisation.address;
        this.model!.phone = data.organisation.phone;
        this.model!.email = data.organisation.email;
        this.model!.website = data.organisation.website;
    }

    @Action
    async update(data: {organisation: OrganisationModel}) {
        await network().put(`/op/user/organisations/${this.model?.org_id}`, JSON.stringify(data.organisation));
        this.update(data);
    }
}   