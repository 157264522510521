
import { Component } from "vue-property-decorator";
import { MODAL_ADMIN_UPDATE_COUNTRY } from "@/Data";

import { RootView, VForm } from "@/components";
import { CountryDropdown } from "@/components/dropdowns";
import { DeviceModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";
import { Territory } from "@/plugins/countries";

@Component({
    components: {
        CountryDropdown,
        DeviceModalLayout
    }
})
export default class AdminUpdateCountryModalView extends RootView {

    country: Territory = {id: "", tag: ""};

    $refs!: {
        form: VForm
    }

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_UPDATE_COUNTRY,
            title: "modal_adminupdatecountry_title",
            desc: "modal_adminupdatecountry_desc",
            action: this.process,
            onOpen: this.onOpen,
            onOpened: this.onOpened,
            validations: this.validations
        }
    }

    onOpen() {
        if (this.selectedDevices.length > 1) {
            this.country = {id: "", tag: ""};
            return;
        }
        
        const selectedDevice = this.selectedDevices[0];
        const countryRaw: string | Territory | undefined = selectedDevice.data.local?.content.country;

        if (!countryRaw) {
            this.country = {id: "", tag: ""};
            return;
        }

        if (typeof countryRaw == "string") {
            this.country = this.$countries.byId(countryRaw) ?? {id: "", tag: ""};
            return;
        }

        this.country = countryRaw;
    }

    validations(): Promise<boolean> {
        return Promise.resolve(this.$refs.form.validate());
    }

    onOpened() {
        this.$refs.form.reset();
    }

    process(): Promise<any> {
        return this.$store.dispatch("admin_devices/setCountry", {selected: this.selectedDevices, options: {
            country: this.country.id
        }});
    }
}
