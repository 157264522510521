
import { Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import Markable from "@/components/Markable.vue";
import { MarkOptions } from "mark.js";

@Component({
    components: {
        Markable
    }
})
export default class ListLayout extends RootView {
    @Prop({default: () => []}) headers?: string[]
    @Prop({default: true}) showHeader?: boolean;
    @Prop({default: "justify-content-center" }) alignHeader?: string;
    @Prop({default: () => {return []}}) list!: any[];
    @Prop({default: () => {return (element: any) => {return element.id;}}}) rowKey!: (element: any) => string;
    @Prop({default: ""}) rowStyle!: string;
    @Prop({default: ""}) rowClass!: string;
    @Prop({default: ""}) type!: string;
    @Prop({default: false}) markable!: boolean;
    @Prop({default: ""}) markText!: string;
    @Prop({default: () => {return {}}}) markOptions!: MarkOptions | undefined;


}
