import Modal from "bootstrap/js/dist/modal";
import {eventBus} from "./main";
import { RawLocation } from "vue-router";
import { ModalType } from "./components";

export enum ToastType {
  TOAST_ERROR,
  TOAST_SUCCESS,
  TOAST_INFO
}

export interface ModalOptions {
    modal: string,
    title: string,
    desc: string,
    options?: boolean,
    height?: string,
    type?: ModalType,
    action?: () => Promise<any>,
    onOpen?: () => any,
    onOpened?: () => any,
    validations?: () => Promise<boolean>,
    onData?: (data: any) => void,
    fullscreen?: boolean,
    size?: string
}

export interface ToastMessage {
    message: string,
    type: ToastType
}

export interface ModalRegisterMessage {
    name: string,
    modal: Modal,
    callback?: (data: any) => void,
}

export enum GotoMessageType {
    GOTO_PUSH,
    GOTO_GO
}

export interface GotoMessage {
    type: GotoMessageType,
    path: RawLocation | number,
    noLoading?: boolean,
}

export interface ModalSrc {
    modal: Modal,
    callback?: (data: any) => void,
}

export const goTo = (message: GotoMessage) => {
    eventBus.$emit("go", message);
}

export const toast = (message: ToastMessage) => {
    eventBus.$emit("toast", message);
}

export const hideToast = () => {
    eventBus.$emit("toast-hide");
}

export const showLoading = (time: number) => {
    eventBus.$emit("loading", time);
}

export const hideLoading = () => {
    eventBus.$emit("loading-hide");
}

export const modal = (name: string, data: any | undefined = undefined, retry = false) => {
    eventBus.$emit("modal", {
        name: name,
        data: data,
        retry: retry
    });
}

export const hideModal = () => {
    eventBus.$emit("modal-hide");
}

export const registerModal = (message: ModalRegisterMessage) => {
    eventBus.$emit("modal-register", message);
}