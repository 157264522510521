
import { Prop, Component } from "vue-property-decorator";
import { RootView, Texts } from "@/components";

@Component({
    components: {
        TimestampText: Texts.TimestampText
    }
})
export default class AdminUpdateRow extends RootView {
    @Prop({default: ""}) title!: string; 
    @Prop({default: 0}) dbTimestamp!: number;
    @Prop({default: 0}) shadowTimestamp!: number;
}
