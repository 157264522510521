
import { Prop, Component } from "vue-property-decorator";
import { RootView } from "@/components";

@Component
export default class TextInputField extends RootView {
    @Prop({default: ""}) placeholder!: string;
    @Prop({default: ""}) value!: string;
    @Prop({default: () => {return []}}) rules!: any[];

    onInput(value: string) {
        this.$emit("input", value);
    }
}
