
import { Component, Vue, Prop } from "vue-property-decorator"

@Component
export default class BoxIndicatorBlock extends Vue {
    @Prop({default: ""}) title?: string;
    @Prop({default: "10px"}) fontSize!: string;

    get textStyle() {
        return `font-size: ${this.fontSize};`
    }
}
