
import { Vue, Prop, Component } from "vue-property-decorator";
import Dropdown from "./Dropdown.vue";
import { Command } from "@/Utils";

@Component({
    components: {
        Dropdown
    }
})
export default class SettingsDropdown extends Vue {
    @Prop({default: () => {return [];}}) items!: Command[];

    onCommandClicked(command: Command) {
        this.$emit('click', command);
    }
}
