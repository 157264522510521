import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

import { jsonParse, SearchIndex, toArray } from "@/Utils";
import searchOrgs, { UserOrganisationsSearchFilters } from "@/search/UserOrganisationSearch";

import UserOrganisationModel from "@/models/UserOrganisationModel";
import OrganisationModel from "@/models/OrganisationModel";
import { network } from "@/network";

@Module
export default class UserMembershipModule extends VuexModule {
    index: SearchIndex<UserOrganisationModel, UserOrganisationsSearchFilters> = new SearchIndex("org_id", {searchRoot: searchOrgs});
    organisation?: OrganisationModel;

    checkboxAll = false;
    filters: {
        name: string,
        role: string
    } = {
        name: "",
        role: ""
    }

    @Mutation
    clear() {
        this.index.clear();
        this.checkboxAll = false;
        this.filters.name = "";
        this.filters.name = "";
        this.organisation = undefined;
    }
    
    @Mutation
    setCheckboxAll() { this.index.checkAll(this.checkboxAll); }
    
    @Mutation
    setFilter(payload: {filter: string, value: any}) {
        (this.filters as any)[payload.filter] = payload.value;
    }
    
    @Mutation
    reset() {
        this.filters.name = "";
        this.filters.role = "";
        this.checkboxAll = false;
    }
    
    @Mutation
    setOrganisations(userMemberships: UserOrganisationModel[]) {
        userMemberships.forEach((value: UserOrganisationModel) => {
            value.name = value.org?.name ?? "";
        });

        this.index.map(userMemberships);
    }

    @Mutation
    remove(selected: string[]) {
        this.index.remove(...selected);
    }

    @Mutation
    search() {
        const role = (this.filters.role ?? "").toLowerCase().trim();
        this.index.search({
            name: this.filters.name,
            role: (role == "all") ? "" : role
        });
    }

    @Action
    fetch() {
        return network().get(`/op/user/memberships`).then((value) => {
            this.setOrganisations(toArray(jsonParse(value.data)));
        });
    }

    @Action
    leave() {
        const selectedIds = this.index.selected.map((value: UserOrganisationModel) => {
            return value.org_id
        });
        return network().delete('/op/user/memberships', {
            data: JSON.stringify({
                memberships: selectedIds
            })
        }).then(() => {
            this.remove(selectedIds);
        });
    }
}