import { Axios } from "axios";
import { modules } from "@/store"

export const network = (): Axios => {
    return new Axios({
        baseURL: process.env.VUE_APP_API,
        timeout: 150000,
        headers: {
            'Authorization': `Bearer ${modules.auth.token}`,
            'Content-Type': 'application/json'
        },
        validateStatus: (status: number) => {
            return status >= 200 && status < 300;
        }
    });
}

export const fileUpload = (): Axios => {
    return new Axios({});
}
