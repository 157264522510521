import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";

import { network } from "@/network"
import DeviceModel, { isDisplayedOnMap } from "@/models/DeviceModel"
import { SearchItemModel } from "@/models/SearchItemModel";
import OrganisationModel from "@/models/OrganisationModel";
import { DeviceSelected } from "@/models/DeviceSelection";
import { QUERY_PARAM_LABEL, QUERY_PARAM_LAST_UPDATE_TYPE, QUERY_PARAM_LICENSE_EXPIRED, QUERY_PARAM_ORG, QUERY_PARAM_SORT, QUERY_PARAM_TRANSDUCER_DISCONNECTED, QUERY_PARAM_TREATMENT, SORT_DESC } from "@/Data";
import searchDevices, { DeviceSearchFilters } from "@/search/DeviceSearch";
import { deviceSortFunction, hasProperty, jsonParse, SearchIndex } from "@/Utils";

const handleTags = (data: any) => {
    if (!data.local || (data.local && !data.local.content)) {
        return data;
    }

    const tags: string =  data.local.content.tags ?? "";
    let tagsData: string[] = [];

    if (tags.trim() != '') {
        tagsData = tags.split(",")
    }

    data.local.content.tags = tagsData;
    return data;
}

const identifyUnit = (data: any) => {
    data.device_id = data.pk.replace("DEV#", "").trim();
    data.org_id = data.sk.replace("ORG#", "").trim();
    return data; 
}

@Module
export default class OrganisationDevicesModule extends VuexModule {

    index: SearchIndex<DeviceModel, DeviceSearchFilters> = new SearchIndex("dev_id", { searchRoot: searchDevices });
    mapDevices: DeviceModel[] = [];

    checkboxAll = false;
    loaded = false;
    showInStorage = false;

    filters: {
        label: string,
        lastUpdateType: any,
        treatmentType: any,
        organisation: OrganisationModel | undefined,
        licenseExpiredOnly: boolean,
        transducerDisconnectedOnly: boolean,
        sort: string
    } = {
        label: "",
        lastUpdateType: "ALL",
        treatmentType: "ALL",
        organisation: undefined,
        licenseExpiredOnly: false,
        transducerDisconnectedOnly: false,
        sort: SORT_DESC
    }
   
    @Mutation
    clear() {
        this.checkboxAll = false;
        this.loaded = false;
        this.index.clear();
        this.mapDevices = [];
        this.showInStorage = false;
        this.filters = {
            label: "",
            treatmentType: "ALL",
            lastUpdateType: "ALL",
            organisation: undefined,
            licenseExpiredOnly: false,
            transducerDisconnectedOnly: false,
            sort: SORT_DESC
        }
    }

    @Mutation 
    setQuery(payload: {query: any, organisations: OrganisationModel[]}) {
        const query = jsonParse(JSON.stringify(payload.query));

        if (hasProperty(query, QUERY_PARAM_LABEL)) {
            this.filters.label = query[QUERY_PARAM_LABEL].trim();
        }

        if (hasProperty(query, QUERY_PARAM_LAST_UPDATE_TYPE)) {
            this.filters.lastUpdateType = query[QUERY_PARAM_LAST_UPDATE_TYPE].trim();
        }

        if (hasProperty(query, QUERY_PARAM_TREATMENT)) {
            this.filters.treatmentType = query[QUERY_PARAM_TREATMENT].trim();
        }

        if (hasProperty(query, QUERY_PARAM_TRANSDUCER_DISCONNECTED)) {
            this.filters.transducerDisconnectedOnly = query[QUERY_PARAM_TRANSDUCER_DISCONNECTED];
        }

        if (hasProperty(query, QUERY_PARAM_LICENSE_EXPIRED)) {
            this.filters.licenseExpiredOnly = query[QUERY_PARAM_LICENSE_EXPIRED];
        }
        
        if (hasProperty(query, QUERY_PARAM_SORT)) {
            this.filters.sort = query[QUERY_PARAM_SORT].trim();
        }

        if (hasProperty(query, QUERY_PARAM_ORG)) {
            this.filters.organisation = payload.organisations.find((value: OrganisationModel) => {
                return value.org_id == query[QUERY_PARAM_ORG];
            })
        }
    }

    @Mutation
    setFilter(payload: {filter: string, value: any}) {
        (this.filters as any)[payload.filter] = payload.value;
    }
        
    @Mutation
    sortByUpdatedTime(payload: {direction: string}) { this.index.searched.sort(deviceSortFunction(payload.direction)); }

    @Mutation
    setSearchedChecked() { this.index.checkAll(this.checkboxAll); }

    @Mutation
    push(devices: DeviceModel[]) {
        this.index.add(...devices);
    }

    @Action
    async fetch(data: {org_id: string}) {
        const responseData: any = jsonParse((await network().get(`/d/user/organisations/${data.org_id}/devices`)).data);
        const result: DeviceModel[] = [];

        if (!responseData.body) {
            return;
        }

        if (Array.isArray(responseData.body) ) {
            for(let i = 0; i < responseData.body.length; i++) {
                result.push(identifyUnit(handleTags(responseData.body[i])));
            }
        } else {
            result.push(identifyUnit(handleTags(responseData.body)));
        }
        
        this.push(result);
    }
    
    @Mutation
    search() {
        this.index.search({
            label: this.filters.label,
            orgs: this.filters.organisation?.org_id ?? "ALL",
            treatment_type: this.filters.treatmentType?.value ?? "ALL",
            last_update_type: this.filters.lastUpdateType?.value ?? "ALL",
            license_expired: this.filters.licenseExpiredOnly,
            transducer_disconnected: this.filters.transducerDisconnectedOnly
        });

        this.mapDevices = [];
        for (let i = 0; i < this.index.models.length; i++) {
            if (isDisplayedOnMap(this.index.models[i], this.showInStorage)) {
                this.mapDevices.push(this.index.models[i]);
            }
        }

        this.index.searched.sort((a: SearchItemModel<DeviceModel>, b: SearchItemModel<DeviceModel>) => {
            const firstInStorage = a.model.local?.content.storage ?? false;
            const secondInStorage = b.model.local?.content.storage ?? false;
            if (firstInStorage && !secondInStorage) {
                return 1;
            }
            if (!firstInStorage && secondInStorage) {
                return -1;
            }
            
            const firstTs = a.model.ts;
            const secondTs = b.model.ts;

            if (firstTs < secondTs) {
                return 1;
            }
            return -1;
        });
    }

    @Action
    updateStorage(payload: {devices: DeviceSelected[], state: boolean}) {
        // wip
    }

    
}   