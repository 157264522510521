
import { Component } from "vue-property-decorator";

import { RootView } from '@/components';
import { DeviceModalLayout } from "@/components/layouts";
import { MODAL_ORGANISATION_STORAGE } from "@/Data";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class OrganisationDevicesStorageModalView extends RootView {

    storageSwitch = false;

    get options(): ModalOptions {
        return {
            modal: MODAL_ORGANISATION_STORAGE,
            title: "modal_organisationstorage_title",
            desc: "modal_organisationstorage_desc",
            action: this.action
        }
    }

    get colorCodeStyle(): string {
        if (this.storageSwitch) return 'color: green';
        return 'color: red';
    }

    action(): Promise<any> {
        return this.$store.dispatch("organisation_devices/updateStorage", {
            devices: this.selectedDevices,
            state: this.storageSwitch
        });
    }
}
