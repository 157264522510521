

import RootView from "@/components/RootView";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CustomRowText extends RootView {
    @Prop({default: ""}) icon!: string;
    @Prop({default: ""}) section!: string;
    @Prop({default: ""}) value!: string;

    get sectionText() {
        return this.$t(this.section);
    }
}

