
import { Component, Prop } from "vue-property-decorator";

import { arrayDeleteElementByIndex } from "@/Utils";

import { RootView } from "@/components";
import { InputAddButton, InputDeleteButton } from "@/components/buttons";

import TextInputField from "./TextInputField.vue";
import TextInputExtrasField from "./TextInputExtrasField.vue";

@Component({
    components: {
        TextInputField, TextInputExtrasField, InputAddButton, InputDeleteButton
    }
})
export default class ListTextField extends RootView {
    @Prop({default: () => {return [""]}}) value!: string[];
    @Prop({default: () => {return []}}) rules!: any[];
    @Prop({default: 10}) max!: number;
    @Prop({default: ""}) placeholder!: string;

    onAddClicked() {
        const newValue = this.value.slice(0);
        newValue.push("");
        this.$emit("input", newValue);
    }

    onDeleteClicked(index: number) {
        const newValue = this.value.slice(0);
        arrayDeleteElementByIndex(newValue, index);
        this.$emit("input", newValue);
    }
}
