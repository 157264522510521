
import { Prop, Component } from "vue-property-decorator";
import SelectDropdown from "./SelectDropdown.vue";
import { BoardDesign } from "@/models/FirmwareModel";
import RootView from "../RootView";
import { Command } from "@/Utils";
import { QUERY_PARAM_BOARD } from "@/Data";

@Component({
    components: {
        SelectDropdown
    }
})
export default class BoardDesignDropdown extends RootView {
    @Prop({default: () => {return undefined}}) base!: Command | undefined;
    @Prop({default: () => {return [
        {id: BoardDesign.DESIGN_3, name: "Design 3"},
        {id: BoardDesign.DESIGN_4, name: "Design 4"}
    ]}}) src!: Command[];
    @Prop({default: "admin_firmwares/setFilter"}) commitTo!: string;
    @Prop({default: "admin_firmwares/search"}) search!: string;

    $refs!: {
      firmwareDropdown: SelectDropdown
    }

    mounted() {
        if (!this.base) return;
        const boards = this.wrapBoard(this.src);
        let result = undefined;

        for (let i = 0; i < boards.length; i++) {
            if (boards[i].org_id == this.base.id)  {
                result = boards[i];
            }
        }

        if (result) {
            this.commitBoardChange(result);
            this.$refs.firmwareDropdown.forceValue(result);
        }
    }

    onBoardClear() {
        this.$store.commit(this.commitTo, {filter: "board", value: undefined});
        this.$store.commit(this.search);
        this.removeFromQuery(QUERY_PARAM_BOARD);
    }

    commitBoardChange(board_raw: Command) {
        let board: Command | undefined = board_raw;
        if (board_raw.id == "all") {
            board = undefined;
        }

        this.$store.commit(this.commitTo, {filter: "board", value: board?.id});
    }

    onBoardChange(board: Command) {
        this.commitBoardChange(board);
        this.$store.commit(this.search);
        this.setQuery(QUERY_PARAM_BOARD, board?.id ?? "all");
    }

    wrapBoard(boards: Command[]) {
        const results: any[] = [];
  
        results.push({org_id: "all", name: this.$t("all")});
        for (let i = 0; i < boards.length; i++) {
          results.push(boards[i]);
        }
  
        return results;
    }
}
