
import { Component } from "vue-property-decorator";
import { MODAL_EDIT_PROFILE } from "@/Data";
import { validatePhone } from "@/Utils";

import { ErrorList, RootView } from "@/components";
import { EditableField } from "@/components/fields";
import { ActionModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        ActionModalLayout,
        EditableField,
        ErrorList
    }
})
export default class EditProfileModalView extends RootView {

    $refs!: {
        editInfoName: EditableField,
        editInfoPhone: EditableField,
        editInfoEmail: EditableField,
        editInfoAddress: EditableField,
        editInfoWebsite: EditableField
    }

    name = "";
    phone = "";
    address = "";
    website = "";
    errors: string[] = [];

    get options(): ModalOptions {
        return {
            title: "modal_editprofile_title",
            desc: "",
            modal: MODAL_EDIT_PROFILE,
            size: "md",
            height: "350px",
            onOpened: this.reset,
            validations: this.validations,
            action: this.process,
        }
    }
    
    reset() {
        this.errors = [];
        this.name = this.$store.state.user.user.name;
        this.phone = this.$store.state.user.user.phone;
        this.website = this.$store.state.user.user.website;
        this.address = this.$store.state.user.user.address;

        this.$refs.editInfoName.stop();
        this.$refs.editInfoPhone.stop();
        this.$refs.editInfoWebsite.stop();
        this.$refs.editInfoAddress.stop();
    }

    validations(): Promise<boolean> {
        this.errors = [];
        let errors: string[] = validatePhone(this.phone);
        if (errors.length > 0) {
            this.errors = errors;
            return Promise.resolve(false);
        }
        return Promise.resolve(true);
    }

    process(): Promise<any> {
        return this.$store.dispatch('user/edit', {
            phone: this.phone,
            name: this.name,
            website: this.website
        })
    }
}
