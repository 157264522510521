import { emptyString, searchItems } from "./Search";
import UserOrganisationModel from "@/models/UserOrganisationModel";

export interface UserOrganisationsSearchFilters {
    name: string,
    role: string
}

export default searchItems<UserOrganisationModel, UserOrganisationsSearchFilters>(new Map([
    ["name", {
        default: emptyString,
        validation: (val: string, item: UserOrganisationModel): boolean => {
            return val.trim() != "" && 
                !(item.org?.name.toLowerCase().trim().includes(val.toLowerCase())
                || item.org?.name.toLowerCase().trim().includes(val.toLowerCase()));
        }
    }],
    ["role", {
        default: emptyString,
        validation: (val: string, item: UserOrganisationModel): boolean => {
            return val.trim() != "" && item.role.toLowerCase() != val.toLowerCase();
        }
    }]
]));