import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import { network } from "@/network"
import DeviceModel, { DeviceLicense, serialNumber } from "@/models/DeviceModel"
import { jsonParse, toArray } from "@/Utils";
import JobModel from "@/models/JobModel";
import { DeviceUpdateContent } from "@/models/DeviceSelection";
import EventModel, { EventSubLoaderEvent } from "@/models/EventModel";
import SubLoaderModel from "@/models/SubLoaderModel";
import TimestampModel from "@/models/TimestampModel";
import { DAILY_TAG } from "@/Data";

export const handleDeviceLicensesRequests = (rawlicensesRequests: any): DeviceLicense[] => {
    let licenses: any[] = [];

    if (!rawlicensesRequests) {
        return licenses;
    }
    
    if (!Array.isArray(rawlicensesRequests)) {
        licenses = [rawlicensesRequests];
    } else if (Array.isArray(rawlicensesRequests)) {
        licenses = rawlicensesRequests;
    }
    
    const latestLicenses = new Map<number, DeviceLicense>();
    for (let i = 0; i < licenses.length; i++) {
        const key: string = licenses[i]["sk"].replace("LIC#", "").trim();
        const ts: number = licenses[i]["timestamp"];
        const isLatest = key == "LATEST";
        if (isLatest && latestLicenses.has(ts)) {
            latestLicenses.get(ts)!.latest = true;
            continue;
        }

        latestLicenses.set(ts, {
            expiry: licenses[i]["expiry"],
            id: licenses[i]["key"],
            latest: isLatest,
            creationDate: ts
        });
    }

    const licensesResults: DeviceLicense[] = Array.from(latestLicenses.values());
    licensesResults.sort((a: DeviceLicense, b: DeviceLicense) => {
        if ((a.creationDate ?? 0) > (b.creationDate ?? 0)) {
            return -1;
        }
        return 1;
    });
    return licensesResults;
}

@Module
export default class DeviceModule extends VuexModule {

    device?: DeviceModel;
    events: EventSubLoaderEvent<EventModel[]> = {
        content: [],
        loaded: false,
        next_token: "",
        last_page: 0
    };

    jobs: SubLoaderModel<JobModel[]> = {
        content: [],
        loaded: false
    };

    timestamps: SubLoaderModel<TimestampModel> = {
        content: {
            trns: 0,
            roam: 0,
            dev: 0,
            lic: 0,
            cfg: 0,
            flags: 0,
            sens: 0,
            gnss: 0,   
        },
        loaded: false
    };

    licenseRequests: SubLoaderModel<DeviceLicense[]> = {
        content: [],
        loaded: false
    };

    @Mutation
    setDevice(device: DeviceModel | undefined) {
        this.device = device;
    }

    @Mutation
    clear() {
        this.device = undefined;
        this.events = {
            loaded: false,
            content: [],
            next_token: "",
            last_page: 0,
        };
        this.jobs = {
            loaded: false,
            content: []
        };
        this.timestamps = {
            content: {
                trns: 0,
                roam: 0,
                dev: 0,
                lic: 0,
                cfg: 0,
                flags: 0,
                sens: 0,
                gnss: 0,   
            },
            loaded: false
        };
        this.licenseRequests = {
            content: [],
            loaded: false
        };
    }

    @Mutation
    setLicenseRequests(licenseRequests: DeviceLicense[]) {
        this.licenseRequests = {
            content: licenseRequests,
            loaded: true
        };
    }

    @Mutation
    setTimestamps(timestamps: TimestampModel) {
        this.timestamps = {
            content: timestamps,
            loaded: true
        };
    }

    @Mutation
    setJobs(jobs: JobModel[]) {
        this.jobs = {
            content: jobs,
            loaded: true
        };
    }

    @Mutation
    setEvents(payload: {count: number, data: EventModel[], offset: string}) {
        this.events.loaded = true;
        this.events.content.push(...payload.data);
        this.events.next_token = payload.offset;
        this.events.last_page++;
    }

    @Action
    async fetchLicenseRequests() {
        if (!this.device) return;
        const licensesRequests = toArray(jsonParse((await network().get(`d/admin/devices/${serialNumber(this.device)}/licenses`)).data).body);
        this.setLicenseRequests(handleDeviceLicensesRequests(licensesRequests));
    }

    @Action
    async fetchEvents() {
        let result:  {count: number, data: EventModel[], offset: string};

        if (!this.events.loaded) {
            result = jsonParse((await network().get(`d/admin/lists/dev/${this.device?.device_id}/event?limit=25&des=1`)).data).body;
            this.setEvents(result);
        } else {
            result = jsonParse((await network().get(`d/admin/lists/dev/${this.device?.device_id}/event?limit=25&des=1&offset=${this.events.next_token}`)).data).body;
            this.setEvents(result);
        }

        if (result.offset.length > 0) {
            result = jsonParse((await network().get(`d/admin/lists/dev/${this.device?.device_id}/event?limit=25&des=1&offset=${this.events.next_token}`)).data).body;
            this.setEvents(result);
        }
    }

    @Action
    async fetchTimestamps() {
        let timestamps: TimestampModel;
        try {
            timestamps = jsonParse((await network().get(`d/admin/devices/${this.device?.device_id}/timestamps`)).data).body ?? {};
        } catch (exception) {
            timestamps = {
                trns: 0,
                roam: 0,
                dev: 0,
                lic: 0,
                cfg: 0,
                flags: 0,
                sens: 0,
                gnss: 0
            };
        }

        this.setTimestamps(timestamps);
    }

    @Action 
    async fetchJobs() {
        let jobs: JobModel[] = [];
        try {
            jobs = toArray(jsonParse((await network().get(`d/admin/devices/${this.device!.device_id}/jobs`)).data).body) ?? [];

            jobs.sort((a: JobModel, b: JobModel) => {
                if ((a.created_ts ?? a.timestamp) > (b.created_ts ?? b.timestamp)) {
                    return -1;
                }
                return 1;
            });
        } catch (exception) {
            jobs = [];
        }
        this.setJobs(jobs);
    }

    @Action
    async fetchDevice(data: {orgId: string,deviceId: string}) {
        const responseData: any = jsonParse((await network().get(`/d/user/organisations/${data.orgId}/devices/${data.deviceId}`)).data);
        if (Object.keys(responseData.body).length === 0) {
            this.setDevice(undefined);
            return;
        } 
        
        const tags: string =  responseData.body.local?.content.tags ?? "";
        let tagsData: string[] = [];

        if (tags.trim() != '') {
            tagsData = tags.split(",")
        }

        let isDaily = false;
        tagsData = tagsData.filter((value) => {
                        if (value == DAILY_TAG) {
                            isDaily = true;
                        }
                        return value != DAILY_TAG;
                    });
        
        if (responseData.body.local) {
            responseData.body.local.content.tags = tagsData;
        }
        
        responseData.body.org_id = responseData.body.sk.replace("ORG#", "");
        responseData.body.device_id = responseData.body.pk.replace("DEV#", "");
        responseData.body.daily = isDaily;

        if (!responseData.body.src) {
            responseData.body.src = "UNKNOWN";
        }

        this.setDevice(responseData.body);
    }

    @Mutation
    changeTags(tags: string[]) {
        this.device!.local!.content.tags = tags;
    }

    @Mutation
    changeLabel(label: string) {
        this.device!.local!.content.label = label;
    }

    @Mutation
    changeStorage() {
        this.device!.local!.content.storage = true;
    }

    @Mutation
    toggleDevice(element: boolean) {
        this.device!.configuration!.content.active = element;
        this.device!.desiredconfiguration!.content.active= element;
    }

    @Mutation
    initLocal() {
        this.device!.local = {
            content: {
                label: "",
                tags: [],
                storage: false
            },
            timestamp: Date.now()
        }
    }

    @Action
    updateLocal(payload: {type: string, content: any}) {
        const body: any = {};

        if (!this.device!.local) {
            this.initLocal();
        }

        switch(payload.type) {
            case 'tags':
                body.tags = payload.content;
                this.changeTags(payload.content);
                break;
            case 'label':
                body.label = payload.content;
                this.changeLabel(payload.content);
                break;
            case 'storage':
                body.storage = payload.content;
                this.changeStorage();
                this.toggleDevice(false);
                break;
            default:
                break;
        }

        body.type = payload.type;

        return network().put(`/op/user/organisations/${this.device?.org_id}/devices/${this.device?.device_id}`, JSON.stringify(body));
    }

    @Action
    setTags(payload: DeviceUpdateContent<{interaction: boolean, tags: string[]}>): Promise<boolean> {
        const tagTransactions: string[] = payload.options!.tags;
        const interaction: boolean = payload.options!.interaction;

        const tags: Set<string> = new Set(this.device?.local?.content.tags ?? []);
        for (let i = 0; i < tagTransactions.length; i++) {
            if (interaction) {
                tags.add(tagTransactions[i]);
            } else {
                tags.delete(tagTransactions[i]);
            }
        }

        this.changeTags(Array.from(tags));
        return network().put("/op/admin/devices", JSON.stringify({
            action: "tags",
            options: payload.options,
            selected: payload.selected
        }));
    }

    @Action
    toggle(payload: {content: boolean}) {
        this.toggleDevice(payload.content);

        return network().patch(`/op/user/organisations/${this.device?.org_id}/devices/${this.device?.device_id}`, JSON.stringify({
            status: payload.content
        }));
    }
}