
import { Vue, Prop, Component } from "vue-property-decorator";
import { RootView } from "@/components";
import { ActionModalLayout, ListLayout } from "@/components/layouts"
import { RoleTag } from "@/components/tags";
import { ModalOptions } from "@/Toast";
import { INVITATION_RESPONSE, MODAL_USER_INVITATION_ACCEPT } from "@/Data";
import UserInvitationModel from "@/models/UserInvitationModel";

@Component({
    components: {
        ActionModalLayout, ListLayout, RoleTag
    }
})
export default class AcceptInvitationModalView extends RootView {
    selected: UserInvitationModel[] = [];

    get options(): ModalOptions {
        return {
            modal: MODAL_USER_INVITATION_ACCEPT,
            title: "modal_acceptinvitation_title",
            desc: "",
            height: "296px",
            size: "md",
            action: this.process,
            onData: this.onData
        }
    }

    onData(selected: UserInvitationModel[]) {
        this.selected = selected;
    }

    process(): Promise<any> {
        return this.$store.dispatch("user_invitations/confirm", {
            task: INVITATION_RESPONSE.ACCEPT,
            selected: this.selected
        }).then(() => {
            setTimeout(() => {
                this.$store.dispatch("user_memberships/fetch");
            }, 500);
        });
    }
}
