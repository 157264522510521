import { Coordinates } from "@/Coordinates";
import { v4 as uuid } from "uuid";
import { checkAll, SearchItemModel } from "./models/SearchItemModel";
import DeviceModel from "./models/DeviceModel";
import { PASSWORD_REQUIREMENT, SORT_DESC } from "./Data";

export class SearchIndex<T, Y> {
    models: T[] = [];
    ids: Map<string, T> = new Map();
    searched: SearchItemModel<T>[] = [];
    key!: string;
    searchRoot?: (items: T[], search: Y) => T[];

    constructor(key: string, options?: {searchRoot: (items: T[], search: Y) => T[]}) {
        this.key = key;
        this.searchRoot = options?.searchRoot;
    }

    map(models: T[]) {
        this.models = models;
        for (let i = 0; i < this.models.length; i++) {
            this.ids.set((this.models[i] as any)[this.key], this.models[i]);
        }
        this.searched = wrapSearchItems(models);
    }

    has(key: string): boolean {
        return this.ids.has(key);
    }

    get(key: string): T | undefined {
        return this.ids.get(key);
    }

    add(...model: T[]) {
        this.models.push(...model);
        this.map(this.models);
    }

    clear() {
        this.models = [];
        this.ids.clear();
        this.searched = [];
    }

    search(query: Y): SearchItemModel<T>[] {
        if (!this.searchRoot) return [];
        this.searched = wrapSearchItems(this.searchRoot(this.models, query));
        return this.searched;
    }

    checkAll(value: boolean) {
        checkAll(this.searched, value);
    }

    remove(...keys: string[]) {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const arrCopy = Array.from(this.models);
            const objWithIdIndex = arrCopy.findIndex((obj) => (obj as any)[key] === key);
            arrCopy.splice(objWithIdIndex, 1);
            this.map(arrCopy);
        }
    }

    get selected(): T[] {
        return selection(this.searched);
    }
}

export interface Tab {
    id: string,
    label: string
}

export interface FileUpload {
  id: string,
  file: File, 
  type: string | {id: string, name: string},
}

export interface RoleOption {
  id: string,
  name: string
}

export const fileReader = (file: File): Promise<ArrayBuffer | null | string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(reader.result)
      };

      reader.readAsArrayBuffer(file)
    })
  }


export const capitalize = (value: string) => {
    value = value.toLowerCase();
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export interface Command {
    id: string,
    name: string
}

export const isValidSerialNumber = (value: string): boolean => {
    if (!value.startsWith("EMF") || !value.startsWith("EMF1201-")) {
        return false;
    }
    const separator = value.indexOf("-") + 1;
    const id = value.substring(separator, value.length);

    if (id.length < 4) {
        return false;
    }
    return true;
}

export const isValidEmail = (email: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export const isValidPhone = (phone: string): boolean => {
    return /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(phone);
}

export const isOnlyNumbers = (str: string): boolean => /^\d+$/.test(str);

export const validatePhone = (phone: string): string[] => {
    const errors: string[] = [];

    if (!isValidPhone(phone)) {
        errors.push("phone_rule_international");
    }

    return errors;
}

export const PASSWORD_RULES = {
    length: (str: string | null): boolean => {
        return str != null && str.length >= 8;
    },
    capital: (str: string | null): boolean => {
        return str != null && /[A-Z]/.test(str);
    },
    number: (str: string | null): boolean => {
        return str != null && /[0-9]/.test(str);
    },
    special: (str: string | null): boolean => {
        return str != null && /[$&+,:;=?@#|'<>.^*()%!-]/.test(str);
    }
}

export const validatePassword = (password: string): PASSWORD_REQUIREMENT[] => {
    const errors: PASSWORD_REQUIREMENT[] = [];

    if (!PASSWORD_RULES.length(password)) {
        errors.push(PASSWORD_REQUIREMENT.LENGTH);
    }

    if (!PASSWORD_RULES.capital(password)) {
        errors.push(PASSWORD_REQUIREMENT.CAPITAL);
    }

    if (!PASSWORD_RULES.number(password)) {
        errors.push(PASSWORD_REQUIREMENT.NUMBER);
    }

    if (!PASSWORD_RULES.special(password)) {
        errors.push(PASSWORD_REQUIREMENT.SPECIAL)
    }

    return errors;
}

export const isValidImei = (value: string): boolean => {
    return /^\d+$/.test(value) && value.length == 15;
}

export const isValidVersion = (value: string): boolean => {
    return /^\d+\.\d+\.\d+(?:[a-zA-Z]|-[\w]+)?(?:\+[\w.-]+)?$/.test(value);
}

export const genUUID = (): string => {
    return uuid();
}

export const toArray = (item: any | any[]): any[] => {
    if (item == undefined) {
        return [];
    }
    if (Array.isArray(item)) {
        return item;
    }
    return [item];
}

export enum UnitDateType {
    YEAR = "year",
    MONTH = "month",
    DAY = "day",
    HOUR = "hour",
    WEEK = "week",
    MINUTE = "minute"
}

export const isString = (x: any): boolean => {
    return typeof x === 'string' || Object.prototype.toString.call(x) === "[object String]"
}

export const dayToString = (value: number): string => {
    return (new Date(value)).toISOString().substr(0, 10);
}

export const dayToPreciseString = (value: number): string => {
    return (new Date(value)).toISOString();
}

export const activatedTime = (time: number): {days: number, minutes: number, hours: number}=> {
    const days = Math.round(time / (24 * 60));
    const rawMinutes = Math.round(time % (24* 60));
    const hours = Math.round(rawMinutes / 60);
    const minutes = rawMinutes % 60;

    return {
        days: days,
        minutes: minutes,
        hours: hours
    };
}

export const betweenDaysConversion = (fromDate: Date, toDate: Date, allowSmaller = false, allowWeeks = false): {value: number, unit: UnitDateType } => {

    const days = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24));

    if (days > 365) {
        return {
            value: Math.floor(days / 365),
            unit: UnitDateType.YEAR
        }
    }

    const weeks = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24 * 7));
    if (weeks > 0 && allowWeeks) {
        return {
            value: weeks,
            unit: UnitDateType.WEEK
        }
    } 

    if (days > 3 || !allowSmaller) {
        return {
            value: days,
            unit: UnitDateType.DAY
        }
    }

    const hours = Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 3600));
    if (hours >= 2) {
        return {
            value: hours,
            unit: UnitDateType.HOUR
        }
    }

    return {
        value: Math.floor((toDate.getTime() - fromDate.getTime()) / (1000 * 60)),
        unit: UnitDateType.MINUTE
    }
}

export const isDefaultCoordinates = (coord: Coordinates): boolean => {
    return coord.longitude == 0 && coord.latitude == 0 && coord.accuracy == 0;
}

export const wrapSearchItems = <T>(items: T[]): SearchItemModel<T>[] => {
    const results: SearchItemModel<T>[] = [];

    for (let i = 0; i < items.length; i++) {
        results.push({
            checked: false,
            model: items[i]
        })
    }

    return results;
}

export const hasProperty =(object: Record<string, unknown>, key: string): boolean => {
    return Object.prototype.hasOwnProperty.call(object, key);
}

export const isObjectEmpty = (object: any): boolean => {
    return (
      object &&
      Object.keys(object).length === 0 &&
      object.constructor === Object
    );
};

export const isStringEmpty = (str: string): boolean => {
    return (!str || str.length === 0 );
}

export const getUserTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const localIsoDateString = (ts: number) : string => {
    const tzoffset: number = (new Date()).getTimezoneOffset() * 60000;
    return (new Date(ts - tzoffset)).toISOString().slice(0, -8);
}

export const localIsoDateWithoutDaytimeString = (ts: number) : string => {
    const tzoffset: number = (new Date()).getTimezoneOffset() * 60000;
    return (new Date(ts - tzoffset)).toISOString().split('T')[0];
}

export const nth = (d: number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  };
  
export const dateStrFormat = (timestamp: number): string => {
    const date: Date = new Date(timestamp);
    const monthFormatter = new Intl.DateTimeFormat('en',  { month: 'short', timeZone: "America/New_York"});
    const strMonth = monthFormatter.format(date);
    const dayOfTheMonth = date.getDate();
    const year = date.getFullYear();
    return `${strMonth} ${dayOfTheMonth}${nth(dayOfTheMonth)} ${year}`
}

export const getDayOfTheWeekFromNumber = (day: number): string => {
    switch (day) {
        case 0:
        default:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4: 
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
    }
}

export const dateStrFormatWithTime = (timestamp: number): string => {
    const date: Date = new Date(timestamp);
    const monthFormatter = new Intl.DateTimeFormat('en',  { month: 'short', timeZone: "America/New_York"});
    const strMonth = monthFormatter.format(date);
    const dayOfTheMonth = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const dayOfTheWeek = getDayOfTheWeekFromNumber(date.getDay());
    return `${dayOfTheWeek} ${strMonth} ${dayOfTheMonth}${nth(dayOfTheMonth)} ${year} ${hours}:${minutes}`
}

export const getExtendedVersionNumber = (version: string): number => {
    if (version.trim() == "") {
      return 0;
    }
    if (version.includes("+")) {
      version = version.substring(0, version.indexOf("+"));
    }
    const versionCellsString: string[] = version.split('.');
    const versionCells: number[] = versionCellsString.map((i) => Number.parseInt(i));
    return versionCells[0] * 100000 + versionCells[1] * 1000 + versionCells[2];
}

export const selection = <Y>(models: SearchItemModel<Y>[], checked = true): Y[] => {
    const results: Y[] = [];

    for (let i = 0; i < models.length; i++) {
        if (models[i].checked == checked) {
            results.push(models[i].model);
        }
    }

    return results;
}

export function promiseWithTimeout<T>(payload: {promise: Promise<T>, defaultValue: T, timeout: number}): Promise<T> {
    return Promise.race<T>([
        payload.promise,
        new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(payload.defaultValue);
            }, payload.timeout)
        })
    ]);
}

export const promiseWithTimeoutBool = (payload: {promise: Promise<boolean>, timeout?: number}): Promise<boolean> => {
    const timeout = payload.timeout ?? 500;
    return promiseWithTimeout<boolean>({promise: payload.promise, defaultValue: false, timeout: timeout});
}

export class Pause {
    take: Promise<any>;
    constructor(val: number) {
        this.take = new Promise((resolve, reject) => {
            setTimeout(() => {resolve(true)}, val);
        });
    }
}

export const setPause = (time: number): Pause => {
    return new Pause(time);
}

export const pausify = async (promise: Promise<any>, time: number): Promise<any> => {
    const pause: Pause = setPause(time);
    let result: any;

    try {
        result = await promise;
    } catch (exception) {
        await pause.take;
        throw exception;
    }

    await pause.take;
    return result;
}

export const deviceSortFunction = (direction: string) => {
    return (a: SearchItemModel<DeviceModel>, b: SearchItemModel<DeviceModel>) => {
        if (a.model.ts == b.model.ts) {
            return 0;
        }
    
        if (a.model.ts > b.model.ts) {
            return (direction == SORT_DESC) ? -1: 1;
        } else {
            return (direction == SORT_DESC) ? 1: -1;
        }
    };
}

export const urlRemoveQueryAndHashSafe = (url: string): string => {
    return url.replace(/[?#].*$/, '');
}

export const doesStringIncludeAnyFromArray = (value: string, labels: string[]): boolean => {
    for (let i = 0; i < labels.length; i++) {
        if (value.includes(labels[i])) return true;
    }

    return false;
}

export const arrayDeleteElementByIndex = <T> (arr: T[], index: number): T[] => {
    if (index < 0 || index >= arr.length) {
      throw new Error("Index out of bounds");
    }
  
    arr.splice(index, 1); // Removes 1 element at the specified index
    return arr;
}

export const jsonParse = (data: any): any => {
    if (!data) {
        return undefined;
    }
    
    if (typeof data == "string") {
        return JSON.parse(data);
    } else {
        return data;
    }
}