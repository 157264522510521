import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import AuthModule from '@/store/modules/AuthModule'
import OrganisationModule from '@/store/modules/organisations/OrganisationModule'
import OrganisationDevicesModule from '@/store/modules/organisations/OrganisationDevicesModule'
import DeviceModule from "@/store/modules/DeviceModule"
import OrganisationUsersModule from "@/store/modules/organisations/OrganisationUsersModule"
import UserModule from '@/store/modules/user/UserModule'
import AdminOrganisationsModule from '@/store/modules/admin/AdminOrganisationsModule'
import AdminModule from '@/store/modules/admin/AdminModule'
import AdminDevicesModule from '@/store/modules/admin/AdminDevicesModule'
import AdminFirmwaresModule from "@/store/modules/admin/AdminFirmwaresModule"
import InviteModule from '@/store/modules/InviteModule'
import GlobalModule from "@/store/modules/GlobalModule"
import UserMembershipModule from "@/store/modules/user/UserMembershipModule"
import UserInvitationModule from "@/store/modules/user/UserInvitationModule";

const store = new Vuex.Store({});

const globalModule = new GlobalModule({store: store, name: "global"});
const authModule = new AuthModule({store: store, name: "auth"});
const inviteModule = new InviteModule({store: store, name: "invite"});

const userModule = new UserModule({store: store, name: "user"});
const userMembershipModule = new UserMembershipModule({store: store, name: "user_memberships"});
const userInvitationModule = new UserInvitationModule({store: store, name: "user_invitations"});

const organisationModule = new OrganisationModule({store: store, name: "organisations"});
const organisationDevicesModule = new OrganisationDevicesModule({store: store, name: "organisation_devices"});
const organisationUsersModule = new OrganisationUsersModule({store: store, name: "organisation_users"});

const deviceModule = new DeviceModule({store: store, name: "device"});

const adminModule = new AdminModule({store: store, name: "admin"});
const adminOrganisationsModule = new AdminOrganisationsModule({store: store, name: "admin_organisations"});
const adminDevicesModules = new AdminDevicesModule({store: store, name: "admin_devices"});
const adminFirmwaresModule = new AdminFirmwaresModule({store: store, name: "admin_firmwares"});

export const modules = {
    global: globalModule, auth: authModule, invite: inviteModule,
    user: userModule, userMemberships: userMembershipModule, userInvitations: userInvitationModule,
    organisation: organisationModule, organisationDevices: organisationDevicesModule, organisationUsers: organisationUsersModule,
    device: deviceModule, admin: adminModule, 
    adminOrganisations: adminOrganisationsModule, adminDevices: adminDevicesModules,
    adminFirmwares: adminFirmwaresModule
}

export default store;