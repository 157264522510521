
import { Prop, Component } from "vue-property-decorator";

import { RootView } from "@/components";
import { InfoRowText, CustomRowText } from "@/components/texts";
import { RoleTag } from "@/components/tags";

@Component({
    components: {
        InfoRowText,
        CustomRowText, RoleTag
    }
})
export default class MobileOrganisationInfoView extends RootView {
    @Prop({default: ""}) role!: string;
    @Prop({default: ""}) name!: string;
    @Prop({default: ""}) phone!: string;
    @Prop({default: ""}) address!: string;
    @Prop({default: ""}) website!: string;
}
