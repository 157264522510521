
import { RootView, VForm } from "@/components";
import { Component } from "vue-property-decorator";

import { MODAL_ADMIN_MANAGE_DAILY_EMAIL } from "@/Data";
import { isValidEmail } from "@/Utils";
import { ModalOptions } from "@/Toast";

import { ListTextField } from "@/components/fields";
import { ActionModalLayout } from "@/components/layouts";

@Component({
    components: {
        ActionModalLayout,
        ListTextField
    }
})
export default class AdminManageDailyEmailModalView extends RootView {
    $refs!: {
        form: VForm
    }

    emails: string[] = [""];

    rules = {
        required: (value: string) => !!value || 'Required.',
        email: (value: string) => value.trim().length == 0 || isValidEmail(value) || "Invalid email",
    }

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_MANAGE_DAILY_EMAIL,
            title: "modal_adminmanagedailyemail_title",
            desc: "",
            size: "md",
            action: this.confirm,
            validations: this.validations,
            onOpen: this.init
        }
    }

    init() {
        this.emails = this.$store.state.admin.appSettings.daily_email_list.slice();
    }

    validations(): Promise<any> {
        return Promise.resolve(this.$refs.form.validate());
    }

    confirm(): Promise<any> {
        return this.$store.dispatch("admin/updateDailyEmailList", this.emails);
    }
}
