import { VuexModule, Module, Mutation, Action} from "vuex-class-modules";
import { fileUpload, network } from "@/network";
import FirmwareModel, { BoardDesign, FirmwareUploadType } from "@/models/FirmwareModel";
import { FileUpload, SearchIndex, fileReader, getExtendedVersionNumber, jsonParse } from "@/Utils";
import { SearchItemModel } from "@/models/SearchItemModel";
import searchFirmwares, { AdmFirmwareSearchFilters }  from "@/search/AdminFirmwareSearch";
import { SORT_DESC } from "@/Data";

const fileKey = (type: string): string => {
    switch(type) {
        case "elf":
            return "zephyr.elf";
        case "hex": 
            return "merged.hex"
        default:
        case "bin":
            return "app_update.bin";
    }
}

const fileTypeToUploadType = (type: string): FirmwareUploadType => {
    switch (type) {
        case "elf":
            return FirmwareUploadType.ELF;
        case "hex":
            return FirmwareUploadType.HEX;
        default:
            return FirmwareUploadType.BIN;
    }
}

@Module
export default class AdminFirmwaresModule extends VuexModule {

    index: SearchIndex<FirmwareModel, AdmFirmwareSearchFilters> = new SearchIndex("fw_id", { searchRoot: searchFirmwares});
    checkboxAll = false;

    filters: {
        name: string,
        version: string,
        sort: string,
        board: BoardDesign | undefined
    } = {
        name: "",
        version: "",
        sort: SORT_DESC,
        board: undefined
    }

    @Mutation 
    setSearchedChecked() { this.index.checkAll(this.checkboxAll); }

    @Mutation
    clear() {
        this.index.clear();
        this.filters = {
            name: "",
            version: "",
            sort: SORT_DESC,
            board: undefined
        }
        this.checkboxAll = false;
    }

    @Mutation
    setAll(firmwares: FirmwareModel[]) {
        this.index.map(firmwares);
        this.index.searched.sort(((a: SearchItemModel<FirmwareModel>, b: SearchItemModel<FirmwareModel>) => {
            if (getExtendedVersionNumber(a.model.version) > getExtendedVersionNumber(b.model.version)) {
                return -1;
            }
            return 1;
        }));
    }

    @Mutation
    add(firmware: FirmwareModel) {
        this.index.add(firmware);
    }

    @Mutation
    update(firmware: FirmwareModel) {
        for (let i = 0; i < this.index.models.length; i++) {
            const element = this.index.models[i];
            if (element.firmware_id == firmware.firmware_id) {
                element.description = firmware.description,
                element.name = firmware.name,
                element.version = firmware.version;
                break;
            }
        }
    }

    @Mutation
    mutateLatest(version: string) {
        for (let i = 0; i < this.index.models.length; i++) {
            const element = this.index.models[i];
            if (element.version == version) {
                element.latest = true;
                break;
            } else if (element.latest != undefined && element.latest) {
                element.latest = false;
            }
        }
        this.index.search({
            name: this.filters.name,
            version: this.filters.version,
            board: this.filters.board ?? ""
        });
    }

    @Action 
    async setLatest(firmware: FirmwareModel) {
        await network().patch(`/op/admin/firmwares/${firmware.firmware_id}`);
        this.mutateLatest(firmware.version);
    }

    @Action
    async fetch() {
        const data: any =  jsonParse((await network().get("/d/admin/firmwares")).data).body;
        for (let i = 0; i < data.length; i++) {
            if (data[i].board == undefined) {
                data[i].board = BoardDesign.DESIGN_3;
            }
        }
        this.setAll(data);
        this.search();
    }

    @Mutation
    setFilter(payload: {filter: string, value: any}) {
        (this.filters as any)[payload.filter] = payload.value;
    }

    @Mutation
    search() {
        this.index.search({
            name: this.filters.name,
            version: this.filters.version,
            board: this.filters.board ?? ""
        });
    }

    @Action
    async create(payload: {
        latest: boolean, version: string, name: string, 
        description: string, files: FileUpload[], board: BoardDesign
    }) {
        const firmware: FirmwareModel = {
            firmware_id: "",
            name: payload.name,
            path: "",
            files: [], 
            release: Date.now(),
            version: payload.version,
            description: payload.description,
            board: payload.board
        };

        try {
            for (let i = 0; i < payload.files.length; i++) {

                const result = await network().post("/op/admin/firmwares/path", JSON.stringify({
                    path: `${payload.version}/${fileKey((payload.files[i].type as any).id)}`,
                }));

                const data = jsonParse(result.data);
                const fwKey = data.path;
                const type: FirmwareUploadType = fileTypeToUploadType((payload.files[i].type as any).id);
                
                if (type == FirmwareUploadType.BIN) {
                    firmware.path = fwKey;
                }
                
                firmware.files.push({
                    path: fwKey,
                    type: type
                });
    
                const buffer: ArrayBuffer | string | null = await fileReader(payload.files[i].file);
                await fileUpload().put(fwKey, buffer,  {
                        headers: { 'Content-Type': 'application/octet-stream' }
                });
            }
    
            const upload: any = firmware as any;
            upload.latest = payload.latest;
            const result: any = await network().post("/op/admin/firmwares", JSON.stringify(upload));
            const data = jsonParse(result.data);
            firmware.firmware_id = data.id;
            this.add(firmware);
        } catch (exception) {
            console.log(exception);
            return false;
        }
        
        return true;
    }

    @Mutation
    sort() {
        // todo
    }

}