import { Component } from "vue-property-decorator";
import { RootView } from "@/components";
import OrganisationModel from "@/models/OrganisationModel";
import { Command, RoleOption } from "@/Utils";
import { modal, toast, ToastType } from "@/Toast";
import { MODAL_LEAVE_ORGANISATION } from "@/Data";

@Component
export default class BaseUserMembershipsView extends RootView {

    commands: Command[] = [
        { id: "leave", name: this.$t("command_leave").toString() }
    ];

    onCommandClicked(command: Command) {
        const selected = this.$store.state.user_memberships.index.selected;
        if (selected.length <= 0) {
            toast({
                message: this.$t("toast_missing_selection", ["membership"]).toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }
        switch(command.id) {
            case "leave":
                modal(MODAL_LEAVE_ORGANISATION, selected);
                break;
            default:
                break;
        }
    }

    onRoleChange(role: RoleOption | undefined) {
        this.$store.commit("user_memberships/setFilter", {filter: "role", value: role?.id ?? ""});
        this.onSearch();
    }

    onRoleClear() {
        this.$store.commit("user_memberships/setFilter", {filter: "role", value: ""});
        this.onSearch();
    }

    onSearch() {
        this.$store.commit("user_memberships/search");
    }

    viewOrganisation(organisation: OrganisationModel) {
        this.goto({
            path: `/organisation/${organisation.org_id}`,
        })
    }
}