
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component
export default class InputDeleteButton extends RootView {
    onClearClicked() {
        this.$emit("delete");
    }
}
