
import { Component } from "vue-property-decorator";
import { Accordion, MissingData } from "@/components";
import { MobileListLayout, ListHeaderLayout, CenterLayout } from "@/components/layouts";
import { LastUpdateTypeSearchDropdown, TreatmentTypesSearchDropdown, OrganisationSearchDropdown, Dropdown } from "@/components/dropdowns";
import { CheckboxField, SearchbarField } from "@/components/fields";
import { DeviceDashboardBlock } from "@/components/blocks";

import BaseDashboardPageView from "./BaseDashboardPageView";

@Component({
    components: {
        MobileListLayout, Accordion, LastUpdateTypeSearchDropdown, TreatmentTypesSearchDropdown,
        OrganisationSearchDropdown, CheckboxField, SearchbarField, DeviceDashboardBlock,
        ListHeaderLayout, Dropdown, MissingData, CenterLayout
    }
})
export default class MobileDashboardPageView extends BaseDashboardPageView {
    
}
