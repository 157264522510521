import { Module, VuexModule, Action, Mutation } from "vuex-class-modules";
import { modules } from "../.."

import { network } from "@/network"
import UserModel from "@/models/UserModel";
import { jsonParse } from "@/Utils";

@Module
export default class UserModule extends VuexModule {

    user?: UserModel;

    @Mutation
    clear() {
        this.user = undefined;
    }
    
    @Mutation
    setUser(user: UserModel) {
        this.user = user;
    }

    @Action
    fetch() {
        return network().get(`/d/user/users/${modules.auth.user?.id}`).then((value) => {
            this.setUser(jsonParse(value.data).body);
        });
    }

    @Action 
    changePassword(payload: {old_password: string, new_password: string}) {
        return network().put("/op/user/password", JSON.stringify({
            old_password: payload.old_password,
            new_password: payload.new_password,
            access_token: modules.auth.accessToken
        }));
    }

    @Action
    edit(data: {phone: string, name: string, website: string}) {
        return network().put("/op/user/self", JSON.stringify({
            phone: data.phone,
            name: data.name,
            website: data.website
        })).then(() => {
            this.setUser({
                // eslint-disable-next-line 
                usr_id: this.user?.usr_id!,
                phone: data.phone,
                name: data.name,
                website: data.website,
                // eslint-disable-next-line
                email: this.user?.email!
            });
            return true;
        });
    }
}