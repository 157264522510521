
import { Component } from "vue-property-decorator";

import { MODAL_ADMIN_UPDATE_TAGS, SYSTEM_TAG_IGNORE_LICENSE } from "@/Data";
import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts"
import { ChipSearchField } from "@/components/fields";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        ChipSearchField,
        DeviceModalLayout
    }
})
export default class AdminUpdateTagsModalView extends RootView {

    setDeviceTags: string[] = [];

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_UPDATE_TAGS,
            title: "modal_admintags_title",
            desc: "modal_admintags_desc",
            onOpen: this.onOpen,
            action: this.process
        }
    }

    onOpen() {
        let tagSet: Set<string> = new Set();

        for (let i = 0; i < this.selectedDevices.length; i++) {
            const tags: string[] = this.selectedDevices[i].data.local?.content.tags ?? [];
            tags.forEach((value) => {
                if (value == SYSTEM_TAG_IGNORE_LICENSE) return;
                tagSet.add(value);
            })
        }

        this.setDeviceTags = Array.from(tagSet);
    }

    process(): Promise<any> {
        let targetAction = "admin_devices/setTags";
        if (this.isOnDevicePage) {
            targetAction = "device/setTags";
        }

        return this.$store.dispatch(targetAction, {
            options: {tags: this.setDeviceTags}, 
            selected: this.selectedDevices 
        });
    }

}
