
import { Prop, Component } from "vue-property-decorator";

import RootView from "../RootView";
import SelectDropdown from "./SelectDropdown.vue";
import OrganisationModel from "@/models/OrganisationModel";
import { ACTION_ADMIN_FILTERS, ACTION_ADMIN_SEARCH_DEVICES, QUERY_PARAM_ORG } from "@/Data";

@Component({
    components: {
        SelectDropdown
    }
})
export default class OrganisationSearchDropdown extends RootView {

    @Prop({default: () => {return undefined}}) base!: OrganisationModel | undefined;
    @Prop({default: () => {return []}}) src!: OrganisationModel[];
    @Prop({default: false}) unassigned!: boolean;
    @Prop({default: ACTION_ADMIN_FILTERS}) commitTo!: string;
    @Prop({default: ACTION_ADMIN_SEARCH_DEVICES}) search!: string;

    $refs!: {
      organisationDropdown: SelectDropdown
    }

    mounted() {
        if (!this.base) return;
        const organisations = this.wrapOrganisations(this.src);
        let result = undefined;

        for (let i = 0; i < organisations.length; i++) {
            if (organisations[i].org_id == this.base.org_id)  {
                result = organisations[i];
            }
        }

        if (result) {
            this.commitOrganisationChange(result);
            this.$refs.organisationDropdown.forceValue(result);
        }
    }

    onOrganisationClear() {
        this.$store.commit(this.commitTo, {filter: "organisation", value: undefined});
        this.$store.commit(this.search);
        this.removeFromQuery(QUERY_PARAM_ORG);
    }

    commitOrganisationChange(organisation: OrganisationModel) {
        let org: OrganisationModel | undefined = organisation;
        if (organisation.org_id == "all") {
            org = undefined;
        }

        this.$store.commit(this.commitTo, {filter: "organisation", value: org});
    }

    onOrganisationChange(organisation: OrganisationModel) {
        this.commitOrganisationChange(organisation);
        this.$store.commit(this.search);
        this.setQuery(QUERY_PARAM_ORG, organisation?.org_id ?? "all");
    }

    wrapOrganisations(organisations: OrganisationModel[]) {
        const results: any[] = [];
  
        results.push({org_id: "all", name: this.$t("all")});
        if (this.unassigned) {
            results.push({org_id: "no_assignment", name: this.$t("unassigned")});
        }
        
        for (let i = 0; i < organisations.length; i++) {
          results.push(organisations[i]);
        }
  
        return results;
    }
}
