
import { Component, Prop, Vue } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component
export default class DeviceFooterHomeView extends RootView {

    close() {
        this.$emit("close");
    }

    onLeftTooltipClicked() {
        this.$emit("previous");
    }

    onRightTooltipClicked() {
        this.$emit("next");
    }

    get tooltipLeftCaret(): string {
      if (this.index <= 0) {
        return "opacity: 0.5;";
      }
      return "cursor: pointer";
    }

    get displayIcon(): any {
      return this.deviceIcon(this.chooseDevice);
    }
    
    get tooltipRightCaret(): string {
      if (this.index >= this.$store.state.organisation_devices.mapDevices.length - 1) {
        return "opacity: 0.5;"
      }
      return "cursor: pointer";
    }
}
