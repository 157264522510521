
import { Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component({
    components: {}
})
export default class MobileListLayout extends RootView {
    @Prop({default: () => []}) headers?: string[]
    @Prop({default: true}) showHeader?: boolean;
    @Prop({default: "justify-content-center" }) alignHeader?: string;
    @Prop({default: () => {return []}}) list!: any[];
    @Prop({default: () => {return (element: any) => {return element.id;}}}) rowKey!: (element: any) => string;
    @Prop({default: ""}) type!: string;
}
