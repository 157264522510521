
import { Component } from "vue-property-decorator";

import { capitalize } from "@/Utils";

import { RootView, VForm } from "@/components";
import { EditbarField, PasswordField } from "@/components/fields";
import { LoginLayout } from "@/components/layouts";
import { PasswordSheet } from "@/components/sheets";
import { PasswordRequirementText } from "@/components/texts";

@Component({
    components: {
        EditbarField,
        PasswordField,
        LoginLayout,
        PasswordRequirementText,
        PasswordSheet
    }
})
export default class InvitationPageView extends RootView {

    name = "";
    password = "";

    rules = {
        nameRequired: (value: string) => {
            if (value.trim().length > 3) return true; 
            return this.$t("field_rule_min_char", ["Name", 3]);
        },
    }

    $refs!: {
        form: VForm,
    }

    get getRoleText(): string {
        let role: string = this.$store.state.invite.role;
        if (role == "member") {
            return ".";
        }

        if (role == "admin") {
            return ` as an ${capitalize(role)}.`;
        }
        return ` as a ${capitalize(role)}.`;
    }

    confirm(event: any) {
        event.preventDefault();  

        this.$nextTick(async () => {
            if (!this.$refs.form.validate()) {
                return;
            }

            await this.$store.dispatch("invite/signup", {
                name: this.name,
                password: this.password
            });

            await this.$store.dispatch("auth/login", {
                username: this.$store.state.invite.email,
                password: this.password
            });

            this.goto({ path: '/map'});
        })
    }

}
