
import { Vue, Component } from "vue-property-decorator";
import { NavbarContent, PageTitle, RootView } from "@/components";

@Component({
    components: {
        NavbarContent, PageTitle
    }
})
export default class AdminViewLayout extends RootView {
    links = {
        devices: "/admin",
        organisations: "/admin/organisations",
        firmwares: "/admin/firmwares"
    }

    isOnPage(value: string): boolean {
        return this.$route.path == value;
    }

    onPageLinkClass(value: string): string {
        if (this.$route.path == value) return 'active';
        return '';
    }
}

