
import { isMobile } from "mobile-device-detect";
import { genUUID } from "@/Utils";
import { Vue, Prop, Component } from "vue-property-decorator";

import RootView from "../RootView";
import PopupModal from "./PopupModal.vue";

@Component({
    components: {
        PopupModal
    }
})
export default class DialogPopupModal extends RootView {
    @Prop({default: ""}) title!: string;
    @Prop({default: ""}) subtitle!: string;
    @Prop({default: isMobile}) fullscreen!: boolean;
    @Prop({default: false}) titlestart!: boolean;
    @Prop({default: ""}) size!: string;
    @Prop({default: ""}) icon!: string;
    @Prop({default: ""}) header!: string;
    @Prop({default: "495px"}) height!: string;

    showBody = false;
    id = genUUID();

    get modalId() {
        return `modal_${this.id}`;
    }

    mounted() {
      // eslint-disable-next-line
      setTimeout(() => {
        // eslint-disable-next-line
        let that = this;
        document.querySelector(`#${this.modalId}`)!.addEventListener('show.bs.modal', function () {
          that.showBody = true;
          that.$emit("open");
        });

        document.querySelector(`#${this.modalId}`)!.addEventListener('shown.bs.modal', function () {
          that.$emit("opened");
        });

        document.querySelector(`#${this.modalId}`)!.addEventListener('hidden.bs.modal', function () {
          that.showBody = false;
        });
      }, 200)
    
    }

    get maxHeightStyle() {
      if (this.fullscreen) {
        return "";
      }
      return `height: ${this.height};`
    }

    get classValue() {
      let val = "";

      if (this.fullscreen) {
        val += "modal-fullscreen"
      }

      let size = this.sizeValue;

      if (size.length > 0) {
        val += ` ${size}`;
      }

      return val.trim();
    }

    get sizeValue() {
      if (this.size?.trim().length <= 0) return "";
      return `modal-${this.size}`;
    }

    confirm() {
        this.$emit("confirm");
    }
}
