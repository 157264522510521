
import RootView from "../RootView";
import { Prop, Component } from "vue-property-decorator";

@Component
export default class ListHeaderLayout extends RootView {
    @Prop({default: ""}) type!: string;
    @Prop({default: 0}) total!: number;
    @Prop({default: 0}) selected!: number;

    get classVal() {
        if (this.isMobile) {
            return "pe-2";
        }
        return "pe-5";
    }
    get style() {
        if (this.isMobile) {
            return "font-size: 11px; letter-spacing: 1.1px;";
        }
        return "font-size: 14px; letter-spacing: 1.1px;";
    }
}
