
import { Component } from "vue-property-decorator";
import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts";
import { MODAL_ADMIN_SET_DEVICE_DAILY } from "@/Data";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class AdminSetDeviceDailyModalView extends RootView {

    interaction = false;

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_SET_DEVICE_DAILY,
            title: "modal_adminsetdevicedaily_title",
            desc: 'modal_adminsetdevicedaily_desc',
            onOpen: this.onOpen,
            action: this.process
        }
    }

    onOpen() {
        if (this.selectedDevices.length != 1) return;
        this.interaction = this.selectedDevices[0].data.local?.content.daily ?? false;
    }
  
    process(): Promise<any> {
        return this.$store.dispatch("admin_devices/setDaily", {
            selected: this.selectedDevices,
            options: {
                interaction: this.interaction
            }
        });
    }
}
