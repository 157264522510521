import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import OrganisationModel from "@/models/OrganisationModel";
import { jsonParse, SearchIndex, toArray } from "@/Utils";
import { network } from "@/network";
import searchOrganisations, { AdminOrganisationSearchFilters } from "@/search/AdminOrganisationSearch";

@Module
export default class AdminOrganisationsModule extends VuexModule {
    organisation: OrganisationModel | undefined;

    index: SearchIndex<OrganisationModel, AdminOrganisationSearchFilters> = new SearchIndex("org_id", {
        searchRoot: searchOrganisations
    });
    checkboxAll = false;
    
    filters: {
        label: string
    } = {
        label: ""
    }

    @Mutation
    setQuery(params: any) {
        // todo
    }

    @Mutation
    clear() {
        this.index.clear();
        this.checkboxAll = false;
        this.filters = {
            label: ""
        };
    }

    @Mutation
    setAll(organisations: OrganisationModel[]) {
        this.index.map(organisations);
    }

    @Mutation
    setSearchedChecked() { this.index.checkAll(this.checkboxAll); }
    
    @Mutation
    search() {
        this.index.search({
            label: this.filters.label
        });
    }

    @Action
    async fetch() {
        let raws: any[] = jsonParse((await network().get("/d/admin/organisations")).data).body;
        const organisations: OrganisationModel[] = [];
        raws = toArray(raws);

        for (let i = 0; i < raws.length; i++) {
            const raw: any = raws[i];
            organisations.push({
                org_id: raw.pk.replace("ORG#", ""),
                name: raw.name,
                email: raw.email,
                phone: raw.phone,
                website: raw.website,
                address: raw.address
            });
        }

        this.setAll(organisations);
        this.search();
    }

    @Action
    create(organisation: OrganisationModel) {
        return network().post("/op/admin/organisations", JSON.stringify({
            "name": organisation.name,
            "address": organisation.address,
            "email": organisation.email,
            "website": organisation.website,
            "phone": organisation.phone
        }));
    }

}