
import { Component, Vue, Watch } from 'vue-property-decorator';
import { eventBus } from "./main";

import Modal from "bootstrap/js/dist/modal";

import Modals from "@/views/Modals.vue";
import Navbar from '@/views/Navbar.vue';
import MobileSidebar from "@/views/MobileSidebar.vue";
import Sidebar from "@/views/Sidebar.vue";
import AppLoadingView from "@/views/AppLoadingView.vue";

import { ToastMessage, ModalRegisterMessage, ModalSrc, GotoMessage, GotoMessageType } from "@/Toast";

import { RootView } from "@/components";
import { PopupToast, MobileToast } from './components/toasts';
import { ActionLoadingOverlay } from './components/overlays';

import { RawLocation } from 'vue-router';
import { pausify, urlRemoveQueryAndHashSafe, jsonParse } from './Utils';
import { RouteProps } from './router/Utils';

@Component({
  components: {
    Navbar,
    PopupToast,
    MobileToast,
    ActionLoadingOverlay,
    Modals,
    AppLoadingView,
    MobileSidebar,
    Sidebar,
  }
})
export default class App extends RootView {

  $refs!: {
    sidebar: MobileSidebar,
    toast: PopupToast | MobileToast,
    loading: ActionLoadingOverlay,
  }
  
  currentModal?: Modal;
  modals: Map<string, ModalSrc> = new Map();
  routeHasLoaded = false;
  allowLoadingAnimation = false;
  activeLoadingWatcher = true;
  
  pageProps!: RouteProps | undefined;
  showNavbar = true;
  overflowStatus = "overflow-hidden";

  routeProps(): any {
    this.pageProps =  this.$router.options.routes?.find((route: any) => {
      if (route.name == this.$route.name) {
        return true;
      }
    })?.props as RouteProps ?? undefined;
    this.showNavbar = (this.pageProps?._sidebar ?? true);
    if (!(this.pageProps?._overflow)) {
      this.overflowStatus = "";
    } else {
      this.overflowStatus = "overflow-hidden";
    }
  }

  setupLoading() {
    if (!this.pageProps?._loading) {
        this.allowLoadingAnimation = false;
        this.routeHasLoaded = true;
        this.$forceUpdate();
      } else {
        this.allowLoadingAnimation = true;
        setTimeout(() => {
            this.routeHasLoaded = true;
            this.$forceUpdate();
          }, 2000);
      }
  }

  beforeMount() {
    this.routeProps();
    if (this.$route.path != "/") {
      this.setupLoading();
    }
    
    eventBus.$on('loading', (time: number) => {
      this.$refs.loading?.showFor(time);
    });

    eventBus.$on('loading-hide', () => {
      this.$refs.loading?.forceHide();
    });

    eventBus.$on('toast-hide', () => {
      (this.$refs.toast as any)?.hide();
    });
    
    eventBus.$on('toast', (message: ToastMessage) => {
        (this.$refs.toast as any)?.show(message);
    });

    this.$countries.setup({events: {}});

    eventBus.$on('go', async (message: GotoMessage) => {
      const currentRoute = urlRemoveQueryAndHashSafe(this.$route.fullPath);
      let rawPathObj = message.path;
      let rawPath = "";
      let target = "";

      switch(message.type) {
          case GotoMessageType.GOTO_GO:
            if (message.path == -1) {
              if (window.history.state != null) {
                this.$router.back();
              } else {
                if (this.isAdmin) {
                  this.$router.push("/admin");
                } else {
                  this.$router.push("/map");
                }
              }
            } else {
              this.$router.go(message.path as number);
            }
            break;
          default:
          case GotoMessageType.GOTO_PUSH:

            if (typeof rawPathObj == 'string') {
              rawPath = rawPathObj;
            } else {
              rawPath = (rawPathObj as any).path;
            }
            target = urlRemoveQueryAndHashSafe(rawPath);

            if (
              !currentRoute.includes(target) && 
              (message.noLoading == undefined || !message.noLoading)
            ) {
              this.$refs.loading.showFor(500);
            }
            
            await pausify(new Promise<boolean>((resolve, reject) => {
              this.$router.push(message.path as RawLocation).then(() => {
                  resolve(true);
              });
            }), 500);

            break;
      }
    })

    eventBus.$on('modal-register', (message: ModalRegisterMessage) => {
      this.modals.set(message.name, {
        modal: message.modal,
        callback: message.callback
      });
    });

    eventBus.$on('modal-hide', () => {
      this.currentModal?.hide();
    });

    eventBus.$on('modal', (payload: {name: string, data?: any, retry: boolean}) => {
      if (!this.modals.has(payload.name)) {
        if (payload.retry) {
          setTimeout(() => {
            eventBus.$emit('modal', {name: payload.name, data: payload.data});
          })
        } else {
          console.error(`Modal not found : ${payload.name}`);
        }
        
        return;
      }
      const src: ModalSrc = this.modals.get(payload.name)!;
      if (src.callback) {
        src.callback(structuredClone(payload.data));
      }
      src.modal.show();
    });

    this.$store.commit("global/setQuery",jsonParse(JSON.stringify(this.$route.query)));

    this.$store.dispatch("auth/updateToken");
    setInterval(() => {
      this.$store.dispatch("auth/updateToken");
    }, 60 * 1000);
  }

  @Watch("$route.path")
  onRouteChange(newValue: string, oldValue: string) {
    this.routeProps();
    
    if (this.activeLoadingWatcher && oldValue == "/") {
      this.setupLoading();
      this.activeLoadingWatcher = false;
    }

    if (this.isMobile) {
      this.$refs.sidebar?.hide();
    }

    this.$store.commit("global/setQuery",jsonParse(JSON.stringify(this.$route.query)));
  }

  onNavbarClicked() {
    this.$refs.sidebar.show();
  }

  @Watch("$route.query")
  onQueryChange(newQuery: any) {
    this.$store.commit("global/setQuery", jsonParse(JSON.stringify(this.$route.query)));
  }

}

