
import { Vue, Prop, Component } from 'vue-property-decorator';
import RootView from "@/components/RootView";
import { modal } from '@/Toast';
import { MODAL_STORAGE } from '@/Data';

@Component
export default class StorageButton extends RootView {   
    
    showModal() {
        modal(MODAL_STORAGE);
    }
}
