
import { Component } from "vue-property-decorator";
import { MODAL_SIGNUP } from "@/Data";
import { RootModal, ModalSetup } from "@/components";

@Component
export default class SignupModalView extends RootModal {
    override setup(): ModalSetup {
        return {
            name: MODAL_SIGNUP
        };
    }
}
