
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component
export default class ShadowButton extends RootView {
    goToShadowPage() {
        window.open(`${process.env.VUE_APP_SHADOW_URL}/thing/${this.selectedDevices[0].device_id}/namedShadow/Classic%20Shadow`, "_blank");
    }
}
