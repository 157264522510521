
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

import AppLink from "@/components/AppLink.vue";

@Component({
    components: {
        AppLink
    }
})
export default class Sidebar extends RootView {
    extended = false;

    get iconClass() {
        if (this.extended) return "fa-solid fa-angles-left";
        return "fa-solid fa-angles-right";
    }

    get sideBarStyle() {
        if (this.extended) {
            return "width: 156px !important";
        }
        return "width: 64px;"
    }

    onSidebarExtend() {
        this.extended = !this.extended;
    }

    onMobileAppBtnClick() {
        window.open(process.env.VUE_APP_MOBILE_APP_DOWNLOAD_URL, "_blank");
    }
}
