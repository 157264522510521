import { Component } from "vue-property-decorator";

import { RootView } from "@/components";
import { Command, RoleOption } from "@/Utils";
import { modal, toast, ToastType } from "@/Toast";
import { MODAL_USER_INVITATION_ACCEPT, MODAL_USER_INVITATION_REFUSE } from "@/Data";

@Component
export default class BaseUserInvitationsView extends RootView {

    commands: Command[] = [
        {id: "accept", name: this.$t("command_accept").toString()},
        {id: "refuse", name: this.$t("command_refuse").toString()}
    ]

    onCommandClicked(command: Command) {
        const selected = this.$store.state.user_invitations.index.selected;
        if (selected.length <= 0) {
            toast({
                message: this.$t("toast_missing_selection", ["invitation"]).toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }

        switch(command.id) {
            case "accept":
                modal(MODAL_USER_INVITATION_ACCEPT, selected);
                break;
            case "refuse":
            default:
                modal(MODAL_USER_INVITATION_REFUSE, selected);
                break;
        }
    }

    onRoleChange(role: RoleOption | undefined) {
        this.$store.commit("user_invitations/setFilter", {filter: "role", value: role?.id ?? ""});
        this.onSearch();
    }

    onRoleClear() {
        this.$store.commit("user_invitations/setFilter", {filter: "role", value: ""});
        this.onSearch();
    }

    onSearch() {
        this.$store.commit("user_invitations/search");
    }
}