
import { Component } from "vue-property-decorator";
import { MODAL_USER_INVITATION_FAILED } from "@/Data";
import { RootModal, ModalSetup, ModalType } from "@/components";

@Component
export default class UserInvitationFailedModalView extends RootModal {
    
  override setup(): ModalSetup {
    return {
      name: MODAL_USER_INVITATION_FAILED,
      type: ModalType.VIEW_ONLY
    }
  }

  override onClose() {
    this.$store.commit("invite/clear");
  }
}
