
import { Component } from "vue-property-decorator";

import { MODAL_LOGOUT } from "@/Data";
import { RootModal, ModalSetup, ModalType } from "@/components";

@Component
export default class LogoutModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_LOGOUT,
            type: ModalType.VIEW_ONLY
        }
    }   

    logout() {
        this.$store.dispatch("auth/logout").then(() => {
            this.$store.commit("user/clear");
            this._modal.hide();
            this.goto({ path: '/login'}, true);
        });
    }
}
