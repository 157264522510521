
import { Component, Vue, Watch } from 'vue-property-decorator';

import PageTitle from "@/components/PageTitle.vue";

@Component({
    components: {
        PageTitle
    }
})
export default class FAQPageView extends Vue {

}
