
import { QUERY_PARAM_SORT, SORT_ASC, SORT_DESC } from "@/Data";
import { Component, Prop} from "vue-property-decorator";
import RootView from "./RootView";

@Component
export default class FilterSort extends RootView {
    @Prop({default: SORT_DESC}) value!: string;

    get isDirectionLatest(): boolean {
        return this.value == SORT_DESC;
    }

    reverseDirection(): string {
        if (this.value == SORT_DESC) return SORT_ASC;
        return SORT_DESC;
    }

    onIconClicked() {
        let reverse = this.reverseDirection();
        this.$emit("input", reverse);
        this.$emit("direction", reverse);
        this.setQuery(QUERY_PARAM_SORT, reverse);
    }
}
