
import {Vue, Component, Prop} from "vue-property-decorator"
import { onClickOutside } from '@vueuse/core'
import RootView from "../RootView";

@Component
export default class SearchbarField extends RootView {
    @Prop({default: ""}) name!: string; 
    @Prop({default: ""}) value?: any;
    @Prop({default: ""}) query!: string;
    @Prop({default: ""}) search!: string;

    $refs!: {
        field: HTMLElement
    }

    content = this.value;
    selected = false;

    mounted() {
        onClickOutside(this.$refs.field, this.onClickOutside);
    }

    onClick() {
        this.selected = true;
    }

    onSearchPressedEnter(event: any) {
        if (event.key == 'Enter') {
            this.beforeSearch();
        }
    }

    onSearchButtonSubmit() {
        this.beforeSearch();
    }

    updateValue(event: any) {
        this.$emit('input', event)
    }

    onClickOutside() {
        if (this.selected) {
            this.selected = false;
            this.beforeSearch();
        }
    }

    beforeSearch() {
        if (this.query.length > 0) {
            if (this.value.length > 0) {
                this.setQuery(this.query, this.value);
            } else {
                this.removeFromQuery(this.query);
            }
        }
        if (this.search) {
            this.$store.commit(this.search);
        }
        this.$emit('search');
    }
}

