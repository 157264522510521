
import { Vue, Prop, Component } from "vue-property-decorator";

@Component({
    components: {}
})
export default class PageTitle extends Vue {
    @Prop({default: ""}) title!: string;
    @Prop({default: "fw-bolder"}) titleClass!: string;
    @Prop({default: ""}) titleStyle!: string;

    @Prop({default: ""}) subtitle!: string;
    @Prop({default: ""}) subtitleClass!: string;
    @Prop({default: ""}) subtitleStyle!: string;
}
