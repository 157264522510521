
import { Vue, Prop, Component } from "vue-property-decorator";

import { TabLayout } from "@/components/layouts";
import { TabButton, BackButton } from "@/components/buttons";
import { SettingsDropdown } from "@/components/dropdowns";

import BaseOrganisationView from "./BaseOrganisationView";
import MobileOrganisationInfoView from "./MobileOrganisationInfoView.vue";
import MobileOrganisationUsersView from "./MobileOrganisationUsersView.vue";

@Component({
    components: {
        BackButton,
        SettingsDropdown, 
        TabLayout,
        TabButton,
        MobileOrganisationInfoView,
        MobileOrganisationUsersView
    }
})
export default class MobileOrganisationPageView extends BaseOrganisationView {}
