
import { Component, Vue } from 'vue-property-decorator';

import { AuthRequest, AuthRequestStatus } from "@/Data";
import RootView from '@/components/RootView';

import { LoginLayout } from '@/components/layouts';
import { PasswordField } from '@/components/fields';
import { InfoBlock } from '@/components/blocks';
import { LocaleMessage } from 'vue-i18n';

@Component({
    components: {
        LoginLayout,
        InfoBlock,
        PasswordField
    },
})
export default class LoginView extends RootView {

    STATE_LOGIN = "login";
    STATE_NEW_PW = "new_pw";
    STATE_FORGOT = "forgot";
    STATE_BACK = "back";

    loading = false;

    email = "";
    password = "";
    state = this.STATE_LOGIN;
    newPassword = "";
    newPasswordConfirm = "";
    forgotPasswordEmail = "";
    renderComponent = true;
    rememberMe = false;
    oldRememberMe = "";
    invite = false;

    errors: {
        signin: LocaleMessage[],
        forgotPassword: LocaleMessage[]
    } = {
        signin: [],
        forgotPassword: []
    }

    mounted() {
        this.defaultState();

        const rememberedEmail = localStorage.getItem("EMFCONNECT_LOGIN_REMEMBER_ME");
        if (rememberedEmail) {
            this.oldRememberMe = rememberedEmail;
            this.email = rememberedEmail;
            this.rememberMe = true;
        }

        this.invite = this.$store.state.invite.hasInvitation;
        if (this.invite) {
            this.email = this.$store.state.invite.email;
        }
    }

    async submitLogin() {

        this.$nextTick(() => {
            this.loading = true;
        })

        if (this.rememberMe && this.oldRememberMe != this.email) {
            localStorage.setItem("EMFCONNECT_LOGIN_REMEMBER_ME", this.email);
        }

        if (this.oldRememberMe.trim().length > 0 && !this.rememberMe) {
            localStorage.removeItem("EMFCONNECT_LOGIN_REMEMBER_ME");
        }

        let response: AuthRequest = await this.$store.dispatch("auth/login", {
            username: this.email,
            password: this.password
        })

        this.$nextTick(() => {
            this.loading = false;
        })

        switch(response.status) {
            case AuthRequestStatus.FAILURE:
                this.errors.signin = [this.$t("login_error")];
            break;
            case AuthRequestStatus.NEW_PASSWORD:
            break;
            case AuthRequestStatus.SUCCESS:
            default:
                this.goto({ path: '/map'});
            break;
        }
    }

    cancelForgotPassword() {
        this.state = this.STATE_LOGIN;
        this.errors.signin = [];
    }

    forgotPassword() {
        this.state = this.STATE_FORGOT;
        this.forgotPasswordEmail = "";
        this.errors.forgotPassword = [];
    }

    confirmEmailForgotPassword() {
        if (this.forgotPasswordEmail.length <= 0) {
            this.$nextTick(() => {
                this.errors.forgotPassword = [this.$t("field_rule_empty")];
            })
            return
        }

        this.$nextTick(() => {
            this.loading = true;
        })

        this.$store.dispatch("auth/forgotPassword", {
            username: this.forgotPasswordEmail
        }).then(() => {
            this.state = this.STATE_BACK;
        }).catch(() => {
            this.errors.forgotPassword = [this.$t("forgot_password_email_error")];
        }).finally(() => {
            this.$nextTick(() => {
                this.loading = false;
            })
        })
    }

    defaultState() {
        this.email = "";
        this.password = "";
        this.state = this.STATE_LOGIN;
        this.newPassword = "";
        this.newPasswordConfirm = "";
        this.renderComponent = true;
        this.errors = {
            signin: [],
            forgotPassword: []
        };
    }

}
