
import RootView from "@/components/RootView";
import { Prop, Component } from "vue-property-decorator";

@Component
export default class CardLayout extends RootView {
    @Prop({default: ""}) title!: string;
    @Prop({default: false}) condition!: boolean;
    @Prop({default: false}) search!: boolean;
    @Prop({default: false}) actions!: boolean;
}
