
import { Component, Prop } from "vue-property-decorator";
import RootView from "../RootView";

@Component
export default class OrganisationLinkBlock extends RootView {
    @Prop({default: false}) icon!: boolean;
    @Prop({default: ""}) org_id!: string;

    get orgLink() {
        return `/organisation/${this.org_id}`;
    }
}
