import OrganisationModel from "@/models/OrganisationModel";
import UserOrganisationModel from "@/models/UserOrganisationModel";

import { RootView } from "@/components";
import { MODAL_ORGANISATION_STORAGE, MODAL_ORGANISATION_TREATMENT } from "@/Data";
import { modal, toast, ToastType } from "@/Toast";
import { Command } from "@/Utils";
import Component from "vue-class-component";

@Component
export default class BaseDashboardPageView extends RootView {
    commands: Command[] = [
        {id: "storage", name: this.$t("options_cmd_storage_mode").toString()},
    ]

    toOrg(models: UserOrganisationModel[]): OrganisationModel[] {
        const results: OrganisationModel[] = [];
        for (let i = 0; i < models.length; i++) {
            results.push(models[i].org!);
        }
        return results;
    }

    onCheckboxChanged() { this.$store.commit("organisation_devices/setSearchedChecked") }
    onDeviceFilterByUpdate(direction: string) { this.$store.commit("organisation_devices/sortByUpdatedTime", {direction: direction}); }

    onCommandClicked(command: Command) {
        if (this.selectedDevices.length <= 0) {
            toast({
                message: this.$t("toast_missing_selection", ["device"]).toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }

        switch(command.id) {
            case "storage":
                modal(MODAL_ORGANISATION_STORAGE);
                break;
            case "treatment":
            default:
                modal(MODAL_ORGANISATION_TREATMENT);
                break;
        }
    }
}