import { VuexModule, Module, Mutation, Action} from "vuex-class-modules";

import { network } from "@/network";
import { modules } from "@/store";
import { genUUID, jsonParse } from "@/Utils";
import JobModel from "@/models/JobModel";

interface AppSettings {
    daily_email_list: string[]
}

@Module
export default class AdminModule extends VuexModule {

    appSettings: AppSettings = { daily_email_list: [] };
    leftOrgHash = genUUID();

    @Mutation 
    changeOrgStatus() {
        this.leftOrgHash = genUUID();
    }

    @Mutation
    setAppSettings(appSettings: AppSettings) {
        this.appSettings = appSettings;
    }

    @Mutation
    setAppSettingsDailyEmailList(emails: string[]) {
        this.appSettings.daily_email_list = emails;
    }

    @Action
    async joinOrg(organisation_id: string) {
        await network().post(`/op/admin/organisations/${organisation_id}/self`);
        const usr_id = modules.auth.user!.id!;
        if (!modules.user.user) {
            await modules.user.fetch();
        }
        modules.organisationUsers.add({   
            usr_id: usr_id, 
            org_id: organisation_id, 
            role: "superadmin", 
            info: modules.user.user!
        });
        await modules.auth.updateToken();
        this.changeOrgStatus();
        return true;
    }

    @Action
    async leaveOrg(organisation_id: string) {
        await network().delete(`/op/admin/organisations/${organisation_id}/self`);
        const usr_id = modules.auth.user!.id!;
        modules.organisationUsers.removeUser(usr_id);
        await modules.auth.updateToken();
        this.changeOrgStatus();
        return true; 
    }

    @Action
    cancelJob(payload: JobModel) {
        return network().put(`/op/admin/devices/${payload.device_id}/jobs/${payload.iot_ref}`);
    }

    @Action
    generateLicense(payload: {name: string, date: string}): Promise<string> {
        const name = payload.name;
        const date = new Date(payload.date).getTime();
        return network().post("op/admin/licenses/gen", 
            JSON.stringify({name: name, date: date})).then((data) => {
            return jsonParse(data.data).result;
        });
    }

    @Action
    fetchAppSettings(): Promise<any> {
        return network().get("/op/admin/settings").then((data) => {
            return jsonParse(data.data);
        }).then((settings: AppSettings) => {
            this.setAppSettings(settings);
            return true;
        })
    }

    @Action
    updateDailyEmailList(emails: string[]): Promise<any> {
        return network().put("/op/admin/settings/daily", JSON.stringify({list: emails})).then(() => {
            this.setAppSettingsDailyEmailList(emails);
        });
    }


}