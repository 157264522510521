
import {Component, Prop} from "vue-property-decorator";
import RootView from "../RootView";

@Component
export default class CheckboxField extends RootView {
    @Prop({default: false}) value?: boolean;
    @Prop({default: ""}) search!: string;
    @Prop({default: ""}) query!: string;
    
    get checked(): boolean {
        if (this.value) {
            return this.value!;
        }
        return false;
    }

    set checked(value: any) {
        this.$emit('input', !this.value);
        this.$emit('change', !this.value);

        if (this.query.length > 0) {
            if (value) {
                this.setQuery(this.query, "true");
            } else {
                this.removeFromQuery(this.query);
            }
        }
        if (this.search) {
            this.$store.commit(this.search);
        }
    }
}

