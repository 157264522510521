
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import EditbarField from "./EditbarField.vue";

@Component({
    components: {
        EditbarField
    }
})
export default class EditableField extends Vue {
    @Prop({default: ""}) value!: string;
    @Prop({default: ""}) tooltip!: string;
    @Prop({default: ""}) textStyle!: string;
    @Prop({default: ""}) textClass!: string;

    editing = false;
    
    mounted() {
        this.editing = false;
    }

    val = "";

    get isValid(): boolean {
        return this.value.trim().length > 0;
    }

    stop() {
        this.editing = false;
    }

    onEditClicked() {
        this.val = this.value.slice();
        this.editing = true;
    }

    onDeny() {
        this.editing = false;
    }

    onSubmit() {
        this.editing = false;
        this.$emit("input", this.val);
    }
    
}
