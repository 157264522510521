import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import UserInvitationModel from "@/models/UserInvitationModel";
import searchInvitations, { UserInvitationSearchFilters } from "@/search/UserInvitationSearch";
import { jsonParse, SearchIndex } from "@/Utils";
import { network } from "@/network";
import { AxiosResponse } from "axios";
import { INVITATION_RESPONSE } from "@/Data";

@Module
export default class UserInvitationModule extends VuexModule {

    index: SearchIndex<UserInvitationModel, UserInvitationSearchFilters> = new SearchIndex("org_id", {searchRoot: searchInvitations});
    checkboxAll = false;
    filters: {
        name: string,
        role: any
    } = {
        name: "",
        role: ""
    }

    @Mutation
    setCheckboxAll() { this.index.checkAll(this.checkboxAll); }

    @Mutation
    setFilter(payload: {filter: string, value: any}) {
        (this.filters as any)[payload.filter] = payload.value;
    }
    
    @Mutation
    clear() {
        this.index.clear();
        this.checkboxAll = false;
        this.filters = {
            name: "",
            role: ""
        };
    }

    @Mutation
    search() {
        const role = (this.filters.role ?? "").toLowerCase().trim();
        this.index.search({
            name: this.filters.name,
            role: (role == "all") ? "" : role
        });
    }
    
    @Mutation
    setModels(userInvitations: UserInvitationModel[]) {
        this.index.map(userInvitations);
    }

    @Action
    async fetch() {
        const result = jsonParse((await network().get("/op/user/invitations")).data);
        this.setModels(result);
    }

    @Mutation
    remove(selected: string[]) {
        this.index.remove(...selected);
    }

    @Action
    confirm(payload: {task: INVITATION_RESPONSE, selected: UserInvitationModel[]}) {
        const selected = payload.selected.map((value: UserInvitationModel) => {
            return value.org_id;
        });

        return network().put("/op/user/invitations", JSON.stringify({
            "action": payload.task,
            "invites": selected
        })).then((value: AxiosResponse) => {
            if( value.status != 200) return;
            this.remove(selected);
        });
    }

}