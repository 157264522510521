import MapPageView from '@/views/pages/map/index.vue';
import ProfilePageView from '@/views/pages/profile/index.vue';
import OrganisationPageView from '@/views/pages/organisation/org_id/index.vue';
import FAQPageView from '@/views/pages/faq/index.vue';
import LoginPageView from '@/views/pages/login/index.vue';
import DevicePageView from "@/views/pages/organisation/org_id/devices/dev_id/index.vue";
import MobileDevicePageView from "@/views/pages/organisation/org_id/devices/dev_id/mobile.index.vue";
import PageNotFoundView from "@/views/pages/404/index.vue";
import MobilePageNotFoundView from "@/views/pages/404/mobile.index.vue";
import AdminPageView from "@/views/pages/admin/devices/index.vue";
import MobileAdminPageView from '@/views/pages/admin/devices/mobile.index.vue';
import InvitationPageView from '@/views/pages/invite/token/index.vue';
import ResetPasswordPageView from '@/views/pages/reset/token/index.vue';
import DashboardPageView from '@/views/pages/dashboard/index.vue';
import AdminOrganisationsPageView from '@/views/pages/admin/organisations/index.vue';
import AdminFirmwaresPageView from '@/views/pages/admin/firmwares/index.vue';
import MobileDashboardPageView from '@/views/pages/dashboard/mobile.index.vue';
import MobileProfilePageView from './profile/mobile.index.vue';
import MobileOrganisationPageView from './organisation/org_id/mobile.index.vue';

export default {
    MapPageView, ProfilePageView, OrganisationPageView, FAQPageView,
    LoginPageView, DevicePageView, MobileDevicePageView, PageNotFoundView,
    MobilePageNotFoundView, AdminPageView, MobileAdminPageView, InvitationPageView,
    ResetPasswordPageView, DashboardPageView, AdminOrganisationsPageView: AdminOrganisationsPageView, AdminFirmwaresPageView,
    MobileDashboardPageView, MobileProfilePageView, MobileOrganisationPageView
}