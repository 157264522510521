
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class ActionLoadingOverlay extends Vue {
    show = false;

    showFor(ms: number) {
        this.show = true;
        setTimeout(() => {
            this.show = false;
        }, ms);
    }

    forceHide() {
        this.show = false;
    }
}
