export default {
    "wait_message": 'Veuillez patientez..',
    'latitude': 'Latitude',
    'longitude': 'Longitude',
    "coordinates": "Coordonées",
    'unavailable': 'Indisponible',
    'no_tags_found': 'Aucun tags',
    'not_available': "N/A",    
    'activate': 'Activé',
    'deactivate': 'Desactivé',
    'all': 'Tous',
    '404': "Page not found.",
    'route_undefined': '',
    'route_dashboard': 'Tableau de bord',
    'route_organisations': 'Organisations',
    'route_profile': 'Profile',
    "route_device": "Appareil",
    'route_faq': 'Foire Aux Questions',
    'route_admin': 'Administration',
    'route_firmwares': 'Firmwares',
    "route_organisation": "Organisation",
    "route_invite": "Invitation",
    "route_licenses": "Licences Expirées",
    'role_title': "Role",
    'role_guest': 'Invité',
    'role_admin': 'Admin',
    'role_owner': 'Propriétaire',
    'role_member': 'Membre',
    'role_none': 'Aucun',
    'role_superadmin': 'SuperAdmin',
    'login_email': "Courriel",
    'options': 'Options',
    'options_section_manage': 'Gérer l\'appareil',
    'options_cmd_edit_label': 'Modifier le libellé',
    'options_cmd_activate_treatment': 'Activé le traitement',
    'options_cmd_deactivate_treatment': 'Desactivé le traitement',
    'options_cmd_storage_mode': 'Mode Stockage',
    'options_section_remote_commands': 'Remote Commands',
    'login_password': "Mot de passe",
    "my_organisations": "Mes Organisations",
    "my_informations": "Mes Informations",
    'login_password_confirm': 'Confirmation du mot de passe',
    'done': 'Opération completée avec succès.',
    'failure': 'Operation echouée.. Veuillez contacter un administrateur du site.',
    'passwords_do_not_match': 'Les mots de passes ne correspondent pas',
    'new_login_password': 'Nouveau mot de passe',
    'new_login_password_confirm': 'Confirmer votre nouveau mot de passe',
    'new_login_password_title': 'Nouveau mot de passe requis ',
    'new_login_password_description': 'La création d\'un nouveau mot de passe est requis.',
    'forgot_password_title': 'Mot de passe oublié?',
    'forgot_password_description': 'Veuillez nous fournir votre addresse courriel afin qu\'on puisse vous envoyez un courriel pour réinitialiser votre mot de passe.',
    'forgot_password_email_error': 'Courriel requis/inconnu',
    'forgot_password_email_sent': 'Courriel envoyé. Veuillez verifiez votre boite de courriel.',
    'reset_password_title': "Réinitalisez votre mot de passe",
    'reset_password_description': 'Veuillez complétez le formulaire suivant afin de réinitaliser votre mot de passe.',
    'password_changed_succesfully': 'Mot de passe réinitialisé avec succès.',
    'phone_rule_international': 'Le numero de telephone doit respecter les regles du standard international. Un signe positif en avant (+) suivi de nombre sans espaces.',
    'password_match': 'Les mots de passes doivent correspondre.',
    'password_rule_min_char': 'Le mot de passe nécessite 8 caractères min.',
    'password_rule_uppercase': 'Le mot de passe nécessite 1 majuscule min.',
    'password_rule_number': 'Le mot de passe nécessite 1 chiffre min.',
    'password_rule_special': 'Le mot de passe nécessite 1 caractère spécial min. ($&+,:;=?@#|\'<>.^*()%!-)',
    'password_rule_match': 'Les mots de passes doivent correspondre',
    'forgot_login_email': 'Entrez votre courriel',
    'login_remember': "Se souvenir de moi",
    'login_forgot': "Mot de passe oublié?",
    'login_signup': "S'inscrire",
    'login_signin': "Se connecter",
    'login_error': "Authentification invalide..",
    'logout': "Deconnexion",
    'logout_message': 'Etes-vous sur de vouloir vous compléter cette action?',
    'login_invitation_title': 'Invitation',
    'login_invitation_text': 'Connectez vous pour rejoindre {0}',
    'button_close': 'Fermer',
    'button_confirm': 'Confirmer',
    "update_src_iot": "LTE",
    "update_src_mobile": "Mobile",
    "update_src_admin": "Admin",
    'map_legend': "Légende",
    "map_legend_enabled": "Activé",
    "map_legend_disabled": "Desactivé",
    "map_buttons_summary": "Sommaire",
    "map_buttons_details": "Détails",
    "map_buttons_zoom": "Zoom",
    "map_buttons_search": "Rechercher",
    "map_search_label": "Libéllé",
    "map_search_sn": "Numéro de série",
    "none": "Aucune",
    "no_organisations_title": "Aucunes Organisations",
    "no_organisations_found_title": "Aucunes Organisations trouvées",
    "no_organisations_description": "Vous devez etre membre d'une organisation pour voir le tableau de bord.",
    "profile_usercode": "Votre code de partage",
    "no_organisations_steps": "Contacter vos organisations avec votre code de partage pour qu'ils puissent vous ajouter comme membre.",
    "no_devices": "Aucun appareils trouvés",
    "no_firmwares": "Aucun firmware trouvés",
    "no_users": "Aucun utilisateurs trouvés",
    "no_devices_msg": "Aucun appareils n'a été trouvés pour l'organisation : {name}.",
    "no_device_msg": "Aucun appareil trouvé associé avec l'id.",
    "organisation_command_invite": "Inviter",
    "organisation_command_edit": "Modifier",
    "organisation_command_remove": "Enlever",
    "details_title_map": "Carte",
    "details_title_actions": "Actions",
    "details_title_licenses": "Licences",
    "details_summary_name": "Nom",
    "details_summary_phone": "Téléphone",
    "details_summary_email": "Courriel",
    "details_summary_address": "Adresse",
    "details_summary_website": "Site web",
    "details_summary_temperature": "Température",
    "details_summary_transducerbattery": "Batterie (Transducer)",
    "details_summary_buoybattery": "Batterie (Bouée)",
    "details_summary_uptime": "Uptime",
    "details_summary_resetcount": "Nombre de reéinilisations",
    "details_summary_activatedtime": "Temps activé",
    "details_summary_enabled": "Activation",
    "details_summary_enabled_true": "Activé",
    "details_summary_enabled_false": "Désactivé",
    "details_summary_coordinates": "Coordonnées", 
    "details_summary_water": "Senseur Liquide",
    "details_summary_water_true": "Dans l'eau",
    "details_summary_water_false": "Hors de l'eau",
    "details_summary_wifi": "Réseau cellulaire",
    "details_summary_wifi_status_disconnected": "Déconnecté",
    "details_summary_wifi_status_poor": "Faible",
    "details_summary_wifi_status_good": "Fort",
    "details_summary_wifi_status_excellent": "Excellent",
    "details_summary_wifi_status_fair": "Juste",
    "details_actions_toggle": "Traitement",
    "details_actions_storage": "Stockage",
    "details_actions_storage_btn": "Envoyer",
    "details_actions_cancel_btn": "Annulez",
    "details_actions_back_btn": "Retour",
    "details_actions_unavailable_btn": "Indisponible",
    "details_actions_storage_remove_btn": "Rétablir",
    "details_summary_product": "Produit",
    "details_summary_firmware": "Firmware",
    "details_firmware_imei": "IMEI",
    "details_firmware_ICCID": "ICCID",
    "details_firmware_module": "Mod",
    "details_firmware_board": "Board",
    "details_firmware_app": "App",
    "details_title_summary": "Sommaire",
    "details_title_users": "Utilisateurs",
    "details_title_details": "Détails",
    "details_title_firmware": "Firmware",
    "details_title_roam": "Roam",
    "details_title_updates": "MaJ",
    "details_roam_band": "Band",
    "details_roam_nw": "NW",
    "details_roam_rsrp": "RSRP",
    "details_roam_area": "Area",
    "details_roam_mccmnc": "MCCMNC",
    "details_roam_cell": "Cell",
    "details_roam_ip": "IP",
    "no_label": "Aucun libéllé",
    "treatment": "Traitement",
    "serverconnection": "Cloud",
    "admin_only": "Admin",
    'settings': "Parametres",
    "page_organisations_info": "Gérer vos organisations..",
    "languages": "Langues",
    "page_organisations_table_id": "ID",
    "page_organisations_table_name": "Nom",
    "page_access_forbidden_title": "Accès Interdit",
    "page_access_forbidden_organisation_subtitle": "Il semblerait que vous ne faissiez pas part de l'organisation demandé.",
    "page_organisation_table_id": "ID",
    "page_organisation_table_name": "Nom",
    "page_organisation_table_role": "Role",
    "page_organisation_table_phone": "Téléphone",
    "page_organisation_table_email": "Courriel",
    "page_organisation_table_website": "Site web",
    "page_organisation_table_actions": "Actions",
    "page_organisation_search": "Rechercher",
    "page_organisation_invite": "Inviter",
    "page_organisation_user_edit": "Modifier",
    "page_organisation_user_delete": "Supprimer",
    "btn_delete": "Supprimer",
    "share_code": "Code de partage",
    "toast_share_code": "Le code de partage a été copié sur le presse-papiers.",
    "toast_user_edit": "L'utilisateur fut modifié avec succès.",
    "toast_user_invited": "L'utilisateur fut invité avec succès.",
    "toast_org_user_deleted": "L'utilisateur fut supprimé avec succès.",
    "toast_org_user_updated": "L'utilisateur fut modifié avec succès.",
    "toast_user_invited_fail": "Erreur : La tentative d'invitation a echoué.",
    "toast_admin_cloud_disconnected": "L'operation est présentement indisponible. L'appareil doit etre connecté sur le CLOUD.",
    "help": "Aide",
    "modal_sharehelp_description": "Le bouton de partage vous permet de rejoindre un organisation. Pour rejoindre un organisation vous devez procedez ainsi",
    "modal_sharehelp_stepone": "Cliquez sur le bouton de partage; le code sera transmis sur le presse-papier.",
    "modal_sharehelp_steptwo": "Envoyez le code aux administrateurs de votre organisation pour qu'ils puissent vous y ajouter.",
    "modal_adminupdatefirmware_title": "Mise-à-Jour Firmware",
    "modal_adminupdatefirmware_desc": "Mettre à jour le firmware d'appareil(s).",
    "modal_adminassignorg_title": "Assigner à une organisation",
    "modal_adminassignorg_desc": "Assigner/deassigner un/des appareil(s) à une/d'une organisation.",
    "modal_admintags_title": "Libbelé(s)",
    "modal_admintags_desc": "Ajouter/supprimer un/des libbélé(s) d'un/des appareil(s)",
    "modal_admintags_switch": "Supprimer/Ajoute",
    "modal_admindisable_title": "Désamorcer un/des appareil(s)",
    "modal_admindisable_desc": "Désamorcer un/des appareil(s) et, supprimer leur liens envers une organisation.",
    "modal_adminempty_title": "Créer un/des appareils(s)",
    "modal_adminempty_desc": "Créer des nouveaux appareils avant que leurs données soit enregistrer.",
    "modal_treatment_title": "Basculer le traitement",
    "modal_treatment_text": "Voulez vous activé/desactivé votre appareil?",
    "modal_signup_title": "Inscription",
    "modal_signup_text": "Communiquez avec votre représentant pour créer un compte.",
    "modal_editorg_title": "Modifier l'Organisation",
    "modal_editorg_desc": "Modifier les informations reliées a l'appareil",
    "page_profile_table_name": "Nom",
    "page_profile_button_edit": "Modifier votre profile",
    "modal_invite_title": "Invitez",
    "modal_invite_description": "Invitez un utilisateur a rejoindre votre organisation en insérant un courriel.",
    "modal_invitation_title": "Invitation",
    "modal_invitation_description": "Vous avez rejoint l'organisation <strong> {0} </strong> comme <strong> {1} </strong> avec succès.",
    "modal_invitation_failed_title": "Invitation echoué",
    "modal_invitation_failed_description": "Votre invitation est expirée/invalide.",
    'modal_editdevice_title': 'Modifier',
    'modal_editdevice_desc': 'Modifier certaines informations sur cet appareil',
    "toast_org_missing_selection_command": "Veuillez selectionnez un ou plusieurs utilisateurs avant de faire une action",
    "toast_org_user_update_fail": "La tentative de mise-a-jour a echoué.",
    "toast_already_exists": "L\\les appareil(s) existe\\nt deja.",
    "toast_org_user_delete_fail": "La tentative de supression a echoué.",
    "modal_editorguser_title": "Mise-a-jour",
    "modal_editorguser_desc": "Mettre a jour un/plusieurs utilisateur(s) dans votre organisation.",
    "modal_kickorganisationuser_title": "Kick",
    "modal_kickorganisationuser_desc": "Kicker un/des utilisateur(s) de votre organisation.",
    "dashboard_table_sn": "#NS",
    "dashboard_table_organisation": "Organisation",
    "dashboard_table_update": "Derniere MAJ",
    "dashboard_table_license": "Licence",
    "dashboard_table_label": "Libéllé",
    "dashboard_table_tags": "Tags",
    "dashboard_table_transducer": "Transducteur",
    "dashboard_table_status": "Status",
    "dashboard_table_cloudconnection": "Cloud",
    'device_status_disconnected': 'Transducer deconnecté',
    "device_status_license_expired": "Expirée",
    "device_status_license_not_found": "Introuvable",
    "device_status_license_request_pending": "Nouvelle license",
    "dashboard_table_firmware": "Firmware",
    "dashboard_table_ignored": "Est ignorée?",
    "device_status_enabled": "Traitement EN LIGNE",
    "device_status_offline": "Traitement HORS SERVICE",
    "device_status_unknown": "Inconnu",
    "device_status_transition_on": "ACTIVATION..",
    "device_status_transition_off": "DESACTIVATION..",
    "device_status_on": "ON",
    "device_status_off": "OFF",
    "device_status_storage": "EN STOCKAGE",
    "device_status_pairing": "PAIRING",
    "device_status_disconnected_sm": "DÉCONNECTÉ",
    "device_status_idle": "Absent",
    "modal_storage_title": "Mode Stockage",
    "modal_storage_text": "Envoyer votre unité en Mode Stockage. L'unité va arreter de collecter des données. Etes-vous sure ? ",
    "modal_storage_text_return": "Retirer votre unité du Mode Stockage.",
    "modal_storage_remove_title": "Mode Stockage (Rétablir)",
    "modal_storage_remove_text": "Rétablir votre appareil du mode stockage. Vous devriez tout de meme re-activé l'appareil si nécessaire. Etes-vous sure ?",
    "modal_cancel_title": "Annulez l'opération",
    "modal_cancel_subtitle": "Voulez vous annuler l'opération en cours ?",
    "modal_cancel_text": " Si votre appareil est actif, il sera désactivé, et de meme pour l'opposé, etc.",
    "modal_changepw_title": "Modification du mot de passe",
    "modal_changepw_desc": "Entrez votre ancien mot de passe et un nouveau mot de passe afin de changer votre mot de passe.",
    "modal_editprofile_title": "Modification du profile",
    "modal_editprofile_desc": "Modifiez les champs affiliés a votre profile si nécessaire",
    "admin_command_title": "Commandes",
    "admin_command_firmware": "Mise-a-jour firmware",
    "admin_command_license": "Mettre a jour la licence",
    "admin_command_assignment": "Ajouter des appareils",
    "admin_command_coordinates": "Mettre a jour la position désirée",
    "admin_command_tags": "Mettre des tags",
    "admin_command_empty": "Créer un/des appareil(s)",
    "admin_command_sync": "Synchronizer",
    "admin_command_disable": "Désamorcer",
    "admin_command_shadow": "Shadow",
    "admin_command_serialnumber": "Assigner des numéros de series",
    "profile_command_changepassword": "Modification du mot de passe",
    "profile_command_editprofile": "Modifier le profile",
    "admin_organisations_all": "Organisations (toutes)",
    "firmware_version": "Version",
    "firmware_name": "Nom",
    "firmware_release": "Date de parution",
    "firmware_description": "Description",
    "firmware_id": "ID",
    'modal_adminstorage_title': 'Mode stockage',
    'modal_adminstorage_desc': 'Envoyer/Renvoyer un/des appareil(s) vers/du mode stockage.',
    "modal_admincreatefirmware_title": "Mise-a-jour Firmware",
    "modal_admincreatefirmware_desc": "Creer une nouvelle mise-a-jour du firmware.",
    "modal_admineditfirmware_title": "Modifier une mise-a-jour",
    "modal_admineditfirmware_desc" : "Modifier les details d'une mise-a-jour",
    "modal_admincreateorg_title": "Creer une organisation",
    "modal_admincreateorg_desc": "Creer une organisation en remplissant les champs.",
    "modal_admincoordinates_title": "Mise-a-jour position",
    "modal_admincoordinates_desc": "Mettre a jour la position",
    "modal_adminupdatelicense_title": "Mettre a jour la licence",
    "modal_adminupdatelicense_error": "Erreur: La date selectionnée doit etre supérieur a celle d'aujourd'hui.",
    "firmware_command_create": "Creer une mise-a-jour",
    "organisation_command_create": "Creer une organisation",
    "firmware_command_edit": "Modifier une mise-a-jour",
    "license": "Licence",
    "last_update": "Derniere MAJ",
    "license_valid_text": "Valide pour {0}",
    "license_expire_text": "Expire {0}",
    "year": "année(s)",
    "month": "mois",
    "week": "semaine(s)",
    "day": "jour(s)",
    "hour": "heure(s)",
    "minute": "minute(s)",
    "ago": "il y a {0}",
    "until": "Jusqu'a",
    "since": "Depuis",
    "button_view_device": "VOIR",
    "details_summary_status": "Status",
    "no_license_requests_found": "Aucunes requetes de MAJ de licenses trouvées",
    "no_jobs_found": "Aucunes Job de MAJ Firmware trouvées",
    "no_events_found": "Aucuns événements trouvés",
    "no_files_found": "Aucuns fichiers trouvés",
    "license_request_type": "Type",
    "license_request_expirydate": "Date d'expiration",
    "license_request_creationdate": "Date de creation",
    "license_request_latest": "Plus Recente",
    "license_request_expired": "Expirée",
    "license_request_installed": "Installée",
    "license_request_wip": "MAJ en cour",
    "license_request_skipped": "Passée",
    "license_request_current": "Présentement sur l'appareil",
    "timestamp_type": "Category",
    "timestamp_db": "Timestamp (DB)",
    "timestamp_shadow": "Timestamp (Shadow)",
    "update_type_unknown": "Inconnu",
    "update_type_mobile": "Mobile",
    "update_type_iot": "LTE",
    "update_type_admin": "Admin",
    "update_type_web": "Web",
    "device_status_server_connected": "Connecté",
    "device_status_server_disconnected": "Déconnecté",
    "serialnumber": "Numéro de serie",
    "admin_op_success_create": "Created successfully the following devices.",
    "admin_op_success_license": "Made new licenses for the following devices.",
    "admin_op_success_firmware": "Updated the firmware for the following devices.",
    "admin_op_success_desiredcoords": "Assigned a new set of coordinates for the devices",
    "admin_op_success_assignment": "Assigned successfully the device to the organisation.",
    "admin_op_success_tags": "Set the tags for the following devices.",
    "admin_op_success_sync": "Synced the following devices.",
    "job_status_in_progress": "En-cours",
    "job_status_queued": "En préparation",
    "job_status_rejected": "Rejeté",
    "job_status_removed": "Enlevé",
    "job_status_timed_out": "Temps Mort",
    "job_status_failed": "Echec",
    "job_status_canceled": "Annulé",
    "job_status_succeeded": "Succès",
    "job_status_completed": "Completé",
    "job_status_deleted": "Supprimé",
    "job_status_cancellation_in_progress": "Annulation en-cours",
    "job_status_deletion_in_progress": "Suppression en-cours",
    "cancel_job": "Annuler l'operation",
    "cancel_job_message": "Annuler l'operation. L'appareil va arreter de faire sa MAJ Firmware.",
    "treatment_on": "Traitement ACTIF",
    "treatment_off": "Traitement INACTIF",
    "treatment_in_transition": "Traitement EN TRANSITION",
    "btn_license_create": "Générer une nouvelle licence",
    "type": "Type",
    "value": "Valeur",
    "result": "Résultat",
    "modal_admingenlic_title": "Générer une nouvelle licence",
    "modal_admingenlic_desc": "Veuillez remplir les champs ci-dessous pour générer une licence ",
    "modal_admingenlic_result_desc": "La licence vis-a-vis des champs ci-dessous.",
    "text_expired_license_count": "{0} licence(s) expirés",
    "btn_download_app": "Télécharger l'application",
    "dropfile_here": "Téléverser le fichier ici"
};