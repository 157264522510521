import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import { modules } from "@/store";
import { network } from "@/network";
import { AxiosResponse } from "axios";

import DeviceModel, { deviceLocalCheck } from "@/models/DeviceModel";
import OrganisationModel from "@/models/OrganisationModel";
import searchDevices, { AdminDeviceSearchFilters } from "@/search/AdminDeviceSearch";
import { deviceSortFunction, hasProperty, jsonParse, SearchIndex, toArray } from "@/Utils";

import { 
    DAILY_TAG,
    QUERY_PARAM_DAILY, QUERY_PARAM_LABEL, 
    QUERY_PARAM_LAST_UPDATE_TYPE, QUERY_PARAM_LICENSE_EXPIRED, QUERY_PARAM_ORG, QUERY_PARAM_SORT, QUERY_PARAM_TAGS, QUERY_PARAM_TRANSDUCER_DISCONNECTED, 
    QUERY_PARAM_TREATMENT, SORT_DESC 
} from "@/Data";
import { DeviceSelected, DeviceUpdateContent } from "@/models/DeviceSelection";
import FirmwareModel from "@/models/FirmwareModel";
import { Territory } from "@/plugins/countries";

interface DeviceResponse {
    device_id: string,
    success: boolean,
    error?: string 
}

const trimOutDeviceSelected = (selected: DeviceSelected[]): {org_id: string, device_id: string}[] => {
    const results: {org_id: string, device_id: string}[] = [];
    for (let i = 0; i < selected.length; i++) {
        results.push({
            org_id: selected[i].org_id,
            device_id: selected[i].device_id,
        });
    }
    return results;
}

const requestDeviceUpdate = (index: SearchIndex<DeviceModel, AdminDeviceSearchFilters>, payload: {options: any, action: string, selected: DeviceSelected[], handle: (device: DeviceModel) => void}): Promise<any> => {
    return network().put("/op/admin/devices", JSON.stringify({
        options: payload.options,
        action: payload.action,
        selected: trimOutDeviceSelected(payload.selected)
    })).then((response: AxiosResponse) => {
        const data: DeviceResponse[] = jsonParse(response.data).results;

        for (let i = 0; i < data.length; i++) {
            if (!data[i].success) throw new Error("Failed");
        }
        
        const devices: DeviceSelected[] = payload.selected;

        for (let i = 0; i < devices.length; i++) {
            payload.handle(index.get(devices[i].device_id)!)
        }

        return true;
    })
}

@Module
export default class AdminDevicesModule extends VuexModule {

    index: SearchIndex<DeviceModel, AdminDeviceSearchFilters> = new SearchIndex("device_id", { searchRoot: searchDevices});
    checkboxAll = false;

    filters: {
            licenseExpiredOnly: boolean,
            transducerDisconnectedOnly: boolean,
            dailyOnly: boolean,
            lastUpdateType: any,
            treatmentType: any,
            label: string,
            tags: string[],
            organisation: OrganisationModel | undefined,
            sort: string,
            country: Territory
        } = {
            licenseExpiredOnly: false,
            transducerDisconnectedOnly: false,
            dailyOnly: false,
            lastUpdateType: undefined,
            treatmentType: undefined,
            label: "",
            tags: [],
            organisation: undefined,
            sort: SORT_DESC,
            country: {id: "", tag: ""}
    }

    @Mutation
    setFilter(payload: {filter: string, value: any}) {
        (this.filters as any)[payload.filter] = payload.value;
    }
    
    @Mutation
    search() {
        this.index.search({
            label: this.filters.label,
            tags: this.filters.tags,
            orgs: this.filters.organisation?.org_id ?? "ALL",
            transducer_disconnected: this.filters.transducerDisconnectedOnly,
            daily: this.filters.dailyOnly,
            license_expired: this.filters.licenseExpiredOnly,
            last_update_type: this.filters.lastUpdateType?.value ?? "ALL",
            treatment_type: this.filters.treatmentType?.value ?? "ALL",
            country: this.filters.country.id
        });
        this.index.searched.sort(deviceSortFunction(this.filters.sort));
    }

    @Mutation 
    setQuery(baseQuery: any) {
        const query = jsonParse(JSON.stringify(baseQuery));

        if (hasProperty(query, QUERY_PARAM_LABEL)) {
            this.filters.label = query[QUERY_PARAM_LABEL].trim();
        }

        if (hasProperty(query, QUERY_PARAM_LAST_UPDATE_TYPE)) {
            this.filters.lastUpdateType = query[QUERY_PARAM_LAST_UPDATE_TYPE].trim();
        }

        if (hasProperty(query, QUERY_PARAM_TREATMENT)) {
            this.filters.treatmentType = query[QUERY_PARAM_TREATMENT].trim();
        }

        if (hasProperty(query, QUERY_PARAM_TRANSDUCER_DISCONNECTED)) {
            this.filters.transducerDisconnectedOnly = query[QUERY_PARAM_TRANSDUCER_DISCONNECTED];
        }

        if (hasProperty(query, QUERY_PARAM_DAILY)) {
            this.filters.dailyOnly = query[QUERY_PARAM_DAILY];
        }

        if (hasProperty(query, QUERY_PARAM_LICENSE_EXPIRED)) {
            this.filters.licenseExpiredOnly = query[QUERY_PARAM_LICENSE_EXPIRED];
        }
        
        if (hasProperty(query, QUERY_PARAM_TAGS)) {
            this.filters.tags = query[QUERY_PARAM_TAGS].split(",");
        }

        if (hasProperty(query, QUERY_PARAM_SORT)) {
            this.filters.sort = query[QUERY_PARAM_SORT].trim();
        }

        if (hasProperty(query, QUERY_PARAM_ORG)) {
            this.filters.organisation = modules.adminOrganisations.index.models!.find((value) => {
                return value.org_id == query[QUERY_PARAM_ORG]
            });
        }
    }

    @Mutation
    clear() {
        this.index.clear();
        this.filters = {
            licenseExpiredOnly: false,
            transducerDisconnectedOnly: false,
            dailyOnly: false,
            lastUpdateType: undefined,
            treatmentType: undefined,
            label: "",
            tags: [],
            organisation: undefined,
            sort: SORT_DESC,
            country: {id: "", tag: ""}
        };
        this.checkboxAll = false;
    }
    
    @Mutation
    setSearchedChecked() { this.index.checkAll(this.checkboxAll); }

    @Mutation
    setAll(devices: DeviceModel[]) {
        this.index.map(devices);
        this.index.searched.sort(deviceSortFunction(this.filters.sort));
    }

    @Mutation
    sortByUpdatedTime(payload: {direction: string}) {
        this.index.searched.sort(deviceSortFunction(payload.direction));
    }

    @Action
    updateLicenses(payload: {selected: {device_id: string, id: string, expiry: number}[]}) {
        return network().post("/op/admin/licenses", JSON.stringify({selected: payload.selected}));
    }

    @Action
    sync(payload: {selected: DeviceSelected[]}) {
        const deviceIds: string[] = [];

        for (let i = 0; i < payload.selected.length; i++) {
            deviceIds.push(payload.selected[i].device_id);
        }

        if (deviceIds.length > 0) {
            return network().post("/op/admin/devices/sync", JSON.stringify({
                devices: deviceIds
            }));
        }
        return;
    }

    @Action
    assignOrg(payload: DeviceUpdateContent<{org_id: string}>) {
        return requestDeviceUpdate(this.index, {
            action: "assign",
            options: {
                interaction: true,
                org_id: payload.options!.org_id
            },
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                device.org_id = payload.options!.org_id;
            }
        });
    }

    @Action
    setStorage(payload: DeviceUpdateContent<{interaction: boolean}>) {
        return requestDeviceUpdate(this.index, {
            action: "storage",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                const interaction: boolean = payload.options!.interaction;
                if (!device.local) {
                    device.local = {
                        content: {
                            label: "",
                            tags: [],
                            storage: interaction,
                        },
                        timestamp: Date.now()
                    };
                }

                device.local!.content.storage = interaction;
                device.local!.timestamp = Date.now();
            }
        });
    }

    @Action
    async fetch() {
        let raws: any[] = jsonParse((await network().get("/d/admin/devices")).data).body;
        const devices: DeviceModel[] = [];
        raws = toArray(raws);

        for (let i = 0; i < raws.length; i++) {
            const raw: any = raws[i];
            let tagValue = [];

            if (raw.local && raw.local.content.tags.trim() != "") {
                tagValue  = toArray(raw.local.content.tags.trim().split(","));
            }
            let isDaily = false;

            tagValue = tagValue.filter((value) => {
                if (value == DAILY_TAG) {
                    isDaily = true;
                }
                return value != DAILY_TAG;
            });

            const deviceId: string =  raw.pk.replace("DEV#", "");

            const newDevice = {
                org_id: raw.sk.replace("ORG#", ""),
                device_id: deviceId,
                ts: raw.ts,
                daily: isDaily,
                src: raw.src ?? "UNKNOWN",
                info: raw.info,
                sensors: raw.sensors,
                roam: raw.roam,
                transducer: raw.transducer,
                license: raw.license,
                position: raw.position,
                local: raw.local ? {
                    content: {
                        label: raw.local.content.label?.trim() ?? "",
                        tags: tagValue,
                        storage: raw.local.content.storage,
                        country: raw.local.content.country,
                        daily: raw.local.content.daily
                    },
                    timestamp: raw.local.timestamp
                } : undefined,
                userconnection: raw.userconnection,
                serverconnection: raw.serverconnection,
                configuration: raw.configuration,
                desiredconfiguration: raw.desiredconfiguration,
                job: raw.job,
                licenserequest: raw.licenserequest
            } as DeviceModel;
            devices.push(newDevice as DeviceModel);
        }

        this.setAll(devices);
        this.search();
    }

    @Action
    create(data: {id: string, sn: string, label?: string}[]) {
        return network().post("/op/admin/devices", JSON.stringify({
            ids: data
        })).then((response: AxiosResponse) => {
            return jsonParse(response.data);
        });
    }

    @Action
    setCountry(payload: DeviceUpdateContent<{country: Territory | undefined}>): Promise<boolean> {
        return requestDeviceUpdate(this.index, {
            action: "country",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                const country: Territory | undefined = payload.options!.country;
                deviceLocalCheck(device);
                device.local!.content.country = country;
            }
        });
    }

    @Action
    setDaily(payload: DeviceUpdateContent<{interaction: boolean}>): Promise<boolean> {
        return requestDeviceUpdate(this.index, {
            action: "daily",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                const interaction: boolean = payload.options!.interaction;
                deviceLocalCheck(device);
                device.local!.content.daily = interaction;
            }
        });
    }

    @Action
    setTags(payload: DeviceUpdateContent<{interaction: boolean, tags: string[]}>) {
        return requestDeviceUpdate(this.index, {
            action: "tags",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                const interaction: boolean = payload.options!.interaction;
                const tagTransactions: string[] = payload.options!.tags;

                deviceLocalCheck(device);

                const tags: Set<string> = new Set(device.local!.content.tags);
                for (let i = 0; i < tagTransactions.length; i++) {
                    if (interaction) {
                        tags.add(tagTransactions[i]);
                    } else {
                        tags.delete(tagTransactions[i]);
                    }
                }

                device.local!.content.tags = Array.from(tags);
            }
        })
    }

    @Action
    disable(payload: DeviceUpdateContent<unknown>) {
        return requestDeviceUpdate(this.index, {
            action: "disable",
            options: {},
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                device.org_id = "";
            }
        })
    }

    @Action
    updateFirmwares(payload: DeviceUpdateContent<{firmware: FirmwareModel}>) {
        return requestDeviceUpdate(this.index, {
            action: "firmware",
            options: payload.options,
            selected: payload.selected,
            handle: (device: DeviceModel) => {
                // todo
            }
        })
    }
    
    
}