
import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class EditbarField extends Vue {
    @Prop({default: ""}) label?: string;
    @Prop({default: ""}) value?: any;
    @Prop({default: () => { return []; }}) rules?: [];
    @Prop({default: true}) noDetails?: boolean;
    @Prop({default: false}) hideConfirm?: boolean;
    @Prop({default: false}) hideDeny?: boolean;
    @Prop({default: false}) hideButtons?: boolean;
    
    content = this.value;

    onConfirmPressedEnter(event: any) {
        if (event.key == 'Enter') {
            this.$emit("submit", this.content);
        }
    }

    onConfirmButtonSubmit() {
        this.$emit("submit", this.content);
    }

    onConfirmButtonDeny() {
        this.$emit("deny");
    }

    updateValue(event: any) {
        this.content = event;
        this.$emit('input', event);
    }
}
