
import { Component, Prop } from "vue-property-decorator"
import {LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import L, { LatLngExpression } from 'leaflet';

import DeviceModel from "@/models/DeviceModel"
import { isDefaultCoordinates, promiseWithTimeoutBool } from "@/Utils";
import { Coordinates } from "@/Coordinates";

import { RootView } from "@/components"
import { DangerButton, RecenterButton } from "./buttons";

export const DEFAULT_ZOOM_SINGLE = 13;
export const DEFAULT_ZOOM_MAIN = 2;

const WEB_MAP_OFFSET = -200;
const MOBILE_MAP_OFFSET = -50;

export enum MapClickEventType {
    MAP = "MAP",
    MARKER = "MARKER"
}

export interface MapClickEventMarkerData {
    device: DeviceModel, 
    index: number, 
    latlng: any
}

export interface MapClickEvent {
    event: any,
    type: MapClickEventType,
    data?: MapClickEventMarkerData
}

@Component({
    components: {
        LMap,
        LTileLayer,
        LIcon,
        LMarker,
        DangerButton,
        RecenterButton
    }
})
export default class DeviceMap extends RootView {
    @Prop({default: () => {
        return [];
    }}) devices!: DeviceModel[];
    @Prop({default: false}) single!: boolean;
    @Prop({default: false}) disabled!: boolean;

    offsetToggle = false;
    showMapWarning = false;
    mapCenter = [0, 0];
    mapZoom = DEFAULT_ZOOM_MAIN;
    mapUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

    isDefaultCoords(coordinates: Coordinates | undefined): boolean { 
        if (!coordinates) return false;
        return isDefaultCoordinates(coordinates); 
    }

    $refs!: {
        map: LMap,
        marker: LMarker[]
    }

    get isIdle() {
        return this.chooseDevice?.position?.content.state ?? "0" == "0";
    }

    hasPosition(device: DeviceModel) {
        let latitude = device.position.content.latitude;
        let longitude = device.position.content.longitude;
        return latitude && longitude;
    }

    setToggleOffset(offset: boolean) {
        this.offsetToggle = offset;
    }

    hideMapWarning() {
        this.showMapWarning = false;
    }

    onDangerButtonClicked() {
        this.showMapWarning = true;
    }

    setCenter(device: DeviceModel) {
        if (this.hasFix && this.hasPosition(device)) {
            this.mapCenter = [device.position.content.latitude ?? 0, device.position.content.longitude ?? 0];
        } else if (device.info && device.info.content.deployed && device.info.content.deployedCoordinates != null) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            this.mapCenter = [device.info.content.deployedCoordinates!.latitude ?? 0, device.info.content.deployedCoordinates!.longitude ?? 0];
        } else if (device.userconnection) {
            this.mapCenter = this.getPosition(device);
        }
        
    }

    getOpacity(device: DeviceModel): number {
        const mainDevice: DeviceModel | null = this.chooseDevice;
        if (mainDevice == null) return 1;
        if (mainDevice.device_id == device.device_id) return 1;
        return 0.3;
    }

    getZIndex(device: DeviceModel): number {
        const mainDevice: DeviceModel | null = this.chooseDevice;
        if (mainDevice == null) return 300;
        if (mainDevice.device_id == device.device_id) return 300;
        return 290;
    }


    beforeMount() {
        if (this.single) {
            this.mapZoom = DEFAULT_ZOOM_SINGLE;
        }
    }

    mounted() {
        if (this.device != null) {
            this.setCenter(this.device);
        } else if (this.devices.length > 0) {
            this.setCenter(this.devices[0]);
        }
    }

    onMapReady() {
        if (this.isMobile) {
            this.$refs.map.mapObject.panToOffset({lat: this.mapCenter[0], lng: this.mapCenter[1]}, [0, MOBILE_MAP_OFFSET], { animate: false });
        }
    }

    moveTo(payload: {position: LatLngExpression, timeout?: number, zoom?: number}) : Promise<boolean> {
        const map: LMap = this.$refs.map;
        const timeout = payload.timeout ?? 3000;

        if (!payload.position) return Promise.resolve(false);

        if (this.isMobile) {
            return promiseWithTimeoutBool({ promise: new Promise((resolve, reject) => {
                map.mapObject.panToOffset(payload.position, [0, MOBILE_MAP_OFFSET], { animate: true });
                map.mapObject.on('moveend', () => {
                    resolve(true)
                })
            }), timeout: timeout});
        }
        
        return promiseWithTimeoutBool({ promise:new Promise((resolve, reject) => {
                map.mapObject.panToOffset(payload.position, [ this.offsetToggle ? WEB_MAP_OFFSET : 0, 0], {
                    animate: true
                });
                map.mapObject.on('moveend', () => {
                    resolve(true)
                })
            }), timeout: timeout});
    }

    actionPerDevice(deviceId: string, action: (element: any) => void) {
        if (this.single) {
            if (!this.device) return;
            action((this.$refs.marker[0].mapObject as any));
        }

        for (let i = 0; i < this.devices.length; i++) {
            if (this.devices[i].device_id == deviceId) {
                action((this.$refs.marker[i].mapObject as any));
                break;
            }
        }
    }

    jumpToPosition() {
        if (!this.chooseDevice || this.chooseDevice == null) return;
        this.moveTo({position: this.getPosition(this.chooseDevice), zoom: this.mapZoom});
    }

    userIcon: any = L.icon({
        iconUrl: require("@/assets/person.svg"),
        iconSize: [50, 50],
        iconAnchor: [25, 1]
    })

    displayIcon(device: DeviceModel): any {
        return L.icon({
            iconUrl: this.deviceIcon(device) as unknown as string,
            iconSize: [50, 50],
            iconAnchor: [25, 1]
        })
    }

    onMarkerClicked(e: any, device: DeviceModel, index?: number, latlng?: any) {
        this.$emit("click", {
            event: e,
            type: MapClickEventType.MARKER,
            data: {
                device: device,
                index: index,
                latlng: latlng
            }
        } as MapClickEvent);
    }

    onMapClicked(e: any) {
        this.$emit("click", {
            event: e,
            type: MapClickEventType.MAP,
        } as MapClickEvent);
    }

}
