
import { Component, Vue } from 'vue-property-decorator';

import { Tab } from "@/Utils";
import {toast, ToastType} from "@/Toast";
import { DeviceSelected } from '@/models/DeviceSelection';

import { RootView, PageTitle, TabBar, DeviceMap, MapClickEvent, MapClickEventType } from "@/components";
import { TagsBlock, DeviceInfoBlock, LastUpdateBlock, CountryBlock, OrganisationLinkBlock } from '@/components/blocks';
import { DeviceCommandsDropdown, DeviceOptionsDropdown } from '@/components/dropdowns';
import { DisableButton, ShadowButton, StorageButton, BackButton } from '@/components/buttons';
import { DeviceInfoSheet } from '@/components/sheets';
import { ClassicContainerLayout } from '@/components/layouts';
import { DailyIcon } from '@/components/icons';

import DeviceAdminTimestampsView from '@/views/pages/organisation/org_id/devices/dev_id/DeviceAdminTimestampsView.vue';
import DeviceAdminLicensesView from '@/views/pages/organisation/org_id/devices/dev_id/DeviceAdminLicensesView.vue';
import DeviceAdminJobsView from '@/views/pages/organisation/org_id/devices/dev_id/DeviceAdminJobsView.vue';
import DeviceAdminEventsView from '@/views/pages/organisation/org_id/devices/dev_id/DeviceAdminEventsView.vue';

@Component({
    components: {
        ClassicContainerLayout, TagsBlock, PageTitle, DeviceMap, BackButton,
        LastUpdateBlock, DeviceAdminLicensesView, DeviceAdminTimestampsView, DeviceAdminJobsView,
        DeviceAdminEventsView, DeviceInfoBlock, TabBar, StorageButton, DeviceCommandsDropdown,
        DisableButton, ShadowButton, DeviceOptionsDropdown, DeviceInfoSheet,
        CountryBlock, DailyIcon, OrganisationLinkBlock
    }
})
export default class DevicePageView extends RootView {
    
    currentAdminTab = "timestamps";

    $refs!: {
        updates: HTMLElement,
        map: DeviceMap
    }

    get tabs(): Tab[] {
        return [{
            id: 'timestamps',
            label: 'Timestamps'
        }, {
            id: "licenses",
            label: 'Licenses'
        }, {
            id: "jobs",
            label: "Jobs"
        }, {
            id: "events",
            label: "Events"
        }];
    }

    onMapClicked(payload: MapClickEvent) {
        if (payload.type == MapClickEventType.MAP) return;
        this.$refs.map.jumpToPosition();
    }

    onCompleteAdminCommand(device: DeviceSelected[]) {
        if (device[0].success == undefined || (device[0].success != undefined && device[0].success)) {
            toast({
                message: "Operation done with success.",
                type: ToastType.TOAST_SUCCESS
            });
        } else {
            toast({
                message: "Failed operation.",
                type: ToastType.TOAST_ERROR
            })
        }
    }

    scrollToUpdates() {
        this.$refs.updates.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    onTabChanged(id: string) {
        this.currentAdminTab = id;
    }

    destroyed() {
        this.$store.commit("device/clear");
    }
}
