
import { Component } from "vue-property-decorator";
import { MODAL_INVITE_ORGANISATION, UserRole } from "@/Data";

import { RootModal, ModalSetup } from "@/components";

@Component
export default class InviteOrganisationModalView extends RootModal {

    showError = false;
    inviteId = "";
    inviteRole = "";
    orgId = "";
    UserRole = UserRole;

    override setup(): ModalSetup {
        return {
            name: MODAL_INVITE_ORGANISATION
        };
    }

    onOpen() {
        this.showError = false;
        this.inviteId = "";
        this.inviteRole = "";
        this.orgId = this.$store.state.organisations.model.org_id;
    }

    override async process(): Promise<any> {
        try {
            const result: boolean = await this.$store.dispatch("organisation_users/invite", {
                email: this.inviteId,
                role: this.inviteRole,
                org_id: this.orgId
            });

            if (!result) {
                return Promise.resolve(false);
            }

            return true;
        } catch(error) {
            console.log(error);
            return Promise.resolve(false);
        }
    }

    override onProcessFailure() {
        this.showError = true;
    }

    override onProcessSuccess() {
        this.showError = false;
    }

    onSearch() {
        this.$store.dispatch("organisation_users/search");
    }
}
