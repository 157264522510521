import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { 
    faBars, faHouse, faCircleUser, faClipboardQuestion, faKey, faIdBadge, 
    faMicrochip, faBuildingUser, faRotate, faTriangleExclamation, faMagnifyingGlass, faArrowLeft,
    faXmark, faX, faPowerOff, faBox, faStopwatch, faPenToSquare, faTrash, 
    faChevronDown, faChevronUp, faUsersViewfinder, faUpRightFromSquare, faEyeSlash,
    faEye, faPlus, faPhone, faEnvelope, faGlobe, faBatteryThreeQuarters, faLocationDot, faChevronLeft,
    faCloud, faUsers, faSitemap, faBan, faFile, faCalendarXmark, faCalendar, faCaretLeft, faCaretRight,
    faCircleInfo, faGear, faGears, faUser, faCircleChevronLeft, faTable, faArrowTurnUp,
    faMapLocationDot, faBell, faArrowRight, faLocationPinLock, faListCheck, faAnglesRight, faAnglesLeft,
    faDoorOpen, faInbox, faRankingStar, faAsterisk
} from '@fortawesome/free-solid-svg-icons';

import { 
    faCalendarCheck as faCalendarCheckRegular, faPenToSquare as faPenToSquareRegular, faFile as faFileRegular, 
    faCircleCheck as faCircleCheckRegular, faCircleXmark as faCircleXmarkRegular, faRectangleXmark as faRectangleXmarkRegular, 
    faSquareCheck as faSquareCheckRegular, faHourglassHalf as faHourglassHalfRegular, faCircleQuestion as faCircleQuestionRegular, 
    faClipboard as faClipboardRegular, faCircleLeft as faCircleLeftRegular, 
    faCircleRight as faCircleRightRegular, faSquareCaretUp as faSquareCaretUpRegular, faCalendar as faCalendarRegular,
 } from '@fortawesome/free-regular-svg-icons';

library.add(
    faBars, faHouse, faCircleUser, faClipboardQuestion, faKey, faIdBadge, faMicrochip, faBuildingUser, faRotate,
    faTriangleExclamation, faMagnifyingGlass, faArrowLeft, faXmark, faX, faPowerOff, faBox, faStopwatch, faPenToSquare, faTrash, faChevronDown,
    faChevronUp, faUsersViewfinder, faUpRightFromSquare, faEyeSlash, faEye, faPlus, faPhone, faEnvelope, faGlobe, faBatteryThreeQuarters, faLocationDot,
    faChevronLeft, faCloud, faUsers, faSitemap, faBan, faFile, faCalendarXmark, faCalendar, faCaretLeft, faCaretRight, faCircleInfo,
    faCalendarCheckRegular, faPenToSquareRegular, faFileRegular, faCircleCheckRegular, faCircleXmarkRegular, faRectangleXmarkRegular, faSquareCheckRegular,
    faHourglassHalfRegular, faCircleQuestionRegular, faClipboardRegular, faCircleLeftRegular, faCircleRightRegular, faGear, faGears, 
    faUser, faSquareCaretUpRegular, faCircleChevronLeft, faMapLocationDot, faCalendarRegular, faArrowTurnUp,
    faBell, faArrowRight, faLocationPinLock, faListCheck, faAnglesRight, faAnglesLeft, faTable, faDoorOpen, 
    faInbox, faRankingStar, faAsterisk
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

export default library