
import { Prop, Component, Vue } from "vue-property-decorator";
import CountryIcon from "../icons/CountryIcon.vue";

@Component({
    components: {
        CountryIcon
    }
})
export default class CountryBlock extends Vue {
    @Prop({default: ""}) countryId!: string;
}
