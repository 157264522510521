import { Component, Watch } from 'vue-property-decorator';
import { MODAL_EDIT_ORGANISATION, MODAL_LEAVE_ORGANISATION, UserRole } from '@/Data';
import { Command } from '@/Utils';
import { modal } from '@/Toast';

import { RootView } from "@/components"

@Component
export default class OrganisationPageView extends RootView {
    role: UserRole = UserRole.GUEST;
    UserRole = UserRole
    orgId = "";
    checkboxAll = false;
    tab = "";

    commands: Command[] = [];

    beforeMount() {
        this.orgId = this.$route.params.id;
        this.role = (this.$store.state as any).auth.user.memberships.get(this.orgId);

        if (this.hasPriviledgeToEditOrganisation(this.orgId)) {
            this.commands.push( {
                id: "edit",
                name: this.$t("organisation_command_edit").toString()
            });
        }

        if (this.isMobile) {
            this.commands.push({
                id: "leave",
                name: this.$t('command_leave').toString()
            })
        }
    }

    onDevicesTabClicked() {
        this.goto(`/dashboard?org=${this.orgId}`)
    }

    onLeaveButtonClicked() {
        modal(MODAL_LEAVE_ORGANISATION, [this.$store.state.user_memberships.index.get(this.orgId)]);
    }

    onCommandClicked(command: Command) {
        switch(command.id) {
            case "edit": 
                modal(MODAL_EDIT_ORGANISATION);
                break;
            case "leave":
                this.onLeaveButtonClicked();
                break;
            default:
                break;
        }
    }

    authPageCondition(): boolean {
        return this.$store.state.auth.user.memberships.has(this.$route.params.id) 
             || this.$store.state.auth.user.internals.includes("ADMIN");
    }

    @Watch("$store.state.admin.leftOrgHash")
    onValueChange() {
        this.role = (this.$store.state as any).auth.user.memberships.get(this.orgId);
    }

}