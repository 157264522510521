
import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class PasswordRequirementText extends Vue {
    @Prop({default: ""}) text!: string;
    @Prop({default: false}) status!: boolean;

    get icon() {
        if (this.status) {
            return "fa-regular fa-circle-check";
        }
        return "fa-regular fa-circle-xmark";
    }

    get classStyle() {
        if (this.status) {
            return "text-success";
        }
        return "text-danger"
    }
}
