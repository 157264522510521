
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PasswordField extends Vue {
    @Prop({default: ""}) label!: string;
    @Prop({default: ""}) id!: string;
    @Prop({default: ""}) value!: string;
    @Prop({default: ""}) hint!: "";
    @Prop({default: false}) counter!: boolean;
    @Prop({default: () => {return []}}) error_messages!: string[];
    @Prop({default: () => {return []}}) rules!: any[];
    show = false;

    onDataChanged(data: string) {
        this.$emit("input", data);
        this.$emit('change', data);
    }

    toggle() {
        this.show = !this.show;
    }

    mounted() {
        this.show = false;
    }

    hide() {
        this.show = false;
    }
    
    get button(): string {
        if (this.show) return "fa-solid fa-eye-slash";
        return "fa-solid fa-eye";
    }

    get showType(): string {
        if (this.show) {
            return "text";
        }
        return "password";
    }
}
