
import { Component } from "vue-property-decorator";
import RootView from "@/components/RootView";

@Component
export default class InputAddButton extends RootView {
    onAddClicked() {
        this.$emit("add");
    }
}
