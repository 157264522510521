import store, { modules } from "@/store";
import { InternalRole, UserRole } from "@/Data";
import { hideLoading } from "@/Toast";
import UserOrganisationModel from "@/models/UserOrganisationModel";

let startLocation: string | null = null;

export interface RouteProps {
    _sidebar: boolean;
    _loading: boolean;
    _overflow: boolean;
}

export interface MiddlewareResponse {
    redirect?: string
}

export interface MiddlewarePayload {
    params: any,
    query: any,
    path: string
}

export interface MiddlewareParameters {
    admin?: boolean,
    callback?: (payload: MiddlewarePayload) => Promise<MiddlewareResponse>,
    auth?: boolean
}

export const routeProps = (payload: {sidebar?: boolean, loading?: boolean, overflow?: boolean}): RouteProps => {
    return {
        _sidebar: payload.sidebar ?? true,
        _loading: payload.loading ?? true,
        _overflow: payload.overflow ?? true
    }
}

export const skipAuth = (to: any, names: string[]): boolean => {
    for (let i = 0; i < names.length; i++) {
      if (to.name == names[i]) {
        return true;
      }
    }
    return false;
}
  
export const execute = (callback: ((payload: MiddlewarePayload) => Promise<MiddlewareResponse>) | undefined = undefined, to:any, next: any) => {
    if (callback) {
        // eslint-disable-next-line
        new Promise<MiddlewareResponse>(async (resolve, reject) => {
            const result: MiddlewareResponse = await callback({
                params: to.params,
                query:  to.query,
                path: to.path
            });
            resolve(result);
        }).then((data: MiddlewareResponse) => {
            if (data.redirect) {
                next(data.redirect)
            } else {
                next();
            }
        })
    } else {
        next();
    }
}

export const isAdmin = (): boolean => {
    return (store.state as any).auth.user.internals.includes("ADMIN");
}

export const getMembership = (orgId: string): UserOrganisationModel | undefined => {
    return modules.userMemberships.index.get(orgId);
}

export const hasOrganisationRole = (orgId: string, ...roles: UserRole[]): boolean => {
    const role: UserRole = modules.userMemberships.index.get(orgId)!.role.toUpperCase() as UserRole;
    return roles.includes(role);
} 

export const adminMw = (input?: {callback?: (payload: MiddlewarePayload) => Promise<any>}) => {
    return middleware({callback: async (payload: MiddlewarePayload): Promise<MiddlewareResponse> => {
        if(!isAdmin()) {
            return {
                redirect: "/"
            };
        }
 
        const fetch: Promise<any>[] = [];
        const modules: Map<string, string> = new Map([
            ["/admin", "devices"],
            ["/admin/organisations", "organisations"],
            ["/admin/firmwares", "firmwares"]
        ]);
        
        store.dispatch(`admin/fetchAppSettings`);
        
        for (const [path, value] of modules) {
            const module = `admin_${value}`;
            
            if (payload.path == path) {
                store.commit(`${module}/setQuery`, payload.query);
            }

            fetch.push(store.dispatch(`${module}/fetch`));
        }

        await Promise.all(fetch);
        
        if (input?.callback) {
            await input.callback(payload);
        }

        return {};
     }});
}

export const middleware = (payload?: MiddlewareParameters): (to: any, from: any, next: any) => any => {
    return (to: any, from: any, next: any) => {
        if (!startLocation) {
            startLocation = from.fullPath || '/'; // Store the initial route only once
        }

        const authRequired = payload?.auth ?? true;
        const callback = payload?.callback;

        if (!authRequired) {
            execute(callback, to, next);
        } else if (to.name != "Login" && store.getters["auth/isAuthenticated"]) {
            execute(callback, to, next);
        } else {
            store.dispatch("auth/getCurrentUser").then(async (result: boolean) => {
                
                store.commit("device/clear");

                if (result) {
                    await store.dispatch("user/fetch");
                    await store.dispatch("user_memberships/fetch");
                    await store.dispatch("user_invitations/fetch");

                    if (to.name == "Entry") {
                        if (modules.auth.user?.internals.includes(InternalRole.ADMIN)) {
                            next({path: "/admin"});
                        } else {
                            next({path: "/dashboard"});
                        }
                    } else {
                        execute(callback, to, next);
                    }
                } else {
                    hideLoading();

                    const modulesToClear = [
                        "device", "admin_firmwares", "admin_devices", "admin_organisations", 
                        "organisations", "organisation_users", "organisation_devices", "user",
                        "user_memberships", "user_invitations"
                    ];

                    for (let i = 0; i < modulesToClear.length; i++) {
                        store.commit(`${modulesToClear[i]}/clear`);
                    }

                    if (startLocation != null && startLocation.includes("/invite/")) {
                        store.commit("invite/clear");
                    }
                    
                    next({path: "/login"});
                }
        })
      }
    }
  }