
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import { modal, toast, ToastType } from "@/Toast";
import { MODAL_ADMIN_DISABLE } from "@/Data";

@Component
export default class DisableButton extends RootView {
    
    onDisableButtonClicked() {
      if (this.device == null && this.selectedDevices.length <= 0) {
        toast({
          message: this.$t("toast_missing_selection", ["device"]).toString(),
          type: ToastType.TOAST_ERROR
        });
        return;  
      }

      modal(MODAL_ADMIN_DISABLE);
    }

}
