
import { Component, Watch } from "vue-property-decorator";
import RootView from "@/components/RootView";
import EventModel from "@/models/EventModel";
import SearchDropdown from "@/components/dropdowns/SearchDropdown.vue";
import { EVENTS } from "@/Data";
import { Command } from "@/Utils";

@Component({
    components: {
        SearchDropdown
    }
})
export default class DeviceAdminEventsView extends RootView {
    currentLimit = 25;
    limit = 25;
    currentPageIndex = 0;
    items: EventModel[] = [];
    selected: Set<string> = new Set();
    fromDateStr = "";
    toDateStr = "";

    mounted() {
        this.refresher();
    }

    get textStyle() {
        if (this.isMobile) return "font-size: 12px; margin-bottom: 0;"
        return "";
    }

    get events() {
        return EVENTS;
    }

    get startIndex(): number {
        return this.currentPageIndex * this.limit;
    }

    get endIndex(): number {
        let endIndex = this.currentPageIndex * this.limit + this.limit - 1;
        if (endIndex > this.$store.state.device.events.content.length) {
            endIndex = this.$store.state.device.events.content.length - 1;
        }
        return endIndex;
    }

    get canPreviousPage (): string {
        if (this.currentPageIndex > 0) {
            return 'swap-btn';
        }
        return 'swap-btn-disabled';
    }

    get canNextPage (): string {
        if ((this.$store.state.device.events.next_token && this.currentPageIndex >= this.$store.state.device.events.last_page -1) || this.currentPageIndex < this.$store.state.device.events.last_page - 1) {
            return 'swap-btn';
        }
        return 'swap-btn-disabled';
    }

    previousPage() {
        if (this.currentPageIndex <= 0) {
            return;
        }

        this.currentPageIndex--;
        this.refresher();
    }

    async nextPage() {
        if (this.$store.state.device.events.next_token && this.currentPageIndex >= this.$store.state.device.events.last_page -1) {
            await this.$store.dispatch("device/fetchEvents");
        }

        if (this.currentPageIndex < this.$store.state.device.events.last_page - 1) {
            this.currentPageIndex++;
            this.refresher();
        }
    }

    itemKey(event: EventModel): string  {
        return `${event.ts}#${event.event_id}#${event.src}#${event.type}`;
    }

    onEventIDSelected(selected: Command[]) {
        this.selected.clear();
        for (let i = 0; i < selected.length; i++) {
            this.selected.add(selected[i].id);
        }
        this.refresher();
    }

    refresher() {
        const back: EventModel[] = [];
        const results: EventModel[] = [];
        const baseIndex = this.currentPageIndex * this.limit;
        let endIndex = this.currentPageIndex * this.limit + this.limit;

        this.currentLimit = this.limit;
        if (endIndex > this.$store.state.device.events.content.length) {
            endIndex = this.$store.state.device.events.content.length;
            this.currentLimit = endIndex - baseIndex;
        }

        for (let i = baseIndex; i < endIndex; i++) {
            back.push(this.$store.state.device.events.content[i]);
            if (this.selected.size == 0) {
                results.push(this.$store.state.device.events.content[i]);
            } else {
                if (this.selected.has(this.$store.state.device.events.content[i].event_id)) {
                    results.push(this.$store.state.device.events.content[i]);
                }
            }
        }

        if (back.length > 0) {
            this.toDateStr = this.dateFormatStrWithTime(back[0].ts);
            this.fromDateStr = this.dateFormatStrWithTime(back[back.length-1].ts);
        } else {
            this.toDateStr = "";
            this.fromDateStr = "";
        }
        
        this.items = results;
    }
}
