
import { Component } from "vue-property-decorator";

import { modal } from "@/Toast";
import { 
    MODAL_ADMIN_CREATE_EMPTY, MODAL_ADMIN_GEN_LIC, MODAL_ADMIN_OP_SUCCESS,
    QUERY_PARAM_LABEL,
    QUERY_PARAM_TAGS
 } from "@/Data";
 
import { DeviceSelected } from "@/models/DeviceSelection";

import { Accordion, RootView, PageTitle, MissingData, Markable } from "@/components"
import { CheckboxField, ChipSearchField, SearchbarField } from "@/components/fields";
import { CountryDropdown, DeviceCommandsDropdown, LastUpdateTypeSearchDropdown, OrganisationSearchDropdown, TreatmentTypesSearchDropdown } from "@/components/dropdowns";
import { DeviceDashboardBlock } from "@/components/blocks";
import { MarkOptions } from "mark.js";

@Component({
    components: {
        Accordion, PageTitle, DeviceDashboardBlock,
        SearchbarField, LastUpdateTypeSearchDropdown, OrganisationSearchDropdown, 
        TreatmentTypesSearchDropdown, ChipSearchField, MissingData, 
        CheckboxField, DeviceCommandsDropdown, CountryDropdown, Markable
    }
})
export default class MobileAdminPageView extends RootView {
    showLicenseWarning = true;
    toggleFab = false;

    showSearchLabel = this.$store.state.admin_devices.filters.label.trim().length > 0;
    savedLabel = this.$store.state.admin_devices.filters.label.trim();

    get markOptions(): MarkOptions {
        return {
          acrossElements: true,
          exclude: [
            ".country-block *",
            ".organisation-link-block *",
            ".tags-elements *",
            ".box-indicator-block *",
            ".last-update-block *",
            ".license-dashboard-block *"
          ]
        }
    }


    get toastShowing(): boolean {
        return this.$store.state.global.toast;
    }

    get actionBtnStyle() {
        if (this.toastShowing) return 'margin-bottom: 48px;'
        return '';
    }

    onHideLicenseWarningClicked() {
        this.showLicenseWarning = false;
    }

    onCreateNewDeviceButtonClicked() {
        modal(MODAL_ADMIN_CREATE_EMPTY);
    }

    onCreateLicenseButtonClicked() {
        modal(MODAL_ADMIN_GEN_LIC);
    }

    onAllCheckboxChanged() {
        this.$store.commit("admin_devices/setSearchedChecked");
    }

    onCheckboxChanged() {
        this.$store.commit("admin_devices/search");
    }

    showAdminSuccessModel(data: DeviceSelected[]) {
      modal(MODAL_ADMIN_OP_SUCCESS, data);
    }

    onLabelSearch() {
      if (this.$store.state.admin_devices.filters.label.trim() == "") {
        this.$nextTick(() => {
            this.showSearchLabel = false;
            this.savedLabel = "";
        })
      }
      this.$store.commit(this.ACTIONS.ADMIN_SEARCH_DEVICES);

      if (this.$store.state.admin_devices.filters.label.trim() == "") {
        this.removeFromQuery(QUERY_PARAM_LABEL);
        return;
      }
      
      this.setQuery(QUERY_PARAM_LABEL, this.$store.state.admin_devices.filters.label.trim());
      this.$nextTick(() => {
        this.savedLabel = this.$store.state.admin_devices.filters.label.slice();
        this.showSearchLabel = true;
      })
    }

    onTagChanged(tags: string[]) {
      this.$store.commit(this.ACTIONS.ADMIN_FILTERS, {filter: "tags", value: tags});
      this.$store.commit(this.ACTIONS.ADMIN_SEARCH_DEVICES);

      if (tags.length > 0) {
        this.setQuery(QUERY_PARAM_TAGS, tags.join(","));
      } else {
        this.removeFromQuery(QUERY_PARAM_TAGS);
      }
    }

    mounted() {
        if (
            this.$store.state.admin_devices.filters.label.trim() != "" ||
            this.$store.state.admin_devices.filters.tags.length > 0 ||
            this.$store.state.admin_devices.filters.lastUpdateType != null ||
            this.$store.state.admin_devices.filters.treatmentType != null ||
            this.$store.state.admin_devices.filters.organisation != null ||
            this.$store.state.admin_devices.filters.transducerDisconnectedOnly ||
            this.$store.state.admin_devices.filters.licenseExpiredOnly ||
            this.$store.state.admin_devices.filters.dailyOnly
        ) {
            this.$store.commit(this.ACTIONS.ADMIN_SEARCH_DEVICES);
        }
    }
}
