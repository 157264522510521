
import { Component } from "vue-property-decorator";

import { MODAL_ADMIN_SYNC } from "@/Data";
import { DeviceSelected } from "@/models/DeviceSelection";
import { ModalOptions, toast, ToastType } from "@/Toast";

import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts"
import { jsonParse } from "@/Utils";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class AdminSyncDeviceModalView extends RootView {
    get options(): ModalOptions {
      return {
        modal: MODAL_ADMIN_SYNC,
        title: "modal_adminsync_title",
        desc: "modal_adminsync_desc",
        action: this.process,
        options: false
      }
    }

    async process(): Promise<any> {

        const selected: DeviceSelected[] = this.selectedDevices;
        toast({
                message: "Syncing device(s).. Please wait..",
                type: ToastType.TOAST_INFO
            });
        let result = await this.$store.dispatch("admin_devices/sync", {selected: selected});
        let body = jsonParse(result.data);
        let failures: string[] = body.failures;
        
        if (failures.length > 0) {
            return Promise.resolve(false);
        }
        
        for (let i = 0; i < selected.length; i++) {
            if (failures && failures.includes(selected[i].device_id)) {
                selected[i].success = false;
                continue;
            } 
            selected[i].success = true;
        }

        return Promise.resolve(true);
    }

    
} 
