
import { Component } from "vue-property-decorator";

import { MODAL_ADMIN_CREATE_ORG, MODAL_ADMIN_DELETE_ORG } from "@/Data";
import OrganisationModel from "@/models/OrganisationModel";

import { RootView, MissingData } from "@/components";
import { Dropdown } from "@/components/dropdowns";
import { CheckboxField, SearchbarField } from "@/components/fields";
import { ListLayout, ListHeaderLayout, ListFiltersLayout, ListTooltipLayout, CenterLayout } from "@/components/layouts";
import { PlusButton } from "@/components/buttons";
import { modal, toast, ToastType } from "@/Toast";

import AdminViewLayout from "../AdminViewLayout.vue";
import { Command, selection } from "@/Utils";

@Component({
    components: {
        AdminViewLayout, Dropdown, CheckboxField,
        ListLayout, ListHeaderLayout, ListFiltersLayout, ListTooltipLayout,
        PlusButton, MissingData, SearchbarField, CenterLayout
    }
})
export default class AdminOrganisationsPageView extends RootView {

    commands: Command[] = [
        { id: "delete", name: "Delete"}
    ]

    onOrganisationButtonClicked(organisation: OrganisationModel) {
        this.goto({ path: `/organisation/${organisation.org_id}`});
    }

    onCreateButtonClicked() {
        modal(MODAL_ADMIN_CREATE_ORG);
    }

    onCommandClicked(command: Command) {
        const selectedOrganisations = this.$store.state.admin_organisations.index.selected;
        if (selectedOrganisations <= 0) {
            toast({
                message: this.$t("toast_missing_selection", ["organisation"]).toString(),
                type: ToastType.TOAST_ERROR
            });
            return;  
        }

        switch(command.id) {
            case "delete":
                modal(MODAL_ADMIN_DELETE_ORG);
                break;
            default:
                break;
        }
    }
}
