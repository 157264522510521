
import { Vue, Component } from "vue-property-decorator";

import Offcanvas from "bootstrap/js/dist/offcanvas"
import RootView from "@/components/RootView";

import {MODAL_LOGOUT} from "@/Data";
import {modal} from "@/Toast";

import Branding from "@/components/Branding.vue";
import AppLink from "@/components/AppLink.vue";

@Component({
    components: {
        Branding,
        AppLink,
    }
})
export default class MobileSidebar extends RootView {

    canvas: Offcanvas | undefined;

    $refs!: {
        sidebar: HTMLElement
    }

    onMobileAppBtnClick() {
        window.open(process.env.VUE_APP_MOBILE_APP_DOWNLOAD_URL!)
    }

    onSignoutClicked() {
        modal(MODAL_LOGOUT);
    }
    
    show() {
        this.canvas = new Offcanvas(this.$refs.sidebar)
        this.canvas.show();
    }

    hide() {
        this.canvas?.hide();
    }
    
}
