

import { Prop, Component } from "vue-property-decorator";

import RootView from "../RootView";
import DeviceOptionsDropdown from "../dropdowns/DeviceOptionsDropdown.vue";
import TreatmentTag from "../tags/TreatmentTag.vue";
import TransducerTag from "../tags/TransducerTag.vue";
import DeviceInfoLicenseSheet from '../sheets/DeviceInfoLicenseSheet.vue';
import LicenseBlock from "../blocks/LicenseBlock.vue";

@Component({
    components: {
        DeviceOptionsDropdown,
        TreatmentTag,
        TransducerTag,
        DeviceInfoLicenseSheet,
        LicenseBlock
    }
})
export default class DeviceInfoSheet extends RootView {
    @Prop({default: false}) dashboard!: boolean
} 
