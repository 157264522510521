
import { Component, Vue } from "vue-property-decorator";

import RootView from "@/components/RootView";

import { LoginLayout } from "@/components/layouts";
import { InfoBlock } from "@/components/blocks";
import { VForm } from "@/components";
import { PasswordSheet } from "@/components/sheets";

@Component({
    components: {
        LoginLayout,
        InfoBlock,
        PasswordSheet
    }
})
export default class ResetPasswordPageView extends RootView {
    STATE_INIT = "init";
    STATE_END = "end";
    
    state = this.STATE_INIT;
    password = "";
    passwordConfirm = "";

    $refs!: {
        form: VForm
    }

    mounted() {
        this.state = this.STATE_INIT;
        this.password = "";
        this.passwordConfirm = "";
    }

    confirm() {
        if (!this.$refs.form.validate()) return;

        this.$store.dispatch("auth/resetPassword", {
            token: this.$route.params["token"],
            password: this.password.trim()
        }).then(() => {
            this.state = this.STATE_END;
        });
    }

    cancel() {
        this.goto({path: "/login"});
    }

}

