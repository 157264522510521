
import { Component } from "vue-property-decorator";
import { MODAL_ADMIN_STORAGE } from "@/Data";
import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts"
import { ModalOptions } from "@/Toast";

@Component({
  components: {
    DeviceModalLayout
  }
})
export default class AdminSetStorageModeModalView extends RootView {

    storageSwitch = true;

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_STORAGE,
            title: "modal_adminstorage_title",
            desc: "modal_adminstorage_desc",
            action: this.process,
            onOpen: this.onOpen
        }
    }

    onOpen() {
        this.storageSwitch = true;
    }

    process(): Promise<any> {
      return this.$store.dispatch("admin_devices/setStorage", {options: {interaction: this.storageSwitch}, selected: this.selectedDevices });
    }
}
