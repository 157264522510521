
import { Component } from "vue-property-decorator";

import { FilterSort, MissingData, PageTitle } from "@/components";
import { ListLayout, ListHeaderLayout, ListTooltipLayout, ListFiltersLayout, CenterLayout } from "@/components/layouts";
import { Dropdown, LastUpdateTypeSearchDropdown, SelectDropdown, TreatmentTypesSearchDropdown, OrganisationSearchDropdown } from "@/components/dropdowns";
import { CheckboxField, SearchbarField } from "@/components/fields";
import { LastUpdateBlock, OrganisationLinkBlock } from "@/components/blocks";
import { LicenseTag, TransducerTag, TreatmentTag, ConnectivityTag } from "@/components/tags";

import BaseDashboardPageView from "./BaseDashboardPageView";

@Component({
    components: {
        ListLayout, ListHeaderLayout, ListTooltipLayout,
        Dropdown, SelectDropdown, CheckboxField, LastUpdateBlock,
        LicenseTag, TransducerTag, TreatmentTag, ConnectivityTag,
        ListFiltersLayout, SearchbarField, LastUpdateTypeSearchDropdown,
        TreatmentTypesSearchDropdown, CenterLayout, MissingData, PageTitle,
        OrganisationSearchDropdown, FilterSort, OrganisationLinkBlock
    }
})
export default class DashboardPageView extends BaseDashboardPageView {

}
