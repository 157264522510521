import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import Pages from "@/views/pages";

import store, { modules } from '@/store'
import { UserRole } from '@/Data'
import { isMobile } from 'mobile-device-detect';
import { adminMw, getMembership, hasOrganisationRole, isAdmin, middleware, MiddlewarePayload, MiddlewareResponse, routeProps } from './Utils';
import UserOrganisationModel from '@/models/UserOrganisationModel';

Vue.use(VueRouter);

const prepareOrganisationDevices = async (query: any): Promise<any> => {
  const promiseDevices: Promise<any>[] = [];
  
  store.commit("organisation_devices/clear");
  store.commit("organisation_devices/setQuery", {
    query: query, 
    organisations: modules.userMemberships.index.models.map((value: UserOrganisationModel) => {
      return value.org;
    })
  });

  for (let i = 0; i < modules.userMemberships.index.models.length; i++) {
    promiseDevices.push(store.dispatch("organisation_devices/fetch", {
      org_id: modules.userMemberships.index.models[i].org_id
    }));
  }

  await Promise.all(promiseDevices);
  store.commit("organisation_devices/search");
  return Promise.resolve({});
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Entry',
      beforeEnter: middleware(),
    },
    {
      path: '/login',
      name: 'Login',
      component: Pages.LoginPageView,
      beforeEnter: middleware({auth: false}),
      props: routeProps({sidebar: false, loading: false})
    },
    {
      path: '/reset/:token',
      name: "Reset",
      component: Pages.ResetPasswordPageView,
      props: routeProps({sidebar: false, loading: false})
    },
    {
      path: '/dashboard',
      name: "Dashboard",
      component: isMobile ? Pages.MobileDashboardPageView : Pages.DashboardPageView,
      beforeEnter: middleware({callback: async (payload: MiddlewarePayload): Promise<MiddlewareResponse> => {
        return prepareOrganisationDevices(payload.query);
      }}),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: '/map',
      name: 'Map',
      component: Pages.MapPageView,
      beforeEnter: middleware({callback: async (payload: MiddlewarePayload): Promise<MiddlewareResponse> => {
        if ((store.state as any).invite.hasInvitation && !(store.state as any).invite.recentlyDone) {
          await store.dispatch("invite/confirm");
        }
        return prepareOrganisationDevices(payload.query);
      }}),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: '/profile',
      name: 'Profile',
      component: isMobile ? Pages.MobileProfilePageView : Pages.ProfilePageView,
      beforeEnter: middleware(),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: '/organisation/:id',
      name: 'Organisation',
      component: isMobile ? Pages.MobileOrganisationPageView : Pages.OrganisationPageView,
      beforeEnter: middleware({callback: async (payload: MiddlewarePayload) : Promise<MiddlewareResponse> => {
        store.commit("organisations/set", getMembership(payload.params.id)?.org);
        
        if (!(hasOrganisationRole(payload.params.id, UserRole.ADMIN, UserRole.GUEST, UserRole.OWNER, UserRole.MEMBER) || isAdmin())) {
          return { redirect: "/" };
        }

        if (!hasOrganisationRole(payload.params.id, UserRole.GUEST)) {
          await store.dispatch("organisation_users/fetch", {org_id: payload.params.id});
        }

        return {};
      }}),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: Pages.FAQPageView,
      beforeEnter: middleware({auth: false}),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: "/organisation/:orgId/devices/:deviceId",
      name: "Device",
      component: isMobile ? Pages.MobileDevicePageView : Pages.DevicePageView,
      beforeEnter: middleware({callback: async (payload: MiddlewarePayload): Promise<MiddlewareResponse> => {
        if (isAdmin()) {
          store.dispatch("admin_organisations/fetch");
          store.dispatch("admin_firmwares/fetch");
        }

        await store.dispatch("device/fetchDevice", {
          orgId: payload.params.orgId,
          deviceId: payload.params.deviceId
        });
  
        if (isAdmin()) {
          store.dispatch("device/fetchTimestamps");
          store.dispatch("device/fetchLicenseRequests");
          store.dispatch("device/fetchJobs");
          store.dispatch("device/fetchEvents");
        }
      
        return {};
      }}),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: "/admin",
      name: "Admin",
      component: isMobile ? Pages.MobileAdminPageView : Pages.AdminPageView,
      beforeEnter: adminMw(),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: "/admin/organisations",
      name: "Admin",
      component: Pages.AdminOrganisationsPageView,
      beforeEnter: adminMw(),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: "/admin/firmwares",
      name: "Admin",
      component: Pages.AdminFirmwaresPageView,
      beforeEnter: adminMw(),
      props: routeProps({loading: true, sidebar: true, overflow: false})
    },
    {
      path: "/invite/:token",
      name: "Invite",
      component: Pages.InvitationPageView,
      beforeEnter: middleware({callback: async (payload: MiddlewarePayload): Promise<MiddlewareResponse> => {
        await store.dispatch("auth/getCurrentUser");
        await store.dispatch("invite/check", {token: payload.params.token});
        const state = store.state as any;
        const auth_username = (state.auth.user?.username ?? "").trim();
        const invitation_username = (state.invite.email ?? "").trim();

        if (auth_username.length != 0 && auth_username != invitation_username) await store.dispatch("auth/logout");
        if (!state.invite.hasInvitation) return { redirect: "/" };
        if (state.invite.status == "pending") return { redirect: "/map" };

        return {};
      }, auth: false}),
      props: routeProps({sidebar: false, loading: false, overflow: false})
    },
    {
      path: "*", 
      component: isMobile ? Pages.MobilePageNotFoundView : Pages.PageNotFoundView,
      beforeEnter: middleware({auth: false}),
      props: routeProps({sidebar: false, loading: false, overflow: false})
    }
  ]
});

export default router;