import FirmwareModel from "@/models/FirmwareModel";
import { emptyString, searchItems } from "./Search";

export interface AdmFirmwareSearchFilters {
    name: string,
    version: string,
    board: string
}

export default searchItems<FirmwareModel, AdmFirmwareSearchFilters>(new Map([
    ["name", {
        default: emptyString,
        validation: (val: string, item: FirmwareModel): boolean => {
            return val.trim() != "" && !item.name.trim().includes(val);
        }
    }],
    ["version", {
        default: emptyString,
        validation: (val: string, item: FirmwareModel): boolean => {
            return val.trim() != "" && !item.version.includes(val);
        }
    }],
    ["board", {
        default: emptyString,
        validation: (val: string, item: FirmwareModel): boolean => {
            return val.trim() != "" && item.board != val;
        }
    }]
]));