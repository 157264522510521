
import { Component } from "vue-property-decorator";
import { MODAL_USER_INVITATION } from "@/Data";
import { RootModal, ModalSetup, ModalType } from "@/components";

@Component
export default class UserInvitationModalView extends RootModal {

    override setup(): ModalSetup {
        return {
            name: MODAL_USER_INVITATION,
            type: ModalType.VIEW_ONLY
        };
    }

    override onClose() {
      this.$store.commit("invite/clear");
    }
}
