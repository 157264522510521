
import { Component } from "vue-property-decorator";
import { RootView, Branding, } from "@/components";

import UserMenu from '@/views/UserMenu.vue';

@Component({
    components: {
        Branding,
        UserMenu
    }
})
export default class Navbar extends RootView {
    get extraClass() {
        if (this.isMobile) {
            return "pt-3 pb-3";
        }
        return "pt-1 pb-1"
    }

}
