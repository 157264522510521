
import { Vue, Prop, Component } from "vue-property-decorator";
import CountryFlag from "vue-country-flag";

@Component({
    components: {
        CountryFlag
    }
})
export default class CountryIcon extends Vue {
    @Prop({default: ""}) countryId!: string;
}
