
import { Vue, Component, Prop } from "vue-property-decorator";
import Branding from "@/components/Branding.vue";

@Component({
    components: {
        Branding
    }
})
export default class LoginBoxLayout extends Vue {}
