
import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { genUUID } from "@/Utils";
import Mark, { MarkOptions }  from "mark.js";

@Component
export default class Markable extends Vue {
    @Prop({default: false}) active!: boolean;
    @Prop({default: () => {return undefined}}) options!: MarkOptions | undefined;
    @Prop({default: ""}) text!: string;

    instance!: Mark | undefined;
    id = ""

    render(h: any) {
        const children = this.$slots.default || [];

        if (children.length === 1) {
        // If there's only one child, apply the ID directly to it
            return h(
                children[0].tag,
                {
                ...children[0].data,
                attrs: { ...children[0].data?.attrs, id: this.id }
                },
                children[0].children
            );
        }

        return children;
    }

    beforeMount() {
        this.id = `markable-${genUUID()}`;
    }

    mounted() {
        if (!this.active) return;
        this.instance = new Mark(document.getElementById(this.id)!);
        if (this.text.trim().length > 0) {
            this.$nextTick(() => {
                this.instance?.mark(this.text, this.options);
            })
        }
    }

    @Watch("active") 
    onActiveChanged(newValue: boolean, oldValue: boolean) {
        if (newValue == oldValue) return;
        if (newValue) {
            this.instance = new Mark(document.getElementById(this.id)!);
            return;
        }

        if (!this.instance) return;
        this.$nextTick(() => {
            this.instance?.unmark();
            this.instance = undefined;
        })
    }

    @Watch("text")
    onTextChanged(newValue: string, oldValue: string) {
        if (newValue == oldValue) return;
        this.$nextTick(() => {
            this.instance?.unmark();
            console.log(this.options);
            this.instance?.mark(newValue, this.options);
        })
    }
}
