
import { Component } from "vue-property-decorator";

import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts"
import { MODAL_EDIT_DEVICE_LABEL } from "@/Data";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class EditDeviceLabelModalView extends RootView {
    inputLabel = "";

    get options(): ModalOptions {
        return {
            modal: MODAL_EDIT_DEVICE_LABEL,
            title: "modal_editdevice_title",
            desc: "modal_editdevice_desc",
            onOpen: this.onOpen,
            action: this.process
        }
    }

    onOpen() {
        this.inputLabel = this.label ?? "";
    }

    process(): Promise<any> {
        return this.$store.dispatch("device/updateLocal", {
            type: "label",
            content: this.inputLabel.trim(),
        });
    }
}
