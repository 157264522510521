
import { Component } from "vue-property-decorator";

import { RootView, MissingData, Accordion } from "@/components";
import { MobileListLayout, ListHeaderLayout, CenterLayout, ListTooltipLayout, MobileRowLayout } from '@/components/layouts';
import { Dropdown, UserOrganisationRoleDropdown } from "@/components/dropdowns";
import { SearchbarField, CheckboxField } from "@/components/fields";
import { PlusButton } from "@/components/buttons";
import { RoleTag } from "@/components/tags";

import BaseOrganisationUsersView from "./BaseOrganisationUsersView";

@Component({
    components: {
        MissingData, MobileListLayout, ListHeaderLayout, CheckboxField,
        Accordion, ListTooltipLayout, Dropdown,
        SearchbarField, PlusButton, CenterLayout,
        UserOrganisationRoleDropdown, RoleTag, MobileRowLayout
    }
})
export default class OrganisationUsersView extends BaseOrganisationUsersView {}
