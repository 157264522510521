
import { Vue, Component, Prop } from "vue-property-decorator";
import { Buttons } from "@/components";

@Component({
    components: {
        BackButton: Buttons.BackButton
    }
})
export default class AccessForbiddenView extends Vue {

    @Prop({default: ""}) subtitle!: string;

    get subtitleText() {
        return `page_access_forbidden_${this.subtitle}_subtitle`;
    }

}
