
import { Prop, Component, Watch } from "vue-property-decorator";
import RootView from "../RootView";

@Component
export default class TabLayout extends RootView {
    @Prop({default: () => {return {}}}) options!: any[];
    tab = 0;

    setTab(tab: string) {
        const keys = Object.keys(this.options);
        for (let i = 0; i < keys.length; i++) {
            if (tab == keys[i]) {
                this.tab = i;
                return;
            }
        }
    }

    tabIndex(tab: string): number {
        const keys = Object.keys(this.options);
        for (let i = 0; i < keys.length; i++) {
            if (tab == keys[i]) {
                return i;
            }
        }
        return -1;
    }

    isCurrentTab(tab: string): boolean {
        return this.tab == this.tabIndex(tab);
    }

    @Watch("tab")
    onTabChanged(tab: number) {
        if (tab < 0 || tab >= Object.keys(this.options).length) return;
        this.$emit("change", Object.keys(this.options)[tab]);
    }
}
