import { Command } from "./Utils";
import OrganisationModel from "./models/OrganisationModel";

export const ADMIN_OP_CREATE = "create";
export const ADMIN_OP_LICENSE = "license";
export const ADMIN_OP_FIRMWARE = "firmware";
export const ADMIN_OP_DESIREDCOORDS = "desiredcoords";
export const ADMIN_OP_ASSIGNMENT = "assignement";
export const ADMIN_OP_TAGS = "tags";
export const ADMIN_OP_SYNC = "sync";

export const CODE_UNAUTHORIZED = 403;
export const CODE_VALID = 1;
export const CODE_INVALID = 0;

export const DAILY_TAG = "daily";
export const ACTION_ADMIN_SEARCH_DEVICES = "admin_devices/search";
export const ACTION_ADMIN_FILTERS = "admin_devices/setFilter";

export enum UserRole {
    OWNER="OWNER",
    ADMIN="ADMIN",
    MEMBER="MEMBER",
    GUEST="GUEST",
    SUPERADMIN="SUPERADMIN"
}

export type UserMemberships = Map<string, UserRole>

export enum InternalRole {
    DEFAULT = "DEFAULT",
    ADMIN = "ADMIN",
    TECH = "TECH"
}

export interface User {
    username: string,
    id: string,
    tokens: {
        IdToken: string,
        AccessToken: string,
        RefreshToken: string
    },
    organisations: OrganisationModel[],
    memberships: UserMemberships 
    attributes: any,
    internals: InternalRole[]
}

export enum AuthRequestStatus {
    SUCCESS,
    FAILURE,
    NEW_PASSWORD    
}

export interface AuthRequest {
    status: AuthRequestStatus,
    data: any
}

export enum ADMIN_COMMANDS {
  FIRMWARE = "firmware",
  LICENSE = "license",
  ASSIGNMENT = "assignment",
  SYNC = "sync",
  TAGS = "tags",
  STORAGE = "storage",
  DAILY = "daily",
  LABEL = "label",
  COUNTRY = "country",
}

export const KEYWORD_NO_ORG = "NO_ASSIGNMENT";

export const QUERY_PARAM_TAB = "tab";
export const QUERY_PARAM_DAILY = "daily";
export const QUERY_PARAM_LICENSE_EXPIRED = "license_expired";
export const QUERY_PARAM_TRANSDUCER_DISCONNECTED = "transducer_disconnected";
export const QUERY_PARAM_SORT = "sort";
export const QUERY_PARAM_FIRMWARE = "fw";
export const QUERY_PARAM_LABEL = "label";
export const QUERY_PARAM_SN = "sn";
export const QUERY_PARAM_IMEI = "imei";
export const QUERY_PARAM_TAGS = "tags";
export const QUERY_PARAM_LAST_UPDATE_TYPE = "last_update_type";
export const QUERY_PARAM_TREATMENT = "treatment";
export const QUERY_PARAM_ORG = "org";
export const QUERY_PARAM_BOARD = "board";

export const QUERY_PARAMS = {
  QUERY_PARAM_TAB,
  QUERY_PARAM_DAILY,
  QUERY_PARAM_LICENSE_EXPIRED,
  QUERY_PARAM_TRANSDUCER_DISCONNECTED,
  QUERY_PARAM_SORT,
  QUERY_PARAM_FIRMWARE,
  QUERY_PARAM_LABEL,
  QUERY_PARAM_SN,
  QUERY_PARAM_IMEI,
  QUERY_PARAM_TAGS,
  QUERY_PARAM_LAST_UPDATE_TYPE,
  QUERY_PARAM_TREATMENT,
  QUERY_PARAM_ORG,
  QUERY_PARAM_BOARD
};

export const SORT_ASC = "asc";
export const SORT_DESC = "desc";

export const UPDATE_TYPES: string[] = ["unknown", "mobile", "iot", "admin", "web"];
export const TREATMENT_TYPES: string[] = ["treatment_on", "treatment_off", "treatment_in_transition", "in_storage"];
export const ORGANISATION_ROLES: string[] = ["guest", "member", "admin", "owner"];

export const MODAL_EDIT_DEVICE_LABEL = "modal_edit_device_label";
export const MODAL_STORAGE = "modal_storage";
export const MODAL_LOGOUT = "modal_logout";
export const MODAL_TREATMENT = "modal_treatment";
export const MODAL_EDIT_PROFILE = "modal_edit_profile";
export const MODAL_EDIT_PASSWORD = "modal_edit_password";
export const MODAL_SIGNUP = "modal_signup";

export const MODAL_ADMIN_SYNC = "modal_admin_sync";
export const MODAL_ADMIN_STORAGE = "modal_admin_storage";
export const MODAL_ADMIN_UPDATE_TAGS = "modal_admin_update_tags";
export const MODAL_ADMIN_UPDATE_LICENSE = "modal_admin_update_license";
export const MODAL_ADMIN_UPDATE_COORDINATES = "modal_admin_update_coordinates";
export const MODAL_ADMIN_CREATE_FW = "modal_admin_create_fw";
export const MODAL_ADMIN_UPDATE_COUNTRY = "modal_admin_update_country";
export const MODAL_ADMIN_UPDATE_FW = "modal_admin_update_fw";
export const MODAL_ADMIN_ASSIGN_ORG = "modal_admin_assign_org";
export const MODAL_ADMIN_DISABLE = "modal_admin_disable";
export const MODAL_ADMIN_ASSIGN_FW = "modal_admin_assign_fw";
export const MODAL_ADMIN_VIEW_FW = "modal_admin_view_fw";
export const MODAL_ADMIN_CREATE_ORG = "modal_admin_create_org";
export const MODAL_ADMIN_DELETE_ORG = "modal_admin_delete_org";
export const MODAL_ADMIN_OP_SUCCESS = "modal_op_success";
export const MODAL_ADMIN_CREATE_EMPTY = "modal_admin_create_empty";
export const MODAL_ADMIN_CANCEL_JOB = "modal_admin_cancel_job";
export const MODAL_ADMIN_GEN_LIC = "modal_admin_gen_lic";
export const MODAL_ADMIN_REINSTATE_LIC = "modal_admin_reinstate_lic";
export const MODAL_ADMIN_SET_DEVICE_DAILY = "modal_admin_set_device_daily";
export const MODAL_ADMIN_MANAGE_DAILY_EMAIL = "modal_admin_manage_daily_email";

export const MODAL_USER_INVITATION = "modal_user_invitation";
export const MODAL_USER_INVITATION_FAILED = "modal_user_invitation_failed";
export const MODAL_USER_INVITATION_ACCEPT = "modal_user_invitation_accept";
export const MODAL_USER_INVITATION_REFUSE = "modal_user_invitation_refuse";

export const MODAL_EDIT_ORGANISATION_USER = "modal_edit_organisation_user";
export const MODAL_EDIT_ORGANISATION = "modal_edit_organisation";
export const MODAL_INVITE_ORGANISATION = "modal_invite_organisation";
export const MODAL_KICK_ORGANISATION_USER = "modal_kick_organisation_user";
export const MODAL_LEAVE_ORGANISATION = "modal_leave_organisation";

export const MODAL_ORGANISATION_STORAGE = "modal_organisation_storage";
export const MODAL_ORGANISATION_TREATMENT = "modal_organisation_treatment";

export const EVENTS: Command[] = [
    {
      id: "USER_ACTIVATE",
      name: "USER_ACTIVATE"
    },
    {
      id: "USER_DEACTIVATE",
      name: "USER_DEACTIVATE"
    },
    {
      id: "DEVICE_ACTIVE",
      name: "DEVICE_ACTIVE"
    },
    {
      id: "DEVICE_INACTIVE",
      name: "DEVICE_INACTIVE"
    },
    {
      id: "USER_LABEL_UPDATE",
      name: "USER_LABEL_UPDATE"
    },
    {
      id: "CHANGE_TAGS",
      name: "CHANGE_TAGS"
    },
    {
      id: "DISABLE",
      name: "DISABLE"
    },
    {
      id: "DEVICE_ORGANISATION_CHANGE",
      name: "DEVICE_ORGANISATION_CHANGE"
    },
    {
      id: "MANUAL_LICENSE",
      name: "MANUAL_LICENSE"
    },
    {
      id: "LICENSE_CFG_UPDATE",
      name: "LICENSE_CFG_UPDATE"
    },
    {
      id: "LICENSE_UPDATE",
      name: "LICENSE_UPDATE"
    },
    {
      id: "NEW_LICENSE_REQ",
      name: "NEW_LICENSE_REQ"
    },
    {
      id: "FW_UPDATE",
      name: "FW_UPDATE"
    },
    {
      id: "REMOTE_FW_UPDATE_REQ",
      name: "REMOTE_FW_UPDATE_REQ"
    },
    {
      id: "REMOTE_FW_UPDATE_FAIL",
      name: "REMOTE_FW_UPDATE_FAIL"
    },
    {
      id: "MANUAL_FW_UPDATE",
      name: "MANUAL_FW_UPDATE"
    },
    {
      id: "CLOUD_CONNECT",
      name: "CLOUD_CONNECT"
    },
    {
      id: "CLOUD_DISCONNECT",
      name: "CLOUD_DISCONNECT"
    },
    {
      id: "TRANSDUCER_PAIRING",
      name: "TRANSDUCER_PAIRING"
    },
    {
      id: "TRANSDUCER_PAIRED",
      name: "TRANSDUCER_PAIRED"
    },
    {
      id: "TRANSDUCER_PAIRING_FAILED",
      name: "TRANSDUCER_PAIRING_FAILED"
    },
    {
      id: "TRANSDUCER_DISCONNECTED",
      name: "TRANSDUCER_DISCONNECTED"
    },
    {
      id: "TRANSDUCER_CONNECTED",
      name: "TRANSDUCER_CONNECTED"
    },
    {
      id: "TRANSDUCER_DRIVER_0_ON",
      name: "TRANSDUCER_DRIVER_0_ON"
    },
    {
      id: "TRANSDUCER_DRIVER_0_OFF",
      name: "TRANSDUCER_DRIVER_0_OFF"
    },
    {
      id: "TRANSDUCER_DRIVER_1_ON",
      name: "TRANSDUCER_DRIVER_1_ON"
    },
    {
      id: "TRANSDUCER_DRIVER_1_OFF",
      name: "TRANSDUCER_DRIVER_1_OFF"
    },
    {
      id: "TRANSDUCER_IN_WATER",
      name: "TRANSDUCER_IN_WATER"
    },
    {
      id: "TRANSDUCER_OUT_WATER",
      name: "TRANSDUCER_OUT_WATER"
    },
    {
      id: "TRANSDUCER_FW_UPD",
      name: "TRANSDUCER_FW_UPD"
    },
    {
      id: "GNSS_FIX",
      name: "GNSS_FIX"
    },
    {
      id: "DEPLOYED",
      name: "DEPLOYED"
    },
    {
      id: "IN_STORAGE",
      name: "IN_STORAGE"
    },
    {
      id: "OUT_OF_STORAGE",
      name: "OUT_OF_STORAGE"
    },
    {
      id: "MANUAL_OUT_OF_STORAGE",
      name: "MANUAL_OUT_OF_STORAGE"
    },
    {
      id: "LTE_CONNECT",
      name: "LTE_CONNECT"
    },
    {
      id: "LTE_DISCONNECT",
      name: "LTE_DISCONNECT"
    },
    {
      id: "BATTERY_LOW",
      name: "BATTERY_LOW"
    },
    {
      id: "MOBILE_CONNECTION",
      name: "MOBILE_CONNECTION"
    },
    {
      id: "GEOFENCE_IN",
      name: "GEOFENCE_IN"
    },
    {
      id: "GEOFENCE_OUT",
      name: "GEOFENCE_OUT"
    }
];

export const SYSTEM_TAG = "___SYSTEM";
export const SYSTEM_TAG_IGNORE_LICENSE = "___SYSTEM_IGNORE_LICENSE";

export const SYSTEM_TAGS = [
  SYSTEM_TAG_IGNORE_LICENSE
]

export enum LastUpdateLengthType {
  NONE = "NONE",
  STORAGE = "STORAGE",
  GOOD = "GOOD",
  POOR = "POOR",
  DISCONNECTED = "DISCONNECTED"
}

export enum JobStatusRange {
    QUEUED = "queued",
    REJECTED = "rejected",
    REMOVED = "removed",
    TIMED_OUT = "timed_out",
    FAILED = "failed",
    IN_PROGRESS = "in_progress",
    CANCELED = "canceled",
    SUCCEEDED = "succeeded",
    COMPLETED = "completed",
    DELETED = "deleted",
    CANCELLATION_IN_PROGRESS = "cancellation_in_progress",
    DELETION_IN_PROGRESS = "deletion_in_progress"
}

export enum INVITATION_RESPONSE {
  ACCEPT = "accept",
  REFUSE = "refuse"
}

export const getJobStatusFromStr = (str: string): JobStatusRange => {
    switch (str) {
        case "queued":
            return JobStatusRange.QUEUED;
        case "rejected":
            return JobStatusRange.REJECTED;
        case "removed":
            return JobStatusRange.REMOVED;
        case "timed_out":
            return JobStatusRange.TIMED_OUT;
        case "failed":
            return JobStatusRange.FAILED;
        case "canceled":
            return JobStatusRange.CANCELED;
        case "succeeded":
            return JobStatusRange.SUCCEEDED;
        case "completed":
            return JobStatusRange.COMPLETED;
        case "deleted":
            return JobStatusRange.DELETED;
        case "cancellation_in_progress":
            return JobStatusRange.CANCELLATION_IN_PROGRESS;
        case "deletion_in_progress":
            return JobStatusRange.DELETION_IN_PROGRESS;
        case "in_progress":
        default:
            return JobStatusRange.IN_PROGRESS;
    }
}

export enum PASSWORD_REQUIREMENT {
  LENGTH = "password_rule_min_char",
  CAPITAL = "password_rule_uppercase",
  NUMBER = "password_rule_number",
  SPECIAL = "password_rule_special"
}