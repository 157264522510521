import countries from "i18n-iso-countries";
import Vue from "vue";

export interface Territory {
    id: string,
    tag: string
}

// Only for admin side when loading to avoid waiting for the library
export const COUNTRIES_EN: Territory[] = [
    {"id":"AF","tag":"Afghanistan"},
    {"id":"AL","tag":"Albania"},
    {"id":"DZ","tag":"Algeria"},
    {"id":"AS","tag":"American Samoa"},
    {"id":"AD","tag":"Andorra"},
    {"id":"AO","tag":"Angola"},
    {"id":"AI","tag":"Anguilla"},
    {"id":"AQ","tag":"Antarctica"},
    {"id":"AG","tag":"Antigua and Barbuda"},
    {"id":"AR","tag":"Argentina"},
    {"id":"AM","tag":"Armenia"},
    {"id":"AW","tag":"Aruba"},
    {"id":"AU","tag":"Australia"},
    {"id":"AT","tag":"Austria"},
    {"id":"AZ","tag":"Azerbaijan"},
    {"id":"BS","tag":"Bahamas"},
    {"id":"BH","tag":"Bahrain"},
    {"id":"BD","tag":"Bangladesh"},
    {"id":"BB","tag":"Barbados"},
    {"id":"BY","tag":"Belarus"},
    {"id":"BE","tag":"Belgium"},
    {"id":"BZ","tag":"Belize"},
    {"id":"BJ","tag":"Benin"},
    {"id":"BM","tag":"Bermuda"},
    {"id":"BT","tag":"Bhutan"},
    {"id":"BO","tag":"Bolivia"},
    {"id":"BA","tag":"Bosnia and Herzegovina"},
    {"id":"BW","tag":"Botswana"},
    {"id":"BV","tag":"Bouvet Island"},
    {"id":"BR","tag":"Brazil"},
    {"id":"IO","tag":"British Indian Ocean Territory"},
    {"id":"BN","tag":"Brunei Darussalam"},
    {"id":"BG","tag":"Bulgaria"},
    {"id":"BF","tag":"Burkina Faso"},
    {"id":"BI","tag":"Burundi"},
    {"id":"KH","tag":"Cambodia"},
    {"id":"CM","tag":"Cameroon"},
    {"id":"CA","tag":"Canada"},
    {"id":"CV","tag":"Cape Verde"},
    {"id":"KY","tag":"Cayman Islands"},
    {"id":"CF","tag":"Central African Republic"},
    {"id":"TD","tag":"Chad"},
    {"id":"CL","tag":"Chile"},
    {"id":"CN","tag":"People's Republic of China"},
    {"id":"CX","tag":"Christmas Island"},
    {"id":"CC","tag":"Cocos (Keeling) Islands"},
    {"id":"CO","tag":"Colombia"},
    {"id":"KM","tag":"Comoros"},
    {"id":"CG","tag":"Republic of the Congo"},
    {"id":"CD","tag":"Democratic Republic of the Congo"},
    {"id":"CK","tag":"Cook Islands"},
    {"id":"CR","tag":"Costa Rica"},
    {"id":"CI","tag":"Cote d'Ivoire"},
    {"id":"HR","tag":"Croatia"},
    {"id":"CU","tag":"Cuba"},
    {"id":"CY","tag":"Cyprus"},
    {"id":"CZ","tag":"Czech Republic"},
    {"id":"DK","tag":"Denmark"},
    {"id":"DJ","tag":"Djibouti"},
    {"id":"DM","tag":"Dominica"},
    {"id":"DO","tag":"Dominican Republic"},
    {"id":"EC","tag":"Ecuador"},
    {"id":"EG","tag":"Egypt"},
    {"id":"SV","tag":"El Salvador"},
    {"id":"GQ","tag":"Equatorial Guinea"},
    {"id":"ER","tag":"Eritrea"},
    {"id":"EE","tag":"Estonia"},
    {"id":"ET","tag":"Ethiopia"},
    {"id":"FK","tag":"Falkland Islands (Malvinas)"},
    {"id":"FO","tag":"Faroe Islands"},
    {"id":"FJ","tag":"Fiji"},
    {"id":"FI","tag":"Finland"},
    {"id":"FR","tag":"France"},
    {"id":"GF","tag":"French Guiana"},
    {"id":"PF","tag":"French Polynesia"},
    {"id":"TF","tag":"French Southern Territories"},
    {"id":"GA","tag":"Gabon"},
    {"id":"GM","tag":"Republic of The Gambia"},
    {"id":"GE","tag":"Georgia"},
    {"id":"DE","tag":"Germany"},
    {"id":"GH","tag":"Ghana"},
    {"id":"GI","tag":"Gibraltar"},
    {"id":"GR","tag":"Greece"},
    {"id":"GL","tag":"Greenland"},
    {"id":"GD","tag":"Grenada"},
    {"id":"GP","tag":"Guadeloupe"},
    {"id":"GU","tag":"Guam"},
    {"id":"GT","tag":"Guatemala"},
    {"id":"GN","tag":"Guinea"},
    {"id":"GW","tag":"Guinea-Bissau"},
    {"id":"GY","tag":"Guyana"},
    {"id":"HT","tag":"Haiti"},
    {"id":"HM","tag":"Heard Island and McDonald Islands"},
    {"id":"VA","tag":"Holy See (Vatican City State)"},
    {"id":"HN","tag":"Honduras"},
    {"id":"HK","tag":"Hong Kong"},
    {"id":"HU","tag":"Hungary"},
    {"id":"IS","tag":"Iceland"},
    {"id":"IN","tag":"India"},
    {"id":"ID","tag":"Indonesia"},
    {"id":"IR","tag":"Islamic Republic of Iran"},
    {"id":"IQ","tag":"Iraq"},
    {"id":"IE","tag":"Ireland"},
    {"id":"IL","tag":"Israel"},
    {"id":"IT","tag":"Italy"},
    {"id":"JM","tag":"Jamaica"},
    {"id":"JP","tag":"Japan"},
    {"id":"JO","tag":"Jordan"},
    {"id":"KZ","tag":"Kazakhstan"},
    {"id":"KE","tag":"Kenya"},
    {"id":"KI","tag":"Kiribati"},
    {"id":"KP","tag":"North Korea"},
    {"id":"KR","tag":"South Korea"},
    {"id":"KW","tag":"Kuwait"},
    {"id":"KG","tag":"Kyrgyzstan"},
    {"id":"LA","tag":"Lao People's Democratic Republic"},
    {"id":"LV","tag":"Latvia"},
    {"id":"LB","tag":"Lebanon"},
    {"id":"LS","tag":"Lesotho"},
    {"id":"LR","tag":"Liberia"},
    {"id":"LY","tag":"Libya"},
    {"id":"LI","tag":"Liechtenstein"},
    {"id":"LT","tag":"Lithuania"},
    {"id":"LU","tag":"Luxembourg"},
    {"id":"MO","tag":"Macao"},
    {"id":"MG","tag":"Madagascar"},
    {"id":"MW","tag":"Malawi"},
    {"id":"MY","tag":"Malaysia"},
    {"id":"MV","tag":"Maldives"},
    {"id":"ML","tag":"Mali"},
    {"id":"MT","tag":"Malta"},
    {"id":"MH","tag":"Marshall Islands"},
    {"id":"MQ","tag":"Martinique"},
    {"id":"MR","tag":"Mauritania"},
    {"id":"MU","tag":"Mauritius"},
    {"id":"YT","tag":"Mayotte"},
    {"id":"MX","tag":"Mexico"},
    {"id":"FM","tag":"Micronesia, Federated States of"},
    {"id":"MD","tag":"Moldova, Republic of"},
    {"id":"MC","tag":"Monaco"},
    {"id":"MN","tag":"Mongolia"},
    {"id":"MS","tag":"Montserrat"},
    {"id":"MA","tag":"Morocco"},
    {"id":"MZ","tag":"Mozambique"},
    {"id":"MM","tag":"Myanmar"},
    {"id":"NA","tag":"Namibia"},
    {"id":"NR","tag":"Nauru"},
    {"id":"NP","tag":"Nepal"},
    {"id":"NL","tag":"Netherlands"},
    {"id":"NC","tag":"New Caledonia"},
    {"id":"NZ","tag":"New Zealand"},
    {"id":"NI","tag":"Nicaragua"},
    {"id":"NE","tag":"Niger"},
    {"id":"NG","tag":"Nigeria"},
    {"id":"NU","tag":"Niue"},
    {"id":"NF","tag":"Norfolk Island"},
    {"id":"MK","tag":"The Republic of North Macedonia"},
    {"id":"MP","tag":"Northern Mariana Islands"},
    {"id":"NO","tag":"Norway"},
    {"id":"OM","tag":"Oman"},
    {"id":"PK","tag":"Pakistan"},
    {"id":"PW","tag":"Palau"},
    {"id":"PS","tag":"State of Palestine"},
    {"id":"PA","tag":"Panama"},
    {"id":"PG","tag":"Papua New Guinea"},
    {"id":"PY","tag":"Paraguay"},
    {"id":"PE","tag":"Peru"},
    {"id":"PH","tag":"Philippines"},
    {"id":"PN","tag":"Pitcairn"},
    {"id":"PL","tag":"Poland"},
    {"id":"PT","tag":"Portugal"},
    {"id":"PR","tag":"Puerto Rico"},
    {"id":"QA","tag":"Qatar"},
    {"id":"RE","tag":"Reunion"},
    {"id":"RO","tag":"Romania"},
    {"id":"RU","tag":"Russian Federation"},
    {"id":"RW","tag":"Rwanda"},
    {"id":"SH","tag":"Saint Helena"},
    {"id":"KN","tag":"Saint Kitts and Nevis"},
    {"id":"LC","tag":"Saint Lucia"},
    {"id":"PM","tag":"Saint Pierre and Miquelon"},
    {"id":"VC","tag":"Saint Vincent and the Grenadines"},
    {"id":"WS","tag":"Samoa"},
    {"id":"SM","tag":"San Marino"},
    {"id":"ST","tag":"Sao Tome and Principe"},
    {"id":"SA","tag":"Saudi Arabia"},
    {"id":"SN","tag":"Senegal"},
    {"id":"SC","tag":"Seychelles"},
    {"id":"SL","tag":"Sierra Leone"},
    {"id":"SG","tag":"Singapore"},
    {"id":"SK","tag":"Slovakia"},
    {"id":"SI","tag":"Slovenia"},
    {"id":"SB","tag":"Solomon Islands"},
    {"id":"SO","tag":"Somalia"},
    {"id":"ZA","tag":"South Africa"},
    {"id":"GS","tag":"South Georgia and the South Sandwich Islands"},
    {"id":"ES","tag":"Spain"},
    {"id":"LK","tag":"Sri Lanka"},
    {"id":"SD","tag":"Sudan"},
    {"id":"SR","tag":"Suriname"},
    {"id":"SJ","tag":"Svalbard and Jan Mayen"},
    {"id":"SZ","tag":"Eswatini"},
    {"id":"SE","tag":"Sweden"},
    {"id":"CH","tag":"Switzerland"},
    {"id":"SY","tag":"Syrian Arab Republic"},
    {"id":"TW","tag":"Taiwan, Province of China"},
    {"id":"TJ","tag":"Tajikistan"},
    {"id":"TZ","tag":"United Republic of Tanzania"},
    {"id":"TH","tag":"Thailand"},
    {"id":"TL","tag":"Timor-Leste"},
    {"id":"TG","tag":"Togo"},
    {"id":"TK","tag":"Tokelau"},
    {"id":"TO","tag":"Tonga"},
    {"id":"TT","tag":"Trinidad and Tobago"},
    {"id":"TN","tag":"Tunisia"},
    {"id":"TR","tag":"Türkiye"},
    {"id":"TM","tag":"Turkmenistan"},
    {"id":"TC","tag":"Turks and Caicos Islands"},
    {"id":"TV","tag":"Tuvalu"},
    {"id":"UG","tag":"Uganda"},
    {"id":"UA","tag":"Ukraine"},
    {"id":"AE","tag":"United Arab Emirates"},
    {"id":"GB","tag":"United Kingdom"},
    {"id":"US","tag":"United States of America"},
    {"id":"UM","tag":"United States Minor Outlying Islands"},
    {"id":"UY","tag":"Uruguay"},
    {"id":"UZ","tag":"Uzbekistan"},
    {"id":"VU","tag":"Vanuatu"},
    {"id":"VE","tag":"Venezuela"},
    {"id":"VN","tag":"Vietnam"},
    {"id":"VG","tag":"Virgin Islands, British"},
    {"id":"VI","tag":"Virgin Islands, U.S."},
    {"id":"WF","tag":"Wallis and Futuna"},
    {"id":"EH","tag":"Western Sahara"},
    {"id":"YE","tag":"Yemen"},
    {"id":"ZM","tag":"Zambia"},
    {"id":"ZW","tag":"Zimbabwe"},
    {"id":"AX","tag":"Åland Islands"},
    {"id":"BQ","tag":"Bonaire, Sint Eustatius and Saba"},
    {"id":"CW","tag":"Curaçao"},
    {"id":"GG","tag":"Guernsey"},
    {"id":"IM","tag":"Isle of Man"},
    {"id":"JE","tag":"Jersey"},
    {"id":"ME","tag":"Montenegro"},
    {"id":"BL","tag":"Saint Barthélemy"},
    {"id":"MF","tag":"Saint Martin (French part)"},
    {"id":"RS","tag":"Serbia"},
    {"id":"SX","tag":"Sint Maarten (Dutch part)"},
    {"id":"SS","tag":"South Sudan"},
    {"id":"XK","tag":"Kosovo"}
];

const USA_STATES: Territory[] = [
    { id: 'AL', tag: 'Alabama' },
    { id: 'AK', tag: 'Alaska' },
    { id: 'AZ', tag: 'Arizona' },
    { id: 'AR', tag: 'Arkansas' },
    { id: 'CA', tag: 'California' },
    { id: 'CO', tag: 'Colorado' },
    { id: 'CT', tag: 'Connecticut' },
    { id: 'DE', tag: 'Delaware' },
    { id: 'FL', tag: 'Florida' },
    { id: 'GA', tag: 'Georgia' },
    { id: 'HI', tag: 'Hawaii' },
    { id: 'ID', tag: 'Idaho' },
    { id: 'IL', tag: 'Illinois' },
    { id: 'IN', tag: 'Indiana' },
    { id: 'IA', tag: 'Iowa' },
    { id: 'KS', tag: 'Kansas' },
    { id: 'KY', tag: 'Kentucky' },
    { id: 'LA', tag: 'Louisiana' },
    { id: 'ME', tag: 'Maine' },
    { id: 'MD', tag: 'Maryland' },
    { id: 'MA', tag: 'Massachusetts' },
    { id: 'MI', tag: 'Michigan' },
    { id: 'MN', tag: 'Minnesota' },
    { id: 'MS', tag: 'Mississippi' },
    { id: 'MO', tag: 'Missouri' },
    { id: 'MT', tag: 'Montana' },
    { id: 'NE', tag: 'Nebraska' },
    { id: 'NV', tag: 'Nevada' },
    { id: 'NH', tag: 'New Hampshire' },
    { id: 'NJ', tag: 'New Jersey' },
    { id: 'NM', tag: 'New Mexico' },
    { id: 'NY', tag: 'New York' },
    { id: 'NC', tag: 'North Carolina' },
    { id: 'ND', tag: 'North Dakota' },
    { id: 'OH', tag: 'Ohio' },
    { id: 'OK', tag: 'Oklahoma' },
    { id: 'OR', tag: 'Oregon' },
    { id: 'PA', tag: 'Pennsylvania' },
    { id: 'RI', tag: 'Rhode Island' },
    { id: 'SC', tag: 'South Carolina' },
    { id: 'SD', tag: 'South Dakota' },
    { id: 'TN', tag: 'Tennessee' },
    { id: 'TX', tag: 'Texas' },
    { id: 'UT', tag: 'Utah' },
    { id: 'VT', tag: 'Vermont' },
    { id: 'VA', tag: 'Virginia' },
    { id: 'WA', tag: 'Washington' },
    { id: 'WV', tag: 'West Virginia' },
    { id: 'WI', tag: 'Wisconsin' },
    { id: 'WY', tag: 'Wyoming' },
    { id: 'DC', tag: 'District of Columbia' },
    { id: 'AS', tag: 'American Samoa' },
    { id: 'GU', tag: 'Guam' },
    { id: 'MP', tag: 'Northern Mariana Islands' },
    { id: 'PR', tag: 'Puerto Rico' },
    { id: 'VI', tag: 'U.S. Virgin Islands' }
];

const CANADA_PROVINCES: Territory[] = [
    { id: 'AB', tag: 'Alberta' },
    { id: 'BC', tag: 'British Columbia' },
    { id: 'MB', tag: 'Manitoba' },
    { id: 'NB', tag: 'New Brunswick' },
    { id: 'NL', tag: 'Newfoundland and Labrador' },
    { id: 'NS', tag: 'Nova Scotia' },
    { id: 'ON', tag: 'Ontario' },
    { id: 'PE', tag: 'Prince Edward Island' },
    { id: 'QC', tag: 'Quebec' },
    { id: 'SK', tag: 'Saskatchewan' },
    { id: 'NT', tag: 'Northwest Territories' },
    { id: 'NU', tag: 'Nunavut' },
    { id: 'YT', tag: 'Yukon' }
];

const MEXICO_STATES: Territory[] = [
    { id: 'AGU', tag: 'Aguascalientes' },
    { id: 'BCN', tag: 'Baja California' },
    { id: 'BCS', tag: 'Baja California Sur' },
    { id: 'CAM', tag: 'Campeche' },
    { id: 'CHI', tag: 'Chiapas' },
    { id: 'CHH', tag: 'Chihuahua' },
    { id: 'COA', tag: 'Coahuila' },
    { id: 'COL', tag: 'Colima' },
    { id: 'DIF', tag: 'Mexico City' },
    { id: 'DUR', tag: 'Durango' },
    { id: 'GUA', tag: 'Guanajuato' },
    { id: 'GRO', tag: 'Guerrero' },
    { id: 'HID', tag: 'Hidalgo' },
    { id: 'JAL', tag: 'Jalisco' },
    { id: 'MEX', tag: 'State of Mexico' },
    { id: 'MIC', tag: 'Michoacán' },
    { id: 'Mor', tag: 'Morelos' },
    { id: 'NAY', tag: 'Nayarit' },
    { id: 'NL', tag: 'Nuevo León' },
    { id: 'OAX', tag: 'Oaxaca' },
    { id: 'PUE', tag: 'Puebla' },
    { id: 'QUE', tag: 'Querétaro' },
    { id: 'ROO', tag: 'Quintana Roo' },
    { id: 'SAN', tag: 'San Luis Potosí' },
    { id: 'SIE', tag: 'Sinaloa' },
    { id: 'SON', tag: 'Sonora' },
    { id: 'TAB', tag: 'Tabasco' },
    { id: 'TAM', tag: 'Tamaulipas' },
    { id: 'TLX', tag: 'Tlaxcala' },
    { id: 'VER', tag: 'Veracruz' },
    { id: 'YUC', tag: 'Yucatán' },
    { id: 'ZAC', tag: 'Zacatecas' },
    { id: 'CMX', tag: 'Mexico City (CDMX)' } // Additional territory for Mexico City
];

const AUSTRALIA_STATES: Territory[] = [
    { id: 'ACT', tag: 'Australian Capital Territory' },
    { id: 'NSW', tag: 'New South Wales' },
    { id: 'NT', tag: 'Northern Territory' },
    { id: 'QLD', tag: 'Queensland' },
    { id: 'SA', tag: 'South Australia' },
    { id: 'TAS', tag: 'Tasmania' },
    { id: 'VIC', tag: 'Victoria' },
    { id: 'WA', tag: 'Western Australia' }
  ];

  const BRAZIL_STATES: Territory[] = [
    { id: 'AC', tag: 'Acre' },
    { id: 'AL', tag: 'Alagoas' },
    { id: 'AP', tag: 'Amapá' },
    { id: 'AM', tag: 'Amazonas' },
    { id: 'BA', tag: 'Bahia' },
    { id: 'CE', tag: 'Ceará' },
    { id: 'DF', tag: 'Distrito Federal' },
    { id: 'ES', tag: 'Espírito Santo' },
    { id: 'GO', tag: 'Goiás' },
    { id: 'MA', tag: 'Maranhão' },
    { id: 'MT', tag: 'Mato Grosso' },
    { id: 'MS', tag: 'Mato Grosso do Sul' },
    { id: 'MG', tag: 'Minas Gerais' },
    { id: 'PA', tag: 'Pará' },
    { id: 'PB', tag: 'Paraíba' },
    { id: 'PR', tag: 'Paraná' },
    { id: 'PE', tag: 'Pernambuco' },
    { id: 'PI', tag: 'Piauí' },
    { id: 'RJ', tag: 'Rio de Janeiro' },
    { id: 'RN', tag: 'Rio Grande do Norte' },
    { id: 'RS', tag: 'Rio Grande do Sul' },
    { id: 'RO', tag: 'Rondônia' },
    { id: 'RR', tag: 'Roraima' },
    { id: 'SC', tag: 'Santa Catarina' },
    { id: 'SP', tag: 'São Paulo' },
    { id: 'SE', tag: 'Sergipe' },
    { id: 'TO', tag: 'Tocantins' }
  ];

  const CHINA_PROVINCES: Territory[] = [
    { id: 'ANH', tag: 'Anhui / 安徽' },
    { id: 'BJ', tag: 'Beijing / 北京' },
    { id: 'FJ', tag: 'Fujian / 福建' },
    { id: 'GD', tag: 'Guangdong / 广东' },
    { id: 'GX', tag: 'Guangxi / 广西' },
    { id: 'GZ', tag: 'Guizhou / 贵州' },
    { id: 'HA', tag: 'Henan / 河南' },
    { id: 'HB', tag: 'Hubei / 湖北' },
    { id: 'HN', tag: 'Hunan / 湖南' },
    { id: 'JL', tag: 'Jilin / 吉林' },
    { id: 'JS', tag: 'Jiangsu / 江苏' },
    { id: 'JX', tag: 'Jiangxi / 江西' },
    { id: 'LN', tag: 'Liaoning / 辽宁' },
    { id: 'NX', tag: 'Ningxia / 宁夏' },
    { id: 'QH', tag: 'Qinghai / 青海' },
    { id: 'SC', tag: 'Sichuan / 四川' },
    { id: 'SD', tag: 'Shandong / 山东' },
    { id: 'SH', tag: 'Shanghai / 上海' },
    { id: 'SN', tag: 'Shaanxi / 陕西' },
    { id: 'SX', tag: 'Shanxi / 山西' },
    { id: 'TJ', tag: 'Tianjin / 天津' },
    { id: 'XZ', tag: 'Tibet / 西藏' },
    { id: 'YN', tag: 'Yunnan / 云南' },
    { id: 'ZJ', tag: 'Zhejiang / 浙江' },
    { id: 'HAI', tag: 'Hainan / 海南' },
    { id: 'HK', tag: 'Hong Kong / 香港' },
    { id: 'MO', tag: 'Macau / 澳门' },
    { id: 'TW', tag: 'Taiwan / 台湾' } // Note: Taiwan is considered a province by China
  ];

  const MALAYSIA_STATES: Territory[] = [
    { id: 'JH', tag: 'Johor' },
    { id: 'KD', tag: 'Kedah' },
    { id: 'KL', tag: 'Kuala Lumpur' },
    { id: 'KT', tag: 'Kelantan' },
    { id: 'MB', tag: 'Malacca' },
    { id: 'NS', tag: 'Negeri Sembilan' },
    { id: 'PA', tag: 'Pahang' },
    { id: 'PK', tag: 'Perak' },
    { id: 'PL', tag: 'Perlis' },
    { id: 'PG', tag: 'Penang' },
    { id: 'SG', tag: 'Sabah' },
    { id: 'SR', tag: 'Sarawak' },
    { id: 'SL', tag: 'Selangor' },
    { id: 'TR', tag: 'Terengganu' },
    { id: 'WL', tag: 'Labuan' },
    { id: 'PJ', tag: 'Putrajaya' } // Federal Territory
  ];

  const SOUTHKOREA_PROVINCES: Territory[] = [
    { id: 'BG', tag: 'Busan / 부산' },
    { id: 'CG', tag: 'Chungcheongbuk-do / 충청북도' },
    { id: 'CJ', tag: 'Chungcheongnam-do / 충청남도' },
    { id: 'GB', tag: 'Gyeongbuk-do / 경상북도' },
    { id: 'GC', tag: 'Gyeonggi-do / 경기도' },
    { id: 'GN', tag: 'Gyeongnam-do / 경상남도' },
    { id: 'JE', tag: 'Jeju-do / 제주도' },
    { id: 'JM', tag: 'Jeollabuk-do / 전라북도' },
    { id: 'JN', tag: 'Jeollanam-do / 전라남도' },
    { id: 'SE', tag: 'Seoul / 서울' },
    { id: 'SG', tag: 'Suwon / 수원' },
    { id: 'IN', tag: 'Incheon / 인천' },
    { id: 'DA', tag: 'Daejeon / 대전' },
    { id: 'GW', tag: 'Gwangju / 광주' },
    { id: 'UL', tag: 'Ulsan / 울산' }
  ];
  
  const JAPAN_PREFECTURES: Territory[] = [
    { id: '01', tag: 'Hokkaido / 北海道' },
    { id: '02', tag: 'Aomori / 青森県' },
    { id: '03', tag: 'Iwate / 岩手県' },
    { id: '04', tag: 'Miyagi / 宮城県' },
    { id: '05', tag: 'Akita / 秋田県' },
    { id: '06', tag: 'Yamagata / 山形県' },
    { id: '07', tag: 'Fukushima / 福島県' },
    { id: '08', tag: 'Ibaraki / 茨城県' },
    { id: '09', tag: 'Tochigi / 栃木県' },
    { id: '10', tag: 'Gunma / 群馬県' },
    { id: '11', tag: 'Saitama / 埼玉県' },
    { id: '12', tag: 'Chiba / 千葉県' },
    { id: '13', tag: 'Tokyo / 東京都' },
    { id: '14', tag: 'Kanagawa / 神奈川県' },
    { id: '15', tag: 'Niigata / 新潟県' },
    { id: '16', tag: 'Toyama / 富山県' },
    { id: '17', tag: 'Ishikawa / 石川県' },
    { id: '18', tag: 'Fukui / 福井県' },
    { id: '19', tag: 'Yamanashi / 山梨県' },
    { id: '20', tag: 'Nagano / 長野県' },
    { id: '21', tag: 'Gifu / 岐阜県' },
    { id: '22', tag: 'Shizuoka / 静岡県' },
    { id: '23', tag: 'Aichi / 愛知県' },
    { id: '24', tag: 'Mie / 三重県' },
    { id: '25', tag: 'Shiga / 滋賀県' },
    { id: '26', tag: 'Kyoto / 京都府' },
    { id: '27', tag: 'Osaka / 大阪府' },
    { id: '28', tag: 'Hyogo / 兵庫県' },
    { id: '29', tag: 'Nara / 奈良県' },
    { id: '30', tag: 'Wakayama / 和歌山県' },
    { id: '31', tag: 'Tottori / 鳥取県' },
    { id: '32', tag: 'Shimane / 島根県' },
    { id: '33', tag: 'Okayama / 岡山県' },
    { id: '34', tag: 'Hiroshima / 広島県' },
    { id: '35', tag: 'Yamaguchi / 山口県' },
    { id: '36', tag: 'Tokushima / 徳島県' },
    { id: '37', tag: 'Kagawa / 香川県' },
    { id: '38', tag: 'Ehime / 愛媛県' },
    { id: '39', tag: 'Kochi / 高知県' },
    { id: '40', tag: 'Fukuoka / 福岡県' },
    { id: '41', tag: 'Saga / 佐賀県' },
    { id: '42', tag: 'Nagasaki / 長崎県' },
    { id: '43', tag: 'Kumamoto / 熊本県' },
    { id: '44', tag: 'Oita / 大分県' },
    { id: '45', tag: 'Miyazaki / 宮崎県' },
    { id: '46', tag: 'Kagoshima / 鹿児島県' },
    { id: '47', tag: 'Okinawa / 沖縄県' }
  ];

export enum DIVISIONS_TYPES {
    STATES = "states",
    PROVINCES = "provinces",
    PREFECTURES = "prefectures"
}

export const administrativeNaming: Map<string, string> = new Map([
    ["US", DIVISIONS_TYPES.STATES],
    ["CA", DIVISIONS_TYPES.PROVINCES],
    ["MX", DIVISIONS_TYPES.STATES],
    ["BR", DIVISIONS_TYPES.STATES],
    ["CN", DIVISIONS_TYPES.PROVINCES],
    ["AU", DIVISIONS_TYPES.STATES],
    ["KR", DIVISIONS_TYPES.PROVINCES],
    ["MY", DIVISIONS_TYPES.STATES],
    ["JP", DIVISIONS_TYPES.PREFECTURES]
]);

export const subdivision: Map<string, Territory[]> = new Map([
    ["US", USA_STATES],
    ["CA", CANADA_PROVINCES],
    ["MX", MEXICO_STATES],
    ["BR", BRAZIL_STATES],
    ["CN", CHINA_PROVINCES],
    ["AU", AUSTRALIA_STATES],
    ["KR", SOUTHKOREA_PROVINCES],
    ["MY", MALAYSIA_STATES],
    ["JP", JAPAN_PREFECTURES]
]);

export const territoryId = (element: Territory | string): string => {
    if (typeof element == 'string') return element;
    return element.id;
}

// this is only to distinguish between zipcodes and post codes
export const COUNTRIES_USING_ZIPCODES = [
  "US", // United States
  "PH" // Phillipines
];

export const COUNTRIES_WITH_SUBDIVISIONS: string[] = [
  "US",
  "CA",
  "MX",
  "BR",
  "CN",
  "AU",
  "KR",
  "MY",
  "JP"
]

export const COUNTRIES_WITHOUT_POSTCODES: string[] = [
  "AW", // Aruba
  "HK", // Hong Kong
  "KY", // Cayman Islands
  "PA", // Panama
  "BZ", // Belize
  "BM", // Bermuda
  "LR", // Liberia
  "TV"  // Tuvalu
];

export const COUNTRY_CODES: string[] = [
  "AF", "AX", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "AZ", "BS", "BH", "BD", "BB", 
  "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BQ", "BA", "BW", "BV", "BR", "IO", "BN", "BG", "BF", "BI", "CV", "KH", "CM", 
  "CA", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CG", "CD", "CK", "CR", "HR", "CU", "CW", "CY", "CZ", "DK", 
  "DJ", "DM", "DO", "EC", "EG", "SV", "GQ", "ER", "EE", "SZ", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", 
  "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT", "HM", "HN", "HK", "HU", 
  "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "CI", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "KP", "KR", "KW", 
  "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", 
  "MU", "YT", "MX", "FM", "MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE", 
  "NG", "NU", "NF", "MP", "NO", "OM", "PK", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "MK", 
  "RO", "RU", "RW", "RE", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "WS", "SM", "ST", "SA", "SN", "RS", "SC", "SL", "SG", 
  "SX", "SK", "SI", "SB", "SO", "ZA", "GS", "SS", "ES", "LK", "SD", "SR", "SJ", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH", 
  "TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV", "UG", "UA", "AE", "GB", "US", "UM", "UY", "UZ", "VU", "VA", 
  "VE", "VN", "VG", "VI", "WF", "EH", "YE", "ZM", "ZW"
];

export interface CountriesPluginCallbacks {
    onChange?: () => void
}

export default {
    async install(app: any, options: any) {
      
        const languages = options?.languages;

        if (!languages) {
            console.error("Languages query is required in plugin options.");
            return;
        }

        const dataState = Vue.observable({
            countries: [] as Territory[],
            callbacks: {} as CountriesPluginCallbacks
        });

        app.prototype.$countries = {
            get all() {
                return dataState.countries;
            },
            async setup(payload: {events: CountriesPluginCallbacks}) {
                if (payload.events.onChange) {
                    dataState.callbacks.onChange = payload.events.onChange;
                }

                for (let i = 0; i < languages.length; i++) {
                    await import(`i18n-iso-countries/langs/${languages[i]}.json`).then((data) => {
                        countries.registerLocale(data);
                    })
                }
            
                const countriesData: {[id: string]: string} = countries.getNames("en");
                const keyPair = Object.entries(countriesData);
                const countryInit: Territory[] = [];

                for (let i = 0; i < keyPair.length; i++) {
                    countryInit.push({
                        id: keyPair[i][0],
                        tag: keyPair[i][1]
                    })
                }

                dataState.countries = countryInit;

                if (payload.events.onChange) {
                    payload.events.onChange();
                }
            },
            byId(id: string, locale = "en") {
                const countriesData: {[id: string]: string} = countries.getNames(locale);
                const keyPair = Object.entries(countriesData);
                for (let i = 0; i < keyPair.length; i++) {
                    if (keyPair[i][0] == id) return {
                        id: keyPair[i][0],
                        tag: keyPair[i][1]
                    } as Territory;
                }
                return undefined;
            },
            change(locale: string) {
                const countriesData: {[id: string]: string} = countries.getNames(locale);
                const keyPair = Object.entries(countriesData);
                const result: Territory[] = [];
        
                for (let i = 0; i < keyPair.length; i++) {
                    result.push({
                        id: keyPair[i][0],
                        tag: keyPair[i][1]
                    })
                }
    
                dataState.countries = result;
                
                if (dataState.callbacks.onChange) dataState.callbacks.onChange();
            }
        }
    }
}