

import { Vue, Prop, Component } from "vue-property-decorator"
import { RootView } from "@/components";
import LicenseBlock from "./LicenseBlock.vue";
import { TreatmentTag } from "../tags";

@Component({
    components: {
        TreatmentTag,
        LicenseBlock
    }
})
export default class DeviceInfoBlock extends RootView {
    onRowClicked() {
        this.$emit('click', {device: this.device, index: this.index});
    }

    get displayIcon(): any {
        return this.deviceIcon(this.chooseDevice);
    }

    get deviceIdMaxWidth(): string {
        if (!this.isMobile) {
            return "width: 128px;"
        }
        return "width: 96px;"
    }
}

