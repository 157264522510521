
import { Component } from "vue-property-decorator";

import { Command } from "@/Utils";
import { modal, toast, ToastType } from "@/Toast";

import RootView from "../RootView";
import Dropdown from "./Dropdown.vue";

import { ADMIN_COMMANDS, MODAL_ADMIN_ASSIGN_ORG, MODAL_ADMIN_SET_DEVICE_DAILY, 
    MODAL_ADMIN_STORAGE, MODAL_ADMIN_SYNC, MODAL_ADMIN_UPDATE_COUNTRY, MODAL_ADMIN_UPDATE_FW, 
    MODAL_ADMIN_UPDATE_LICENSE, MODAL_ADMIN_UPDATE_TAGS, MODAL_EDIT_DEVICE_LABEL, MODAL_TREATMENT } from "@/Data";

@Component ({
    components: {
        Dropdown
    }
})
export default class DeviceCommandsDropdown extends RootView {
    labelCommand!: Command;
    treatmentCommand!: Command; 
    baseCommands: Command[] = [];

    get commands(): Command[] {
      if (this.isOnDeviceDetailsPage) {
        return [
          this.labelCommand,
          ...this.baseCommands,
          this.treatmentCommand,
        ]
      }
      return [
        ...this.baseCommands,
      ]
    }

    beforeMount() {
      this.baseCommands = [
        this.adminCmd(ADMIN_COMMANDS.ASSIGNMENT), this.adminCmd(ADMIN_COMMANDS.LICENSE), this.adminCmd(ADMIN_COMMANDS.FIRMWARE), 
        this.adminCmd(ADMIN_COMMANDS.DAILY),
        this.adminCmd(ADMIN_COMMANDS.STORAGE),
        this.adminCmd(ADMIN_COMMANDS.SYNC), this.adminCmd(ADMIN_COMMANDS.TAGS),
        this.adminCmd(ADMIN_COMMANDS.COUNTRY)
      ];
      this.setTreatmentCommand();
      this.setLabelCommand();
    }


    setLabelCommand() {
      if (!this.isOnDeviceDetailsPage) {
        return;
      }

      this.labelCommand = this.adminCmd(ADMIN_COMMANDS.LABEL);
    }

    setTreatmentCommand() {
      if (!this.isOnDeviceDetailsPage) {
        return;
      }
      let text = "";

      if (this.isTreatmentInTransition) {
        text = this.$t("options_cmd_cancel_treatment", [this.isTreatmentTurningOn ? "on" : "off"]).toString();
      } else {
        text = this.$t(!this.isTreatmentOn ? "options_cmd_activate_treatment" : "options_cmd_deactivate_treatment").toString();
      }

      this.treatmentCommand = {id: "treatment", name: text};
    }

    onCommandClicked(command: Command) {
      if (this.device == null && this.selectedDevices.length <= 0) {
        toast({
          message: this.$t("toast_missing_selection", ["device"]).toString(),
          type: ToastType.TOAST_ERROR
        });
        return;  
      }

      switch(command.id) {
        case ADMIN_COMMANDS.FIRMWARE: 
          modal(MODAL_ADMIN_UPDATE_FW);
          break;
        case ADMIN_COMMANDS.LICENSE:
          modal(MODAL_ADMIN_UPDATE_LICENSE);
          break;
        case ADMIN_COMMANDS.ASSIGNMENT:
          modal(MODAL_ADMIN_ASSIGN_ORG);
          break;
        case ADMIN_COMMANDS.TAGS:
          modal(MODAL_ADMIN_UPDATE_TAGS);
          break;
        case ADMIN_COMMANDS.SYNC:
          modal(MODAL_ADMIN_SYNC);
          break;
        case ADMIN_COMMANDS.STORAGE:
          modal(MODAL_ADMIN_STORAGE);
          break;  
        case ADMIN_COMMANDS.DAILY:
          modal(MODAL_ADMIN_SET_DEVICE_DAILY);
          break;
        case ADMIN_COMMANDS.LABEL:
          modal(MODAL_EDIT_DEVICE_LABEL);
          break;
        case ADMIN_COMMANDS.COUNTRY:
          modal(MODAL_ADMIN_UPDATE_COUNTRY);
          break;
        case "treatment":
          modal(MODAL_TREATMENT);
          break;
        default:
          break;
      }
    }

}
