
import { Component } from "vue-property-decorator";
import { RootView, MissingData, Dropdowns } from "@/components";

import DeviceModel from "@/models/DeviceModel";
import UserOrganisationModel from "@/models/UserOrganisationModel";
import OrganisationModel from "@/models/OrganisationModel";

import { SearchbarField } from "@/components/fields";
import { DeviceInfoBlock } from "@/components/blocks";
import { TreatmentTypesSearchDropdown, OrganisationSearchDropdown } from "@/components/dropdowns";

@Component({
    components: {
        SearchbarField,
        MissingData,
        DeviceInfoBlock,
        TreatmentTypesSearchDropdown,
        OrganisationSearchDropdown
    }
})
export default class DeviceSearchHomeView extends RootView {

    toOrg(models: UserOrganisationModel[]): OrganisationModel[] {
      let results: OrganisationModel[] = [];
      for (let i = 0; i < models.length; i++) {
        results.push(models[i].org!);
      }
      return results;
    }

    get styleValue(): string {
      if (this.$store.state.organisation_devices.index.searched.length == 0) {
        return "overflow-y: hidden";
      }

      return "overflow-y: scroll;"
    }

    get searchBarWidth(): string {
      if (this.isMobile) {
        return "w-100";
      }
      return "flex-grow-1";
    }

    get otherSearchStyle(): string {
      if (this.isMobile) {
        return "width: 100%";
      }
      return "width: 196px;"
    }

    get paddingSize() {
      if (this.isMobile) {
        return "p-1";
      }
      return "p-3";
    }

    close() {
        this.$emit("close");
    }

    onDeviceListItemClicked(device: DeviceModel, i: number) {
        this.$emit("selected", {
            device: device,
            index: i
        });
    }

    onSearch() {
        this.$emit("search");
    }
}
