
import { Component } from "vue-property-decorator";
import RootView from "../RootView";
import Dropdown from "./Dropdown.vue";
import { MODAL_EDIT_DEVICE_LABEL, MODAL_STORAGE, MODAL_TREATMENT } from "@/Data";
import { modal } from "@/Toast";

@Component({
    components: {
        Dropdown
    }
})
export default class DeviceOptionsDropdown extends RootView {

    get treatmentTextExtra(): string {
        if (this.isTreatmentInTransition) {
            if (this.chooseDevice?.configuration.content.active) return "on";
            return "off";
        }
        return "";
    }
    
    get treatmentText(): string {
        if (this.isTreatmentInTransition) {
            return "options_cmd_cancel_treatment";
        }

        if (this.isTreatmentOn) {
            return "options_cmd_deactivate_treatment";
        }

        return "options_cmd_activate_treatment";    
    }
    
    onOptionClicked(option: string) {
        switch(option) {
            case "edit":
                modal(MODAL_EDIT_DEVICE_LABEL);
                break;
            case "toggle":
                modal(MODAL_TREATMENT);
                break;
            case "storage":
                modal(MODAL_STORAGE);
                break;
            default:
                break;
        }
    }
}
