
import { Prop, Component } from "vue-property-decorator";
import RootView from "../RootView";
import DeviceInfoBlock from '../blocks/DeviceInfoBlock.vue';

@Component({
    components: {
        DeviceInfoBlock
    }
})
export default class DeviceActionLayout extends RootView {
    @Prop({default: ""}) text!: string;
    @Prop({default: false}) options!: boolean; 
    @Prop({default: false}) fullscreen!: boolean;
    @Prop({default: 240}) maxHeight!: number; 
    @Prop({default: true}) scrollOptions!: boolean;

    tab = null;

    get plural() {
        return this.selectedDevices.length > 1 ? "s" : '';
    }

    get optionSectionStyle() {
        let scroll = "auto";
        if (this.tab == "options" && !this.scrollOptions) {
            scroll = "hidden";
        }
        return `height: 100%; !important; overflow-y: ${scroll}; margin-bottom: 16px;`
    }

    get deviceSectionStyle() {
        let scroll = "auto";
        if (this.tab == "options" && !this.scrollOptions) {
            scroll = "hidden";
        }
        return `height: 100%; !important; overflow-y: ${scroll}; margin-bottom: 16px;`
    }

    get deviceSectionHeight(): number {
        let result = (this.selectedDevices.length * 55) + 15;
        if (result >= this.maxHeight) {
            return this.maxHeight;
        }
        return result;
    }

    get deviceClass() {
        if (this.selectedDevices.length == 1) {
            return "";
        }
        return "pe-3";
    }
    
    get descTitleStyle() {
        if (this.isMobile) {
            return `font-size: 18px;`;
        }
        return 'font-size: 20px;';
    }

    get descTitleSubtitleStyle() {
        if (this.isMobile) {
            return `font-size: 14px;`;
        }
        return `font-size: 16px;`;
    }
}
