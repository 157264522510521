
import { Component } from "vue-property-decorator";
import { MODAL_EDIT_ORGANISATION } from "@/Data";
import { ModalOptions } from "@/Toast";

import OrganisationModel from "@/models/OrganisationModel";

import { ModalSetup, RootView } from "@/components";
import { EditableField } from "@/components/fields";
import { ActionModalLayout } from "@/components/layouts"

@Component({
    components: {
        ActionModalLayout,
        EditableField
    }
})
export default class EditOrganisationModalView extends RootView {
    name = "";
    phone = "";
    email = "";
    address = "";
    website = "";

    $refs!: {
        editInfoName: EditableField,
        editInfoPhone: EditableField,
        editInfoWebsite: EditableField,
        editInfoAddress: EditableField,
        editInfoEmail: EditableField,
    }

    get options(): ModalOptions {
        return {
            modal: MODAL_EDIT_ORGANISATION,
            title: "modal_editorg_title",
            onOpen: this.reset,
            action: this.process,
            desc: ""
        }
    }
   
    reset() {
        this.name = (this.$store.state.organisations.model.name ?? "");
        this.phone = (this.$store.state.organisations.model.phone ?? "");
        this.email = (this.$store.state.organisations.model.email ?? "");
        this.address = (this.$store.state.organisations.model.address ?? "");
        this.website = (this.$store.state.organisations.model.website ?? "");

        this.$refs.editInfoName.stop();
        this.$refs.editInfoPhone.stop();
        this.$refs.editInfoEmail.stop();
        this.$refs.editInfoAddress.stop();
        this.$refs.editInfoWebsite.stop();
    }

    process(): Promise<any> {
        const organisation: OrganisationModel = {
            org_id: this.$store.state.organisations.model.org_id,
            name: this.name,
            phone: this.phone,
            email: this.email,
            website: this.website,
            address: this.address
        };

        return this.$store.dispatch("organisations/update", {
            organisation: organisation
        });
    }
}
