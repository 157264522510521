
import { Component } from "vue-property-decorator";

import { RootView } from '@/components';
import { DeviceModalLayout } from "@/components/layouts";
import { MODAL_ORGANISATION_TREATMENT } from "@/Data";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class OrganisationDevicesStorageModalView extends RootView {
    get options(): ModalOptions {
        return {
            modal: MODAL_ORGANISATION_TREATMENT,
            title: "modal_organisationstorage_title",
            desc: "modal_organisationstorage_desc",
            action: this.action
        }
    }

    action(): Promise<any> {
        return Promise.resolve(undefined);
    }
}
