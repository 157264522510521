
import { Component } from "vue-property-decorator";
import { MODAL_ADMIN_ASSIGN_ORG } from "@/Data";

import { RootView } from "@/components";
import { SelectDropdown } from "@/components/dropdowns";
import { DeviceModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        SelectDropdown,
        DeviceModalLayout
    }
})
export default class AdminAssignOrganisationModalView extends RootView {

    $refs!: {
        assignOrgDropdown: SelectDropdown
    }

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_ASSIGN_ORG,
            title: "modal_adminassignorg_title",
            desc: "modal_adminassignorg_desc",
            action: this.process
        }
    }

    process(): Promise<any> {
        return this.$store.dispatch("admin_devices/assignOrg", {options: {org_id: this.$refs.assignOrgDropdown.value.org_id}, selected: this.selectedDevices});
    }

}
