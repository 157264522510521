
import { Component } from "vue-property-decorator";
import { isValidSerialNumber } from "@/Utils";
import { DeviceSelected } from "@/models/DeviceSelection";

import { MODAL_ADMIN_UPDATE_LICENSE } from "@/Data";
import { toast, ToastType } from "@/Toast";

import { MissingData, RootModal, ModalSetup } from "@/components";
import { CheckboxField, EditbarField } from "@/components/fields";
import { DeviceActionLayout } from "@/components/layouts";
import { BoxIndicatorBlock } from "@/components/blocks";

@Component({
    components: {
        EditbarField,
        CheckboxField,
        DeviceActionLayout,
        BoxIndicatorBlock,
        MissingData
    }
})
export default class AdminUpdateLicenseModalView extends RootModal {
    reverseLicenseValue = false;
    choiceLicenseDate = (new Date()).toISOString().substr(0, 10);
    licenses: {device_id: string, id: string, expiry: number}[] = [];
    inputSerialNumber = "";
    devicesWithoutSN: DeviceSelected[] = [];

    licenseUpdateRules = {
      serialnumber: (value: string): any => isValidSerialNumber(value) || "Invalid serial number format."
    }

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_UPDATE_LICENSE
        };
    }

    onDeviceLicenseSubmitSn(device: DeviceSelected, data: string) {
      if (!isValidSerialNumber(data)) {
        return;
      }
      device.serialnumber = data;
    }
    
    get imeiStyle(): string {
        if (this.isMobile) return "font-size: 12px; "
        return "";
    }

    override onData() {
        this.devicesWithoutSN = [];
        for (let i = 0; i < this.selectedDevices.length; i++) {
            if (this.invalidSN(this.selectedDevices[i].serialnumber)) {
                this.devicesWithoutSN.push(this.selectedDevices[i]);
            }
        }
    }

    onAdminLicenseModalOpened() {
        this.inputSerialNumber = this.hasSerialNumber ? this.serialNumber! : "";
        this.choiceLicenseDate = (new Date()).toISOString().substr(0, 10);
        this.reverseLicenseValue = false;
    }

    invalidSN(str: string | undefined): boolean {
        return !str || str.trim() == '';
    }

    override validations(): Promise<boolean> {
        this.licenses = [];
        let expiryLicenseChoice = new Date(this.choiceLicenseDate);

        let currentDate = new Date();
        if (!this.reverseLicenseValue && expiryLicenseChoice.getTime() < currentDate.getTime()) {
            toast({type: ToastType.TOAST_ERROR, message: this.$t("modal_adminupdatelicense_date_error").toString()});
            return Promise.resolve(false);
        }

        let expiry: number = expiryLicenseChoice.getTime();

        const devices: DeviceSelected[] = this.selectedDevices;
        if (this.isOnDevicePage) {
            if (this.invalidSN(this.serialNumber)) {
                toast({type: ToastType.TOAST_ERROR, message: this.$t("modal_adminupdatelicense_singlesn_error").toString()});
                return Promise.resolve(false);
            }

            this.licenses.push({
                id: this.inputSerialNumber.trim(),
                device_id: devices[0].device_id,
                expiry: expiry
            });
            return Promise.resolve(true);
        }
        
        for (let i = 0; i < devices.length; i++) {
            if (this.invalidSN(devices[i]!.serialnumber)) {
                toast({type: ToastType.TOAST_ERROR, message: this.$t("modal_adminupdatelicense_sn_error").toString()});
                return Promise.resolve(false);
            }

            this.licenses.push({
                id: devices[i].serialnumber! ,
                device_id: devices[i].device_id,
                expiry: expiry
            });
        }

        return Promise.resolve(true);
    }

    override process(): Promise<any> {
      return this.$store.dispatch("admin_devices/updateLicenses", {selected: this.licenses}).then(async (data) => {
        return data;
      })
    }

}
