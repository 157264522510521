
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectDropdown extends Vue {
    @Prop({default: []}) items?: any[];
    @Prop({default: "label"}) label?: string;
    @Prop({default: ""}) placeholder?: string;

    value?: any = "";

    onclick(item: any) {
        this.$emit('click', item);
    }

    forceValue(value: any) {
        this.value = value;
    }

    onInputChange(data: any) {
        if (data == null) {
            this.$emit('clear');
        }
        this.$emit("input", this.value);
    }

    clear() {
        this.value = undefined;
    }
}

