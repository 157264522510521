import { RootView } from "@/components";
import { TabLayout } from "@/components/layouts";
import { MODAL_EDIT_PASSWORD, MODAL_EDIT_PROFILE } from "@/Data";
import { modal } from "@/Toast";
import { Command } from "@/Utils";
import { Component, Watch } from "vue-property-decorator";

@Component
export default class BaseProfileView extends RootView {

    $refs!: {
        tab: TabLayout
    }

    commands: Command[] = [{
        id: "edit_password",
        name: this.$t("profile_command_changepassword").toString()
      }, {
        id: "edit_profile",
        name: this.$t("profile_command_editprofile").toString()
    }];

    onTabChanged(tab: string) {
        this.setQuery(this.QUERY_PARAMS.QUERY_PARAM_TAB, tab);
    }

    onCommandClicked(command: Command) {
        switch (command.id) {
            case "edit_password":
                modal(MODAL_EDIT_PASSWORD);
                break;
            case "edit_profile":
                modal(MODAL_EDIT_PROFILE);
                break;
            default:
                break;
        }
    }

    @Watch("$store.state.global.query")
    onQueryChanged(query: any) {
        const tab = query[this.QUERY_PARAMS.QUERY_PARAM_TAB];
        if (this.$refs.tab.isCurrentTab(tab)) return;
        this.$refs.tab.setTab(tab);
    }
}