
import { Component, Watch } from "vue-property-decorator";
import { RootView, VForm } from '@/components';
import { ActionModalLayout, ListLayout } from '@/components/layouts';
import { ModalOptions } from "@/Toast";
import { MODAL_ADMIN_DELETE_ORG } from "@/Data";
import { CheckboxField } from "@/components/fields";

@Component({
    components: {
        ActionModalLayout, ListLayout, CheckboxField
    }
})
export default class AdminDeleteOrganisationModalView extends RootView {
    $refs!: {
        form: VForm
    }

    rules = {
        required: (value: boolean) => {
            if (value) return true;
            return "Required";
        }
    }

    checked = false;

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_DELETE_ORG,
            title: "modal_admindeleteorg_title",
            desc: "",
            size: "md",
            action: this.process,
            validations: this.validations,
            onOpen: this.onOpen
        }
    }

    onOpen() {
        this.checked = false;
    }

    validations(): Promise<boolean> {
        return Promise.resolve(this.$refs.form.validate());
    }

    process(): Promise<any> {
        return Promise.resolve(undefined);
    }
}
