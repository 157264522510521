
import { Component } from "vue-property-decorator";
import UserOrganisationModel from "@/models/UserOrganisationModel";
import { MODAL_KICK_ORGANISATION_USER } from "@/Data";

import { RootModal, ModalSetup } from "@/components";

@Component
export default class KickOrganisationUserModalView extends RootModal {

    org_id = "";
    users: UserOrganisationModel[] = [];

    override setup(): ModalSetup {
        return {
            name: MODAL_KICK_ORGANISATION_USER
        };
    }

    override onData(data: any) {
        this.org_id = data.org_id;
        this.users = data.users;
    }

    override process(): Promise<any> {
        let promises: Promise<any>[] = [];

        for (let i = 0; i < this.users.length; i++) {
            promises.push(this.$store.dispatch("organisation_users/remove", {
                org_id: this.users[i].org_id,
                usr_id: this.users[i].usr_id
            }));
        }
        return Promise.all(promises);
    }
}
