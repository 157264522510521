
import { Component } from "vue-property-decorator";

import { RootView, MissingData } from "@/components";
import { ListLayout, ListFiltersLayout, ListHeaderLayout, CenterLayout, ListTooltipLayout } from '@/components/layouts';
import { Dropdown, UserOrganisationRoleDropdown } from "@/components/dropdowns";
import { SearchbarField, CheckboxField } from "@/components/fields";
import { PlusButton } from "@/components/buttons";
import { RoleTag } from "@/components/tags";

import BaseOrganisationUsersView from "./BaseOrganisationUsersView";

@Component({
    components: {
        MissingData, ListLayout, ListHeaderLayout, CheckboxField,
        ListFiltersLayout, ListTooltipLayout, Dropdown,
        SearchbarField, PlusButton, CenterLayout,
        UserOrganisationRoleDropdown, RoleTag
    }
})
export default class OrganisationUsersView extends BaseOrganisationUsersView {}
