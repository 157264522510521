
import { Component } from "vue-property-decorator";

import { RootView } from "@/components";
import { MODAL_STORAGE } from "@/Data";
import { DeviceModalLayout } from "@/components/layouts"
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class StorageModalView extends RootView {

    get options(): ModalOptions {
        return {
            modal: MODAL_STORAGE,
            title: "modal_storage_title",
            desc: this.inStorage ? 'modal_storage_text_return' : 'modal_storage_text',
            action: this.process,
            options: false
        }
    }

    process(): Promise<any> {
        const state = (!(this.inStorage ?? false));
        return this.$store.dispatch("device/updateLocal", {
            type: "storage",
            content: state 
        });
    }
}
