
import { Component } from "vue-property-decorator";

import DeviceModel from "@/models/DeviceModel";
import { MODAL_TREATMENT } from "@/Data";
import { RootView } from "@/components";
import { DeviceModalLayout } from "@/components/layouts"
import { ModalOptions } from "@/Toast";

@Component({
    components: {
        DeviceModalLayout
    }
})
export default class TreatmentModalView extends RootView {

    get options(): ModalOptions {
        return {
            modal: MODAL_TREATMENT,
            title: "modal_treatment_title",
            desc: this.$t('modal_treatment_text', [
                !this.treatmentStatus ? this.$t('activate').toString().toLowerCase() : 
                this.$t('deactivate').toString().toLowerCase() ]).toString(),
            action: this.process,
            options: false
        }
    }

    process(): Promise<any> {
        const device: DeviceModel | null = this.chooseDevice;
        if (device == null) {
            return Promise.resolve(false);
        }

        const state = !device.configuration?.content.active;
        return this.$store.dispatch("device/toggle", {
            content: state
        });
    }

}

