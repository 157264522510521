
import { Component, Prop, Vue } from "vue-property-decorator";
import RootView from "./RootView";

@Component
export default class Branding extends RootView {
    @Prop({default: true}) title!: boolean;
    @Prop({default: ""}) width!: string;
    @Prop({default: false}) small!: boolean;
    @Prop({default: false}) mobile!: boolean;
    @Prop({default: true}) image!: boolean;

    get classStyle(): string {
        if (this.isMobile) {
            return "d-flex flex-row justify-content-center align-items-center flex-wrap";
        }
        return "d-flex flex-row justify-content-center";
    }
    get fontSize() {
        if (this.small) {
            return "font-size: 24px";
        }
        return "font-size: 36px";
    }

    get iconWidth() {
        if (this.width.trim().length != 0) {
            return this.width;
        }
        if (this.small) {
            return "width: 156px";
        }
        return "width: 256px";
    }
}
