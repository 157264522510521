import { emptyArray, emptyString, falseBoolean, searchItems, specificString } from "@/search/Search";
import DeviceModel from "@/models/DeviceModel";
import { territoryId } from "@/plugins/countries";
import { doesStringIncludeAnyFromArray } from "@/Utils";

export interface AdminDeviceSearchFilters {
    label: string,
    tags: string[],
    orgs: string,
    daily: boolean,
    license_expired: boolean,
    transducer_disconnected: boolean,
    last_update_type: string,
    treatment_type: string,
    ignore?: string[],
    country?: string
}

export default searchItems<DeviceModel, AdminDeviceSearchFilters>(new Map([
    ["label", {
        default: emptyString,
        validation: (labelRaw: string, device: DeviceModel): boolean => {

            if (labelRaw.trim() == "") {
                return false;
            }
        
            if (!device.local && !device.info) {
                return true;
            }

            let skip = true;
            let str = "";

            const label = labelRaw.toLowerCase().split("+");

            if (skip && device.local) {
                str = device.local?.content.label.toLowerCase() ?? "";
                skip = !doesStringIncludeAnyFromArray(str, label);
            }

            if (skip && device.info) {
                str = (device.info?.content.serialnumber?.toLowerCase() ?? "").replace("emf1201-", "emf");
                skip = !doesStringIncludeAnyFromArray(str, label);
            }

            if (skip && device.info) {
                str = device.info?.content.imei?.toLowerCase() ?? "";
                skip = !doesStringIncludeAnyFromArray(str, label);
            }

            if (skip && device.info) {
                str = device.info?.content.appV?.toLowerCase() ?? "";
                if (str.length > 0) {
                    str = "v" + str;
                }
                skip = !doesStringIncludeAnyFromArray(str, label);
            }
            
            return skip;
        }
    }],
    ["tags", {
        default: emptyArray,
        validation: (tags: string[], device: DeviceModel): boolean => {
            return tags.length > 0 && (device.local == null || (device.local != null && !tags.some(value => device.local!.content.tags.includes(value))));
        }
    }],
    ["orgs", {
        default: specificString("ALL"),
        validation: (org_id: string, device: DeviceModel): boolean => {
            return org_id != "ALL" && device.org_id.toUpperCase() != org_id.toUpperCase();
        }
    }],
    ["license_expired", {
        default: falseBoolean,
        validation:  (licenseExpiredOnly: boolean, device: DeviceModel): boolean => {
            const timestamp: number = Date.now()
            if (!licenseExpiredOnly) {
                return false;
            }
            const licenseExpired: boolean = device.license == undefined || (device.license != null  
                                && timestamp >= device.license.content.expiry);
            if (licenseExpiredOnly && !licenseExpired) return true;        
            if (device.licenserequest === undefined) return false;
            return device.licenserequest.content.expiry >= timestamp;
        }
    }],
    ["daily", {
        default: falseBoolean,
        validation: (dailyOnly: boolean, device: DeviceModel): boolean => {
            return device.daily != undefined && dailyOnly && !device.daily;
        }
    }],
    ["transducer_disconnected", {
        default: falseBoolean,
        validation: (transducerDisconnectedOnly: boolean, device: DeviceModel): boolean => {
            return device.transducer != null && transducerDisconnectedOnly && device.transducer?.content.connected;
        }
    }],
    ["last_update_type", {
        default: specificString("ALL"),
        validation: (update_type: string, device: DeviceModel): boolean => {
            const updateType = update_type.toUpperCase().trim();
            const deviceUpdateType = device.src.toUpperCase().trim();
            if (updateType == "ALL") {
                return false;
            }
            return deviceUpdateType != updateType;
        }
    }],
    ["treatment_type", {
        default: specificString("ALL"),
        validation: (treatment_type: string, device: DeviceModel): boolean => {
            const treatmentType = treatment_type.toUpperCase();
            if (treatmentType == "ALL") {
                return false;
            }

            if (!device.transducer || !device.configuration) {
                return true;
            }

            switch(treatmentType) {
                case "TREATMENT_ON":
                    return !(device.transducer.content.activated && device.configuration.content.active);
                case "TREATMENT_OFF":
                    return !(!device.transducer.content.activated && !device.configuration.content.active);
                default: 
                    return !((device.transducer.content.activated && !device.configuration.content.active) 
                            || (!device.transducer.content.activated && device.configuration.content.active));
            }
        }
    }],
    ["country", {
        default: emptyString,
        validation: (country: string, device: DeviceModel): boolean => {
            if (country.trim().length == 0) return false;
            if (!device.local?.content.country) return true;
            return country != territoryId(device.local?.content.country);
        }
    }],
    ["ignore", {
        default: emptyArray,
        validation: (tags: string[], device: DeviceModel): boolean => {
            if (tags.length == 0) return false;
            if (device.local == null) return false;
            return tags.some(value => device.local!.content.tags.includes(value));
        }
    }],
])); 