
import { Component, Prop } from "vue-property-decorator"
import RootView from "@/components/RootView";

@Component
export default class SuperAdminButton extends RootView {

    isInOrganisation = false;

    async mounted() {
        this.isInOrganisation = await this.$store.dispatch("auth/isInOrganisation", this.$store.state.organisations.model?.org_id);
    }

    onClick() {
        if (!this.isInOrganisation) {
            this.$store.dispatch("admin/joinOrg", this.$store.state.organisations.model?.org_id);
        } else {
            this.$store.dispatch("admin/leaveOrg", this.$store.state.organisations.model?.org_id);
        }
        
        this.isInOrganisation = !this.isInOrganisation;
    }

}
