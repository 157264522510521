
import { Component, Watch } from 'vue-property-decorator';

import DeviceModel from "@/models/DeviceModel";
import { DeviceSelected } from '@/models/DeviceSelection';

import { QUERY_PARAM_LABEL, QUERY_PARAM_TAGS, MODAL_ADMIN_CREATE_EMPTY, 
  MODAL_ADMIN_OP_SUCCESS, MODAL_ADMIN_GEN_LIC, MODAL_ADMIN_MANAGE_DAILY_EMAIL  } from '@/Data';
import { modal } from '@/Toast';

import { RootView, PageTitle, MissingData, FilterSort } from '@/components';
import { OrganisationSearchDropdown, TreatmentTypesSearchDropdown, DeviceCommandsDropdown, LastUpdateTypeSearchDropdown, CountryDropdown } from '@/components/dropdowns';
import { BoxIndicatorBlock, TagsBlock, LastUpdateBlock, OrganisationLinkBlock } from '@/components/blocks';
import { TreatmentTag, TransducerTag, ConnectivityTag, LicenseTag } from '@/components/tags';
import { SearchbarField, ChipSearchField, CheckboxField, RegisterBarField } from '@/components/fields';
import { CenterLayout, ListFiltersLayout, ListHeaderLayout, ListLayout, ListTooltipLayout } from '@/components/layouts';
import { PlusButton, ViewButton, DisableButton } from '@/components/buttons';
import { CountryIcon } from '@/components/icons';

import AdminViewLayout from '../AdminViewLayout.vue';
import { MarkOptions } from 'mark.js';

@Component({
    components: {
        AdminViewLayout,
        ListFiltersLayout, ListHeaderLayout, CenterLayout,
        PageTitle, ListLayout, CheckboxField, ListTooltipLayout, 
        BoxIndicatorBlock, LastUpdateBlock, LicenseTag,
        TreatmentTag, FilterSort, RegisterBarField, ConnectivityTag,
        TransducerTag, PlusButton, MissingData, ViewButton, TagsBlock,
        SearchbarField, ChipSearchField, LastUpdateTypeSearchDropdown,
        OrganisationSearchDropdown, TreatmentTypesSearchDropdown,
        DeviceCommandsDropdown, DisableButton, 
        CountryDropdown, CountryIcon, OrganisationLinkBlock
    }
})
export default class AdminDevicesPageView extends RootView {

    get markOptions(): MarkOptions {
        return {
          acrossElements: true,
          exclude: [
            "thead *",
            ".row-device-update *",
            ".row-device-daily *",
            ".row-device-country *",
            ".row-device-transducer *",
            ".row-device-treatment *",
            ".row-device-cloud *",
            ".row-device-tags *",
            ".row-device-organisation *"
          ]
        }
    }

    showSearchLabel = this.$store.state.admin_devices.filters.label.trim().length > 0;
    savedLabel = this.$store.state.admin_devices.filters.label.trim();
    
    onButtonCreateClicked() {
      modal(MODAL_ADMIN_CREATE_EMPTY);
    }

    onWarnLicenseBtnClicked() {
      this.goto("/admin/licenses");
    }

    showAdminSuccessModel(data: DeviceSelected[]) {
      modal(MODAL_ADMIN_OP_SUCCESS, data);
    }

    onGenerateLicenseBtnClicked() {
      modal(MODAL_ADMIN_GEN_LIC);
    }

    onManageDailyEmailBtnClicked() {
      modal(MODAL_ADMIN_MANAGE_DAILY_EMAIL);
    }

    onDeviceFilterByUpdate(direction: string) { this.$store.commit("admin_devices/sortByUpdatedTime", {direction: direction}); }

    onDeviceDetailsButtonClicked(device: DeviceModel) {
      this.goto({ path: `/organisation/${device.org_id}/devices/${device.device_id}`});
    }

    onLabelSearch() {
      if (this.$store.state.admin_devices.filters.label.trim() == "") {
        this.$nextTick(() => {
            this.showSearchLabel = false;
            this.savedLabel = "";
        })
      }
      this.$store.commit(this.ACTIONS.ADMIN_SEARCH_DEVICES);

      if (this.$store.state.admin_devices.filters.label.trim() == "") {
        this.removeFromQuery(QUERY_PARAM_LABEL);
        return;
      }
      
      this.setQuery(QUERY_PARAM_LABEL, this.$store.state.admin_devices.filters.label.trim());
      this.$nextTick(() => {
        this.savedLabel = this.$store.state.admin_devices.filters.label.slice();
        this.showSearchLabel = true;
      })
    }

    onTagChanged(tags: string[]) {
      this.$store.commit("admin_devices/setFilter", {filter: "tags", value: tags});
      this.$store.commit(this.ACTIONS.ADMIN_SEARCH_DEVICES);

      if (tags.length > 0) {
        this.setQuery(QUERY_PARAM_TAGS, tags.join(","));
        return;
      }
      
      this.removeFromQuery(QUERY_PARAM_TAGS);
    }    

}
