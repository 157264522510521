
import { Component } from "vue-property-decorator";

import { MissingData, Accordion } from "@/components";
import { MobileListLayout, ListHeaderLayout, CenterLayout } from "@/components/layouts";
import { CheckboxField, SearchbarField } from "@/components/fields";
import { Dropdown, UserOrganisationRoleDropdown } from "@/components/dropdowns";

import BaseUserInvitationsView from "./BaseUserInvitationsView";

@Component({
    components: {
        MobileListLayout, ListHeaderLayout, MissingData, Accordion, CenterLayout,
        SearchbarField, UserOrganisationRoleDropdown, Dropdown, CheckboxField
    }
})
export default class MobileUserInvitationsView extends BaseUserInvitationsView {

}
