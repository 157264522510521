import { render, staticRenderFns } from "./InputAddButton.vue?vue&type=template&id=9b47c9f6&scoped=true&"
import script from "./InputAddButton.vue?vue&type=script&lang=ts&"
export * from "./InputAddButton.vue?vue&type=script&lang=ts&"
import style0 from "./InputAddButton.vue?vue&type=style&index=0&id=9b47c9f6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b47c9f6",
  null
  
)

export default component.exports