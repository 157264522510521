
import { Component } from "vue-property-decorator";
import JobModel from "@/models/JobModel";
import { MODAL_ADMIN_CANCEL_JOB, JobStatusRange  } from "@/Data";
import { RootModal, ModalSetup } from "@/components";

@Component
export default class AdminCancelFirmwareJobModalView extends RootModal {
    currentJob!: JobModel;

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_CANCEL_JOB,
        }
    }

    override onData(data: any) {
        this.currentJob = data as JobModel;
    }

    override async process(): Promise<any> {
        await this.$store.dispatch("admin/cancelJob", this.currentJob);
        this.currentJob!.status = JobStatusRange.CANCELLATION_IN_PROGRESS;
        return Promise.resolve(true);
    }
}
