

import { Component, Prop } from "vue-property-decorator"
import RootView from "@/components/RootView";

@Component
export default class TimestampText extends RootView {
    @Prop({default: true}) keyword!: boolean;
    @Prop({default: ""}) timestamp!: number;
}
