
import { Vue, Prop, Component } from "vue-property-decorator";
import RootView from "@/components/RootView";
import { ToastMessage, ToastType } from "@/Toast";

@Component({
    components: {}
})
export default class MobileToast extends RootView {
    @Prop({default: false}) autohide!: boolean;
    shown = false;

    headerClass = "";
    icon = "";
    message = "";
    category = "";
    clearTimeout: any;
    showTimeout: any;

    show(message: ToastMessage) {
        this.message = message.message;
        switch (message.type) {
          case ToastType.TOAST_SUCCESS:
            this.category = "Success";
            this.icon = "fa-regular fa-circle-check";
            this.headerClass = "bg-success";
            break;
          case ToastType.TOAST_ERROR:
            this.category = "Error";
            this.icon = "fa-regular fa-circle-xmark";
            this.headerClass = "bg-danger";
            break;
          case ToastType.TOAST_INFO:
          default:
            this.category = "Info";
            this.icon = "fa-regular fa-circle-question";
            this.headerClass = "bg-info";
            break;
        }
        
        this.$store.commit("global/setToast", true);
        this.showTimeout = setTimeout(() => {
            this.shown = true;
        }, 200);
        
        if (this.autohide) {
            this.clearTimeout = setTimeout(() => {
                this.hide();
            }, 3000);
        }
        
    }

    hide() {
        this.shown = false;
        this.$store.commit("global/setToast", false);
        clearTimeout(this.clearTimeout);
        clearTimeout(this.showTimeout);
    }
}
