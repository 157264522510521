
import { Component } from "vue-property-decorator";
import { MODAL_ADMIN_CREATE_ORG } from "@/Data";
import { RequiredIcon, RootView } from "@/components";
import { EditbarField } from "@/components/fields";
import { ActionModalLayout } from "@/components/layouts";
import { ModalOptions } from "@/Toast";
import { VForm } from "@/components/RootModal";
import { isValidEmail, isValidPhone } from "@/Utils";

@Component({
    components: {
        ActionModalLayout, EditbarField, RequiredIcon
    }
})
export default class AdminCreateOrganisationModalView extends RootView {
    
    $refs!: {
        form: VForm
    }

    rules = {
        required: (value: string) => !!value || 'Required.',
        name: (value: string): any => value.trim().length > 3 || "Name should be minimum more than 3 characters.",
        email: (value: string) => value.trim().length == 0 || isValidEmail(value) || "Invalid email",
        phone: (value: string) => value.trim().length == 0 || isValidPhone(value) || 'Invalid phone',
        str: (value: string) => value.trim().length == 0 || value.trim().length > 3 || "Value should be minimum more than 3 characters."
    }

    get options(): ModalOptions {
        return {
            modal: MODAL_ADMIN_CREATE_ORG,
            title: "modal_adminassignorg_title",
            desc: "modal_adminassignorg_desc",
            size: "md",
            action: this.process,
            validations: this.validations
        }
    }

    name = "";
    phone = "";
    email = "";
    website = "";
    address = "";
    showError = false;
    
    reset() {
        this.name = "";
        this.email = "";
        this.address = "";
        this.phone = "";
        this.website = "";
        this.showError = false;
    }

    validations(): Promise<boolean> {
        return Promise.resolve(this.$refs.form.validate());
    }

    process(): Promise<any> {
        return this.$store.dispatch("admin_organisations/create", {
            name: this.name,
            email: this.email,
            address: this.address,
            phone: this.phone,
            website: this.website
        });
    }
}
