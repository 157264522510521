
import { Component } from "vue-property-decorator";

import { MODAL_ADMIN_CREATE_EMPTY } from "@/Data";
import { toast, ToastType } from "@/Toast";
import { SimplifiedDevice } from "@/models/DeviceModel";

import { RootModal, ModalSetup, ModalType } from "@/components";
import { RegisterBarField } from "@/components/fields";

@Component({
    components: {
        RegisterBarField
    }
})
export default class AdminCreateEmptyDevicesModalView extends RootModal {
    ids: SimplifiedDevice[] = [];

    override setup(): ModalSetup {
        return {
          name: MODAL_ADMIN_CREATE_EMPTY,
          type: ModalType.PROCESS_STAY_OPEN
        }
    }

    reset() {
        this.ids = [];
    }

    resetRef() {
      this.$refs.registerbar.reset();
    }

    override async validations(): Promise<boolean> {
        if (this.ids.length == 0) {
          toast({
            message: "Please register a device before proceeding",
            type: ToastType.TOAST_ERROR
          });
          return Promise.resolve(false);
        }

        if (!this.$refs.registerbar.validate()) {
          return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }

    override async process(): Promise<any> {
        return this.$store.dispatch("admin_devices/create", this.ids);
    }
}
