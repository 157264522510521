
import { Component } from "vue-property-decorator";
import { RootView } from "@/components";
import { ActionModalLayout } from "@/components/layouts"
import { ModalOptions } from "@/Toast";
import { INVITATION_RESPONSE, MODAL_USER_INVITATION_REFUSE } from "@/Data";
import UserInvitationModel from "@/models/UserInvitationModel";

@Component({
    components: {
        ActionModalLayout
    }
})
export default class RefuseInvitationModalView extends RootView {
    selected: UserInvitationModel[] = [];

    get options(): ModalOptions {
        return {
            modal: MODAL_USER_INVITATION_REFUSE,
            title: "",
            desc: "",
            onData: this.onData,
            action: this.process
        }
    }

    onData(selected: UserInvitationModel[]) {
        this.selected = selected;
    }

    process(): Promise<any> {
        return this.$store.dispatch("user_invitations/confirm", {
            task: INVITATION_RESPONSE.REFUSE,
            selected: this.selected
        });
    }
}
