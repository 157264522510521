

import { Component} from 'vue-property-decorator';

import { CardLayout, ClassicContainerLayout, SummaryDetailsLayout, TabLayout } from '@/components/layouts';
import { SettingsDropdown } from '@/components/dropdowns';
import { SummaryCardSheet } from '@/components/sheets';

import UserMembershipsView from './UserMembershipsView.vue';
import UserInvitationsView from './UserInvitationsView.vue';
import BaseProfileView from './BaseProfileView';

@Component({
    components: {
        ClassicContainerLayout, SettingsDropdown, SummaryCardSheet,
        SummaryDetailsLayout, CardLayout, TabLayout,
        UserMembershipsView, UserInvitationsView
    }
})
export default class ProfileView extends BaseProfileView {

    mounted() {
        const view = (this.$store.state.global.query[this.QUERY_PARAMS.QUERY_PARAM_TAB] ?? "").toLowerCase();
        let tab = "";
        switch(view) {
            case "invitations":
                tab = "invitations";
                break;
            case "memberships":
            default:
                tab = "memberships";
                break;
        }

        this.$refs.tab.setTab(tab);
    }

}
