
import debounce from 'debounce';

import { Prop, Component, Watch } from "vue-property-decorator";
import RootView from "../RootView";
import VueSimpleSuggest from 'vue-simple-suggest/lib' // es-lint-ignore
import 'vue-simple-suggest/dist/styles.css'

import { Territory, territoryId } from "@/plugins/countries";
import { remove as removeAccents } from "remove-accents";

import RequiredIcon from "../icons/RequiredIcon.vue";
import CountryIcon from '../icons/CountryIcon.vue';

@Component({
    components: {
        VueSimpleSuggest,
        CountryIcon,
        RequiredIcon
    }
})
export default class CountryDropdown extends RootView {
    @Prop({default: "en"}) locale!: string;
    @Prop({default: () => {return {id: "", tag: ""}}}) value!: Territory;
    @Prop({default: false}) disabled!: boolean;
    @Prop({default: true}) show!: boolean;

    isOkay = false;

    $refs!: {
        selector: any;
    }

    mounted() {
        this.isOkay = true;
    }

    get ruleMessages() {
        if (this.isOkay) {
            return [];
        }
        return ['Field is required.'];
    }

    get textColor() {
        if (this.isOkay) {
            return "";
        }
        return "text-danger";
    }

    get displayId() {
        return territoryId(this.value);
    }
    
    get matchSelection() {
        const countryMatch: Territory | undefined = this.$countries.byId(this.value.id, this.locale);
        if (!countryMatch) return false;
        if (countryMatch.tag != this.value.tag) return false;
        return true;
    }

    get countryRule() {
        if (this.disabled || !this.show) {
            return true;
        }
        const countryMatch: Territory | undefined = this.$countries.byId(this.value.id, this.locale);
        if (!countryMatch || countryMatch.tag != this.value.tag)  {
            this.isOkay = false;
            return "Field is required";
        } 

        if (!this.isOkay) {
            this.isOkay = true;
        }
        return true;
    }

    get borderClass() {
        if (!this.isOkay) {
            return "border border-danger text-danger p-1"
        }
        return "border";
    }

    filterFunc(data: Territory, text: string) {
        if (!data) return false;
        return data.tag.toLowerCase().includes(text.toLowerCase()) || removeAccents(data.tag.toLowerCase()).includes(text.toLowerCase());
    }

    sendOnClearEvent() {
        this.$emit("input", {
            id: "",
            tag: ""
        });
        this.$emit("clear");
    }

    sendOnClearEventDebounce = debounce(this.sendOnClearEvent, 500);

    onInputName(data: string) {

        if (data.length == 0 && this.value.id.length > 0) {
            this.sendOnClearEventDebounce();
        }

        const copy = structuredClone(this.value);
        const countries: Territory[] | undefined = this.$countries.all;
        if (!data) return;
        copy.tag = data;
        
        if (countries) {
            for (let i = 0; i < countries.length; i++) {
                
                if (countries[i].tag.toLowerCase() == data.toLowerCase()) {
                    copy.id = countries[i].id;
                    copy.tag = countries[i].tag;
                    this.$emit("select", countries[i].id);
                    break;
                }
            } 
        }
        
        this.$emit("input", copy);
    }

    onSelection(result: any) {
        const copy = structuredClone(this.value);
        copy.id = result.id;
        copy.tag = result.tag;
        this.$emit("input", copy);
        this.$emit("select", result.id);
    }

}
