
import { Component } from "vue-property-decorator";
import { FirmwareUpload } from "@/models/FirmwareModel";
import { RootModal, ModalSetup, ModalType } from "@/components";
import { MODAL_ADMIN_VIEW_FW } from "@/Data";

@Component
export default class AdminViewFirmwareModalView extends RootModal {

    version = "";
    name = "";
    latest = false;
    description = "";
    release = "";
    firmwareFiles: FirmwareUpload[] = [];

    override setup(): ModalSetup {
        return {
            name: MODAL_ADMIN_VIEW_FW,
            type: ModalType.VIEW_ONLY
        };
    }
    
    override onData(data: any) {
        this.version = data.version;
        this.name = data.name;
        this.latest = data.latest;
        this.release = data.release;
        this.description = data.description;
        this.firmwareFiles = data.firmwareFiles;
    }

    firmwarePath(version: string, path: string) {
        const hasQuerySign = path.indexOf("?");
        if (hasQuerySign > 0) {
            return path.substring(path.indexOf(`${version}/`)+version.length+1, path.indexOf("?"));
        }
        return path.substring(path.indexOf(`${version}/`)+version.length+1);
    }

}

