
import { Vue, Component, Prop } from "vue-property-decorator";
import RootView from "./RootView";

@Component
export default class AppLink extends RootView {
    @Prop({default: false}) short!: boolean;
    @Prop({default: false}) outside!: boolean;
    @Prop({default: false}) menu!: boolean;
    @Prop({default: ""}) name?: string;
    @Prop({default: ""}) link?: string;
    @Prop({default: ""}) icon?: string;
    @Prop({default: ""}) title?: string;
    @Prop({default: "before"}) iconPos?: string;
    @Prop({default: "font-size: 12px;"}) fontStyle!: string;

    get mainStyle() {
        if (this.menu) return "padding-top: 8px; padding-bottom: 8px;";
        return "height: 48px;"
    }

    get iconStyle(): string {
        if (this.icon?.trim().length == 0) return "display: none;"
        if (this.short) return "width: 100%;";
        return "width: 24px;"
    }

    get titleMarginClass(): string {
        if (this.icon?.trim().length == 0) return "";
        return "ms-3";
    }

    get mainClass(): string {
        let content = [];

        if (this.$route.name == this.name) {
            content.push("active");
        }

        if (!this.short) {
            content.push("px-3");
        } else {
            content.push("d-flex justify-content-start")
        }

        return content.join(" ");
    }

    menuLink(name: string, path: string) {
        if (this.menu) {
            this.$emit("click");
            return;
        }

        if (this.outside) {
            window.open(this.link);
            return;
        }

        if (this.$route.name == name) return;
        this.goto({path: path});
    }

    get id() {
        return `menu-link-${this.name?.toLowerCase()}`;
    }
}
