
import { Component } from "vue-property-decorator";
import { MODAL_EDIT_ORGANISATION_USER } from "@/Data";
import { isStringEmpty } from "@/Utils";
import UserOrganisationModel from "@/models/UserOrganisationModel";

import { RootModal, ModalSetup, ErrorList } from "@/components"
import { SelectDropdown } from "@/components/dropdowns";
import { DialogPopupModal } from "@/components/toasts";

@Component({
    components: {
        DialogPopupModal,
        SelectDropdown,
        ErrorList
    }
})
export default class EditOrganisationUserRoleModalView extends RootModal {

    error = false;
    orgId = "";
    users: UserOrganisationModel[] = [];

    override setup() : ModalSetup {
        return {
            name: MODAL_EDIT_ORGANISATION_USER,
        }
    }

    override onData(data: any) {
        this.error = false;
        this.orgId = data.org_id;
        this.users = data.users;
    }

    onShown() {
        setTimeout(() => {
            for (let i = 0; i < this.$refs.roles.length; i++) {
            const role: string = this.users[i].role.toLowerCase().trim();
            this.$refs.roles[i].forceValue({
                id: role,
                name: this.$t(`role_${role}`),
            });
        }
        }, 200);
    }

    override validations(): Promise<boolean> {
        for (let i = 0; i < this.users.length; i++) {
            if(isStringEmpty(this.users[i].role)) {
                this.error = true;
                return Promise.resolve(false);
            }
        }
        this.error = false;
        return Promise.resolve(true);
    }

    override process(): Promise<any> {
        let promises: Promise<any>[] = [];

        for (let i = 0; i < this.users.length; i++) {
            promises.push(this.$store.dispatch("organisation_users/update", {
                org_id: this.users[i].org_id,
                usr_id: this.users[i].usr_id,
                role: this.roleFromString(this.users[i].role)
            }));
        }
        return Promise.all(promises);
    }

    onRoleChange(user: UserOrganisationModel, role: {id: string, name: string}) {
        user.role = role.id.toLowerCase();
    }

    onRoleClear(user: UserOrganisationModel) {
        user.role = "";
    }
}
