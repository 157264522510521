import { render, staticRenderFns } from "./InfoRowText.vue?vue&type=template&id=4596f60a&"
import script from "./InfoRowText.vue?vue&type=script&lang=ts&"
export * from "./InfoRowText.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports