
import { Component, Prop } from 'vue-property-decorator';
import RootView from '../RootView';

@Component
export default class TransducerTag extends RootView {
    @Prop({default: false}) small!: boolean;
    @Prop({default: "font-size: 12px; margin-left: 8px !important; margin-right: 8px !important;"}) textStyle!: string;

    get armStatusClass(): string {
        if (!this.chooseDevice == null) {
            return "";
        }

        if (!this.chooseDevice?.transducer) {
            return "";
        }
        
        if (!this.chooseDevice.transducer?.content.connected) {
            return "alert-secondary";
        }

        return "alert-info";
    }   

    get armStatusString(): string {
        if (!this.chooseDevice == null) {
            return "Unknown";
        }

        if (!this.chooseDevice?.transducer) {
            return "Unknown";
        }
        
        if (!this.chooseDevice.transducer.content.connected) {
            if (this.small) {
                return "Discon."
            }
            return "Disconnected";
        }

        if (!this.chooseDevice.transducer?.content.paired) {
            if (this.small) {
                return "C - Pairing..";
            }
            return "Connected - Pairing..";
        }

        if (this.small) {
            return "C - Paired"
        }

        return "Connected - Paired";
    }
    
}
